import {
  ApolloClient,
  InMemoryCache,
  IntrospectionFragmentMatcher,
  IdGetterObj,
  from,
  GraphQLRequest,
  defaultDataIdFromObject,
} from 'apollo-boost'
import { AppCookies } from '@src/utils/cookies'
import { Cookies } from 'react-cookie'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { pickBy } from 'lodash'
import getConfig from 'next/config'
import {
  logErrorsInDev,
  hasSessionExpired,
  hasRememberMeSessionExpired,
} from '@utils/errorHandler'
import introspectionResult from '@src/types/introspection-result'
import { Cart, Product, UserActivityEntry } from '@src/types/graphql-types'
import { createUploadLink } from 'apollo-upload-client'
import createGraphQLOperationName from '@src/utils/graphQLOperationNameMapper'
import { Router, loginRoute, homeRoute } from '@src/routes'
import { getLocalizedUrl } from '@utils/regional'
import { userSession } from '@src/utils/userSession'
import { NextPageContext } from 'next'
import { isServer } from '@src/utils/isServer'

interface IdGetterObjData extends IdGetterObj {
  _id?: string
  value?: string
  key?: string
  name?: string
}

export const createApolloClient = (
  cookies: Cookies,
  ctx,
  initialState = {}
) => {
  const cache = new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: introspectionResult,
    }),
    // Changes unique identifier. https://github.com/apollographql/apollo-client/tree/master/packages/apollo-cache-inmemory#normalization
    dataIdFromObject: (object: IdGetterObjData) => {
      switch (object.__typename) {
        case 'Substance':
          return object._id
        case 'Cart':
          /**
           * Use the cart alias (i.e. 'active' or 'savedcart') to identify the
           * cart object. If the active cart ID changes (e.g. due to a delete
           * cart mutation), we want to make sure that future cart updates are
           * still used to update the old active cart object.
           */
          const cartType = (object as Cart).cartType || 'SIAL'
          return `${(object as Cart).cartIdAlias}-${cartType}` || object.id
        case 'ProductComponent': {
          return object.value
        }
        case 'Material': {
          /**
           * Custom Oligo products have identical IDs and numbers so name is
           * used in conjunction with ID to provide a unique identifier.
           */
          return object.name ? `${object.id}-${object.name}` : object.id
        }
        case 'ProductNumberSuggestions': {
          /**
           * Prevent Barcode results cache replacing product id of current page if on a product.
           */
          return `${object.id}-barcode`
        }
        case 'SharedListMember':
          return defaultDataIdFromObject(object)
        case 'Product':
          const product = object as Product
          return product.gaProductCode
            ? `${product.id}-${product.gaProductCode}`
            : product.id
        case 'UserActivityEntry':
          const entry = object as UserActivityEntry
          return entry.featureType
        default:
          return object.id
      }
    },
  }).restore(initialState)

  const link = from([
    createHeadersLink(cookies),
    createErrorLink(cookies, cache, ctx),
    createHttpLink(),
  ])

  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link,
    cache,
  })
}

function createHttpLink() {
  const {
    publicRuntimeConfig: { graphQlApiClientUrl, graphQlApiServerUrl },
  } = getConfig()

  let uri
  let fetchOptions
  if (!isServer()) {
    uri = graphQlApiClientUrl
  } else {
    uri = graphQlApiServerUrl
    // We need to dynamically require 'url' and 'https' here since it can only be
    // included with the server build
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const url: typeof import('url') = require('url')

    if (url.parse(uri).protocol === 'https:') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const https: typeof import('https') = require('https')
      fetchOptions = {
        agent: new https.Agent({
          rejectUnauthorized: false,
        }),
      }
    }
  }

  const customFetch = (uri, options) => {
    if (typeof options.body === 'string') {
      const { operationName } = JSON.parse(options.body)
      return fetch(`${uri}?operation=${operationName}`, options)
    }

    return fetch(uri, options)
  }

  return new createUploadLink({
    uri,
    fetchOptions,
    fetch: customFetch,
  })
}

function createErrorLink(
  cookies: Cookies,
  cache: InMemoryCache,
  ctx: NextPageContext | undefined
) {
  return onError((error) => {
    logErrorsInDev(error)
    if (hasSessionExpired(error) && cookies.get(AppCookies.AccessToken)) {
      // `path` is required below to prevent issues with dynamic routes
      cookies.remove(AppCookies.AccessToken, { path: '/' })
      //remove store since there could be b2b store for b2b users
      cookies.remove(AppCookies.Store, { path: '/' })
      cache.reset()
      if (!isServer()) {
        // Force a window reload, let routes determine if they are protected
        window.location.reload()
      }
    }
    if (
      hasRememberMeSessionExpired(error) &&
      cookies.get(AppCookies.AccessToken)
    ) {
      const currentAsPath = ctx?.asPath || Router.asPath
      const { as, href } = getLocalizedUrl(
        userSession(cookies.getAll()),
        `${loginRoute.index()}?redirect=${encodeURIComponent(
          currentAsPath || homeRoute.index()
        )}&sessionExpired=true`
      )
      if (!isServer()) {
        Router.push(href, as)
      } else if (ctx?.res) {
        if (!ctx.res.headersSent) {
          ctx.res.writeHead(302, {
            Location: as,
          })
        }
        ctx.res.end()
      }
    }
  })
}

// Define cacheable queries dictionary
const cacheableQueriesMap = {
  AemHeaderFooter: true,
  ProductDetail: true,
  SdsCertificateSearch: true,
  EmproveProductDocs: true,
}

function createHeadersLink(cookies) {
  const {
    publicRuntimeConfig: { brandIdentity },
  } = getConfig()

  return setContext((graphQLRequest: GraphQLRequest, { headers = {} }) => {
    // We use `pickBy` here to filter out falsy values since the apollo client
    // will coerce things like `undefined` to a string value ("undefined") otherwise.
    const {
      country,
      accessToken,
      _ga,
      store,
      language,
      isMarketplaceCatalogEnabled,
      isBlueErpIntegrationEnabled,
      userErpType,
      hasOnlyBlueERP,
      isDarmstadtUser,
    } = cookies.getAll ? cookies.getAll() : cookies
    const authHeaders = pickBy(
      {
        'x-gql-country': country,
        'x-gql-access-token': accessToken,
        'x-gql-guid': _ga,
        'x-gql-store': store,
        'x-gql-language': language,
        'x-gql-mp-enabled': isMarketplaceCatalogEnabled,
        'x-gql-blue-erp-enabled': isBlueErpIntegrationEnabled,
        'x-gql-user-erp-type': userErpType,
        'x-gql-has-only-blue-erp': hasOnlyBlueERP,
        'x-gql-is-darmstadt-user': isDarmstadtUser,
        'x-gql-requesting-website': brandIdentity,
      },
      Boolean
    )
    const graphQLOperationHeader = createGraphQLOperationName(graphQLRequest)

    return {
      headers: {
        ...headers,
        ...authHeaders,
        ...graphQLOperationHeader,
        // If there's an operation name, and that operation is in the cacheable queries dictionary, set the cacheable header, else set blank object
        ...(graphQLRequest['operationName']
          ? cacheableQueriesMap[graphQLRequest.operationName]
            ? { 'x-gql-cacheable': 'true' }
            : {}
          : {}),
      },
    }
  })
}
