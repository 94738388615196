import { decodeHTML } from 'entities'

export function decodeEntities(encodedString: string): string {
  /* converts encoded HTML Entities into unicode string */
  const translateRegex =
    /&(nbsp|amp|quot|lt|gt|reg|trade|copy|mdash|apos|ldquo|rdquo|eacute);/g
  const htmlSymbolRegex = /&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});/g
  const translateMap = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>',
    reg: '®',
    trade: '™',
    copy: '©',
    mdash: '-',
    apos: "'",
    ldquo: '"',
    rdquo: '"',
    eacute: 'é',
  }
  const numericalEntitiesRegex = /&#(x?[0-9a-f]+);/gi // matches digits and HEX-based entities

  return encodedString
    .replace(translateRegex, (match, entity) => translateMap[entity])
    .replace(numericalEntitiesRegex, (match, entity) =>
      String.fromCharCode(parseInt(`0${entity}`))
    )
    .replace(htmlSymbolRegex, '')
}

export function stripAwayTags(potentialHtml: string): string {
  /* strips out HTML tags out of text */
  return potentialHtml.replace(/<[^>]*>/g, '')
}

export function decodePageTitle(encodedString: string): string {
  return stripAwayTags(decodeHTML(encodedString)).trim()
}

export default function decodeTitle(encodedString: string): string {
  return stripAwayTags(decodeEntities(encodedString)).trim()
}
