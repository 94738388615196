import { CatalogType } from '@src/types/graphql-types'

export enum ObfuscatedCatalogID {
  Sial = 'product',
  Buildingblocks = 'bbe',
  B2b = 'btb',
}

export function obfuscateCatalogID(id: CatalogType): ObfuscatedCatalogID {
  switch (id) {
    case CatalogType.Sial:
      return ObfuscatedCatalogID.Sial
    case CatalogType.Buildingblocks:
      return ObfuscatedCatalogID.Buildingblocks
    case CatalogType.B2b:
      return ObfuscatedCatalogID.B2b
    default:
      return ObfuscatedCatalogID.Sial
  }
}

export function deObfuscateCatalogID(id: ObfuscatedCatalogID): CatalogType {
  switch (id) {
    case ObfuscatedCatalogID.Sial:
      return CatalogType.Sial
    case ObfuscatedCatalogID.Buildingblocks:
      return CatalogType.Buildingblocks
    case ObfuscatedCatalogID.B2b:
      return CatalogType.B2b
    default:
      return CatalogType.Sial
  }
}
