import React, { ReactNode } from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { Typography, ButtonBase } from '@material-ui/core'
import XIcon from '@src/icons/XIcon'
import { FormattedMessage } from 'react-intl'
import { Message } from '@src/utils/messages'

export interface Step {
  title: Message
  description: Message
}

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    fontSize: theme.typography.pxToRem(16),
  },
  closeButton: {
    height: '24px',
  },
  textAndCloseContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textContainer: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

interface StepBodyProps {
  step: Step
  onClose: () => void
  footer?: ReactNode
}

const StepBody: React.FC<StepBodyProps> = ({ step, onClose, footer }) => {
  const classes = useStyles()
  const { description, title } = step

  const handleOnClose = () => {
    onClose()
  }

  return (
    <>
      <div className={classes.textAndCloseContainer}>
        <div className={classes.textContainer}>
          <Typography variant="body1" className={classes.title}>
            <FormattedMessage {...title} />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage {...description} />
          </Typography>
        </div>
        <ButtonBase
          onClick={handleOnClose}
          className={classes.closeButton}
          data-testid="step-x"
        >
          <XIcon className={classes.closeIcon} />
        </ButtonBase>
      </div>
      {footer}
    </>
  )
}

export default StepBody
