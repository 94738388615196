import { getStateName, SupportedCountries } from '@sial/country-states'

export const getStateNameFromSial = (
  isChinaUser: boolean,
  state: string | null | undefined
) => {
  if (!state) {
    return ''
  }
  return isChinaUser ? getStateName('CN' as SupportedCountries, state) : state
}
