import { useCallback, useRef } from 'react'

import {
  TrackingData,
  TrackingDataTypes,
  isFeatureType,
  useTypedAddUserActivityMutation,
  useTypedUserActivityQuery,
} from './useTypedUserActivity'
import { PersistentStorage } from './createPersistentStorage'

const useFirstDefined = <T>(value: T, cacheBuster: unknown = null) => {
  const valueRef = useRef(value)
  const cbRef = useRef<unknown>()
  if (value && cacheBuster !== cbRef.current) {
    cbRef.current = cacheBuster
    valueRef.current = value
  }
  return valueRef.current
}

export const useUserActivity = <K extends keyof TrackingDataTypes>(
  featureType: K,
  storageOverride?: PersistentStorage<TrackingDataTypes[K]>,
  cacheBuster?: unknown
) => {
  const { data: remoteData } = useTypedUserActivityQuery({
    skip: !!storageOverride,
  })
  const storageData = storageOverride?.get()

  const createUserActivityData = (
    featureType: keyof TrackingDataTypes,
    trackingData: TrackingDataTypes[K]
  ): TrackingData[] => [{ featureType, trackingData }]
  const userActivityData = (() => {
    if (storageOverride && !storageData) return []
    if (storageOverride && storageData)
      return createUserActivityData(featureType, storageData)
    return remoteData?.getUserActivity
  })()
  const getTrackingData = (userActivityData: TrackingData[]) =>
    userActivityData.find(isFeatureType(featureType))?.trackingData
  const trackingData = userActivityData && getTrackingData(userActivityData)

  // Initial state data allows us to prevent updates from happening when the data updates
  const initialUserActivity = useFirstDefined(userActivityData, cacheBuster)
  const initialTrackingData =
    initialUserActivity && getTrackingData(initialUserActivity)

  const [addUserActivity] = useTypedAddUserActivityMutation()
  const updateUserActivity = useCallback(
    async (updates: Partial<TrackingDataTypes[K]>) => {
      const updatedTrackingData: TrackingData = {
        featureType: featureType,
        trackingData: { ...trackingData, ...updates },
      }

      return !storageOverride
        ? await addUserActivity({
            variables: {
              input: updatedTrackingData,
            },
          })
        : storageOverride.set(updatedTrackingData.trackingData)
    },
    [featureType, storageOverride, addUserActivity, trackingData]
  )

  return {
    userActivityData,
    trackingData,
    initialTrackingData,
    updateUserActivity,
  }
}
