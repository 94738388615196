import { localStorage } from 'react-storage'
import { createPersistentStorage } from '@src/utils/createPersistentStorage'
import { TrackingDataTypes } from '@src/utils/useTypedUserActivity'

export enum MyNetworkWalkMeConstants {
  BANNER_ID = 'walkme-banner-mn',
  DISMISSED_KEY = 'walkme.dismissedWalkMeMN',
  MAX_DISMISSALS = 4,
  TRACKING_DATA_KEY = 'WALK_ME_MY_NETWORK_RESULTS',
}

const searchResultStorage = createPersistentStorage<
  TrackingDataTypes[typeof MyNetworkWalkMeConstants.TRACKING_DATA_KEY]
>(localStorage, MyNetworkWalkMeConstants.TRACKING_DATA_KEY, 'v1')

export const getMyNetworkStorageOverride = (userIsLoggedIn: boolean) => {
  return userIsLoggedIn ? undefined : searchResultStorage
}
