////////////////////////////////
// Component for use in creating new icons only, not intended for use outside of the 'icon' director
////////////////////////////////

import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const useStyles = makeStyles((theme: Theme) => ({
  vertical: {
    height: '100%',
  },
  horizontal: {
    width: 'inherit',
  },
  warning: {
    fill: theme.palette.warning.main,
  },
  success: {
    fill: theme.palette.success.main,
  },
}))

export type ExtendedColorProps = SvgIconProps['color'] | 'success' | 'warning'

export interface BaseIconProps
  extends Omit<SvgIconProps, 'height' | 'width' | 'color'> {
  height?: number
  width?: number
  color?: ExtendedColorProps
}

interface IconProps extends BaseIconProps {
  children: React.ReactNode
}

const calculateIconDirection = (width: number, height: number) => {
  if (width > height) return 'vertical'
  if (width < height) return 'horizontal'
  return ''
}

const Icon: React.FC<IconProps> = ({
  height = 20,
  width = 20,
  children,
  color,
  ...rest
}) => {
  const classes = useStyles()
  const iconDirection = calculateIconDirection(width, height)
  const isMuiColor = color !== 'success' && color !== 'warning'

  return (
    <SvgIcon
      viewBox={`0 0 ${width} ${height}`}
      classes={{
        root: clsx(classes[iconDirection], !isMuiColor && classes[color]),
      }}
      color={isMuiColor ? color : 'inherit'}
      {...rest}
    >
      {children}
    </SvgIcon>
  )
}

export default Icon
