import {
  EventValues,
  GTMEventCategory,
  GA4EcommercePayload,
  PromoObject,
  TealiumEventTypes,
  GTMEventTypes,
} from '@sial/common-utils'
import {
  mapPaymentMethod,
  priceFormatter,
  sendEvent,
} from '@utils/analytics/coreAnalytics'
import {
  CartFragment,
  CartItemFragment,
} from '@src/queries/CartQuery.generated'
import {
  OrderDetailQuery,
  OrderItemFragment,
} from '@src/queries/OrderDetailsQuery.generated'
import { QuickCartItemFragment } from '@src/queries/AllCartsQuery.generated'
import { QuoteItemFragment } from '@src/queries/QuoteDetailsQuery.generated'
import { SavedCartItemFragment } from '@src/queries/SavedCartQuery.generated'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { groupBy, pickBy } from 'lodash'
import { sessionStorage } from 'react-storage'
import loginStorage from '@src/utils/loginStorage'
import Cookies from 'universal-cookie'

import { hasURLSearchParams, isHomePage, LAST_REFERRER_URL } from './analytics'
import {
  AddToCartGAData,
  GTMEcommerceValues,
  GARemoveCartItem,
} from '@utils/analytics/types'
import { AddToCartPagesEnum } from '@utils/analytics/enums'
import { CartType } from '@src/routes'
import { CartItemInput, UserErpType } from '@src/types/graphql-types'
import { ErpType } from './useProductErpType'

export enum ProductListPagesEnum {
  SearchResultPage = 'search result page',
  ProductDetailPage = 'product detail page',
  SubstancePage = 'substance page',
  OrderCenterSingleProductEntry = 'order center - single product entry',
  OrderCenterMultipleProductEntry = 'order center - multiple product entry',
  SavedCart = 'saved cart',
  ShoppingCart = 'shopping cart',
  YourQuotes = 'quotes',
  YourOrders = 'previous orders',
}

const GUEST_USER = 'guest'
const TealiumCheckout = 'Tealium Checkout'

interface PageTealiumEventSetting {
  tealiumEvent: string
  siteSection: string
  skipGlobalAnalytic?: boolean
  companionLinkEvent?: string
}

const OTHER_PAGE_TEALIUM_SETTING: PageTealiumEventSetting = {
  tealiumEvent: 'page_view',
  siteSection: 'All Other Pages',
}

const CN_CURRENTCY_CODE = 'CNY'

const ITEMS_ADD_TO_CART_DATA_STORAGE_KEY = 'ITEMS_ADD_TO_CART_DATA_STORAGE_KEY'

interface AddToCartEventData {
  items: ValidMaterialPricingDetailFragment[]
  productListPageName: ProductListPagesEnum
}

/**
 *  tealiumPageMappings function
 *
 *  Used to map Tealium (and GTM in the future) page view events in the future
 */
const tealiumPageMappings: Record<string, PageTealiumEventSetting> = {
  '/$': { tealiumEvent: 'home_view', siteSection: 'Homepage' },
  '/product/.+/.+': {
    tealiumEvent: 'page_view',
    siteSection: 'PDP',
  },
  '/search/advanced-search': {
    tealiumEvent: 'page_view',
    siteSection: 'Advanced search',
  },
  '/search': {
    tealiumEvent: 'page_view',
    siteSection: 'Search result pages',
  },
  //TODO content pages
  '/cart\\?action=checkout': {
    tealiumEvent: 'page_view',
    siteSection: 'Checkout pages',
    companionLinkEvent: 'begin_checkout',
    skipGlobalAnalytic: false,
  },
  '/cart\\?action=order_acknowledgement': {
    tealiumEvent: 'page_view',
    siteSection: 'Order confirmation pages',
  },
  '/cart\\?action=cart': {
    tealiumEvent: 'page_view',
    siteSection: 'Checkout pages',
    companionLinkEvent: 'checkout_view',
    skipGlobalAnalytic: false,
  },
  '/ecommerce': {
    tealiumEvent: 'page_view',
    siteSection: 'Other eCommerce pages',
  },
  '/favorites': {
    tealiumEvent: 'page_view',
    siteSection: 'Other eCommerce pages',
  },
  '/request-product': {
    tealiumEvent: 'page_view',
    siteSection: 'Other eCommerce pages',
  },
  '/login': { tealiumEvent: 'page_view', siteSection: 'Login pages' },
  '/profile': { tealiumEvent: 'page_view', siteSection: 'Account pages' },
  '/register': {
    tealiumEvent: 'page_view',
    siteSection: 'Registration pages',
  },
  '/substance': {
    tealiumEvent: 'page_view',
    siteSection: 'Substance pages',
    //productListPageName: ProductListPagesEnum.SubstancePage,
  },
  '/order-center': {
    tealiumEvent: 'page_view',
    siteSection: 'Order center pages',
    //productListPageName: ProductListPagesEnum.OrderCenterSingleProductEntry,
  },
  '/structure-search': {
    tealiumEvent: 'page_view',
    siteSection: 'Structure search',
  },
}

/*
  uTag.link() Adapter Function For GA4

  In the move over to GA4 we want to submit all analytics events through Tealium as well for China.

  This is a simply step in that we simply need to take the existing GA4 events "event" properties we use to create event categories.
  Then we need to step the GA4 analytics field to undefined. No other data needs to change as Tealium is just a modified GA4 adapter that works in China.
*/
export const uTagLinkAdapter = (eventPayload) => {
  // Instances where no event, a generic legacy UA event, or generic GA4 event is passed set the Tealium event to the generic Tealium event type.
  if (
    !eventPayload?.event ||
    eventPayload.event === GTMEventTypes.AnalyticsEvent ||
    eventPayload.event === 'ga4Event'
  ) {
    eventPayload.tealium_event = TealiumEventTypes.AnalyticsEvent
  } else {
    eventPayload.tealium_event = eventPayload?.event
  }

  // Clear passed event value to prevent misinterpretations by Tealium when submitted.
  eventPayload.event = undefined

  if (typeof window !== 'undefined' && window?.utag?.link) {
    window.utag.link(eventPayload)
  }
}

export const getPageTealiumSetting = (
  path?: string
): PageTealiumEventSetting => {
  const pathname = path || window.location.pathname + window.location.search
  const countryAndLanguagePathPrefix = '^(/[a-zA-Z]{2}){2}'
  const mappingKey = Object.keys(tealiumPageMappings || {}).find((key) =>
    new RegExp([countryAndLanguagePathPrefix, key].join('')).exec(pathname)
  )

  return mappingKey
    ? (tealiumPageMappings || {})[mappingKey]
    : OTHER_PAGE_TEALIUM_SETTING
}

export const sendUtagView = (options?: Record<string, any>) => {
  if (typeof window !== 'undefined' && window.utag && window.utag.view) {
    const cookies = new Cookies(window.document.cookie)
    const title = window.document.title
    const referer =
      sessionStorage.getItem(LAST_REFERRER_URL) ||
      window.document.referrer ||
      ''
    const language = cookies.get('language')
    const GUID = cookies.get('GUID')
    const virtualPagePath = [
      window.location.pathname,
      window.location.search,
    ].join('')
    const virtualPageUrl = window.location.href
    const virtualPageParameters = window.location.search
    options = options || {}

    // Destructure out a set of variables needed from the options passed into this function that are used to evaluate certain payload overrides.
    // All remaining variables are then cast to the remainingOptions object via the spread remainder operation with ...
    // tealiumEvent and siteSection are grossly passed sometimes with overrides in this format and then need recast to snake_case for the atual payload. So they need destructured here to stop data pollution AND evaluation.
    const {
      userIsLoggedIn,
      userErpType,
      tealiumEvent: optionedTealiumEvent,
      siteSection: optionedSiteSection,
      ...remainingOptions
    } = options

    // Get specific event params to use based on route/window path if needed.
    const pageTealiumSetting = getPageTealiumSetting(virtualPagePath)

    const tealiumEvent =
      optionedTealiumEvent || pageTealiumSetting?.tealiumEvent
    const siteSection = optionedSiteSection || pageTealiumSetting?.siteSection

    const paramlessHomepage =
      isHomePage(virtualPagePath) && !hasURLSearchParams()

    const utagParam = {
      virtual_page: paramlessHomepage ? `${virtualPagePath}/` : virtualPagePath,
      virtual_page_url: paramlessHomepage
        ? `${virtualPageUrl}/`
        : virtualPageUrl,
      original_referer: referer,
      virtual_page_title: title,
      tealium_event: tealiumEvent,
      site_section: siteSection,
      language_code: language,
      guid: GUID?.split('|')[0],
      loggedIn: userIsLoggedIn ? EventValues.Yes : EventValues.No,
      urlparameters: virtualPageParameters,
      cmc_user_type: userIsLoggedIn
        ? userErpType?.toLowerCase() || ''
        : GUEST_USER,
      ...remainingOptions,
    }

    // Some events we want to fire a utag.link event on the page view as well as a general page view event.
    // If the companion link event name exists on any of the tealiumPageMappings variable it will fire the link event by overriding the existing base tealium_event parameter.

    window.utag.view(utagParam)
  }
}

const productErpTypeMapping = {
  borblue: 'bor-blue',
  blue: 'blue',
  red: 'red',
  borred: 'bor-red',
}

export const getErpTypeParam = (
  erpType: string[] | Object | undefined,
  cartItem?: CartItemInput
) => {
  if (Array.isArray(erpType)) return erpType
  return erpType && cartItem && erpType[cartItem?.materialNumber]
}

const isCustomMappingPage = (pageName = '') =>
  pageName === AddToCartPagesEnum.SavedCart ||
  pageName === AddToCartPagesEnum.OrderDetails ||
  pageName === TealiumEventTypes.RemoveFromCart ||
  pageName === TealiumCheckout ||
  pageName === AddToCartPagesEnum.CustomList ||
  pageName === AddToCartPagesEnum.SharedList ||
  pageName === GTMEventCategory.OrderConfirmation

export const mapProductErpType = (
  erpType: string[],
  userType?: any,
  pageName?: string
): string | undefined => {
  if (!erpType || !erpType.length) return
  if (erpType && erpType.length === 1 && !isCustomMappingPage(pageName))
    return productErpTypeMapping[erpType[0]]
  else {
    if (userType) {
      const { isBlueErpIntegrationEnabled, isB2BUser, userErpType } = userType
      //if we are adding from SRP or PDP, we get the erpType as ["blue","red"] for BOR products.So it needs mapping.
      //if we are adding from pages listed in isCustomMappingPage function, we get erpType as ['borred'] for both blue and red customers.So it needs mapping.
      if (
        isCustomMappingPage(pageName) &&
        (erpType[0] === ErpType.Blue || erpType[0] === ErpType.Red)
      ) {
        return productErpTypeMapping[erpType[0]]
      }
      let type
      if (isBlueErpIntegrationEnabled && !isB2BUser) {
        if (
          userErpType === UserErpType.Anonymous ||
          userErpType === UserErpType.NewUser ||
          userErpType === UserErpType.Red
        ) {
          type = 'borred'
        } else if (
          userErpType === UserErpType.Blue ||
          userErpType === UserErpType.Purple
        ) {
          type = 'borblue'
        }
      }
      return productErpTypeMapping[type]
    }
  }
  return undefined
}

/**
 * @name orderConfirmationTrack
 * @description Send placed order details to GA tealium site
 * @param orderData OrderDetailQuery
 */
export const orderConfirmationTrack = (
  orderData?: OrderDetailQuery,
  userType?: Object
) => {
  if (orderData && window && window.utag && window.utag.link) {
    const itemGroups = groupBy(
      orderData?.orderDetail?.order?.items || [],
      (item) => item?.material?.id
    )

    const paymentMethod = mapPaymentMethod(
      orderData?.orderDetail?.order?.paymentInfo?.paymentMethod || '',
      false,
      orderData?.orderDetail?.order?.contractNumber || ''
    )

    /*
      GA4-395 - intermittent issue where total revenue (sub_total, grand_total)
      is showing 0 or incorrect price compared to item level pricing that
      is available in the data. (Sometimes subTotal = 0 but product item price is available/correct)
      Calculating the total item level pricing to compare with the order sub_total
      If they don't match we put itemsComputedRevenue into sub_total and grand_total
      and show under computed_revenue as yes
    */
    const itemsComputedRevenue =
      priceFormatter(
        orderData?.orderDetail?.order?.items.reduce(
          (acc, item) => (item.totalPrice || 0) + acc,
          0
        )
      ) || 0

    const useComputedRevenue =
      itemsComputedRevenue > (orderData?.orderDetail?.order?.subtotal || 0)

    const groupedItems = Object.values(itemGroups).map((group) => ({
      product_brand: group[0]?.brandId,
      product_category: undefined,
      product_id: group[0]?.product || group[0]?.productDetails?.id,
      product_name:
        group[0]?.productDetails?.description ||
        group[0]?.material?.description ||
        group[0]?.material?.name,
      product_price:
        group.reduce((acc, item) => (item?.pricePerUnit || 0) + acc, 0) /
        group.length,
      product_promo_code: group[0]?.promoCode,
      product_quantity: group.reduce((acc, item) => acc + item.quantity, 0),
      product_variant: group[0]?.materialNumber,
      product_type: mapProductErpType(
        (group[0]?.material?.erpType as string[]) || [],
        userType,
        GTMEventCategory.OrderConfirmation
      ),
    }))

    const orderType = orderData?.orderDetail?.order?.orderType
    let orderTypeFlag = ''

    if (orderData?.orderDetail?.order?.category === 'FTB') {
      if (orderType === null) {
        orderTypeFlag = 'FTB-standard'
      } else if (orderType === CartType.Emerald) {
        orderTypeFlag = 'FTB-emerald'
      }
      //todo FTB-orderType
      // if (orderType === null) {
      //   orderTypeFlag = 'FTB-standard'
      // } else {
      //   orderTypeFlag = `FTB-${orderType}`
      // }
    } else {
      if (orderType === null) {
        orderTypeFlag = 'standard'
      } else if (orderType === CartType.Emerald) {
        orderTypeFlag = 'emerald'
      }
      //todo orderType
      // if (orderType === null) {
      //   orderTypeFlag = 'standard'
      // } else {
      //   orderTypeFlag = orderType || ''
      // }
    }

    const utagParam: Record<string, any> = {
      order_grand_total: useComputedRevenue
        ? itemsComputedRevenue.toString()
        : orderData?.orderDetail?.order?.total.toString(),
      order_id: orderData?.orderDetail?.order?.orderNumber || '',
      order_shipping_amount:
        orderData?.orderDetail?.order?.salesTax?.toString() || '0',
      order_subtotal: useComputedRevenue
        ? itemsComputedRevenue.toString()
        : orderData?.orderDetail?.order?.subtotal.toString(),
      order_tax_amount:
        orderData?.orderDetail?.order?.salesTax?.toString() || '0',
      order_promo_code: '', // always pass empty string
      order_type: orderTypeFlag,
      payment_method: paymentMethod,
      purchaseSoldToNumber:
        orderData?.orderDetail?.order?.soldTo || EventValues.Empty,
      purchaseShipToNumber:
        orderData?.orderDetail?.order?.shipTo?.partnerNumber ||
        orderData?.orderDetail?.order?.shipTo?.customerNumber ||
        EventValues.NotAvailable,
      purchaseBillToNumber:
        orderData?.orderDetail?.order?.billTo?.partnerNumber ||
        orderData?.orderDetail?.order?.billTo?.customerNumber ||
        EventValues.NotAvailable,
      purchaseParticipantId:
        sessionStorage.getItem('PARTICIPANT_ID') || EventValues.NotAvailable,
      non_interaction: 1,
      currency_code: orderData?.orderDetail?.order?.currency,
      product_brand: groupedItems.map(
        (item) => item?.product_brand?.toLowerCase() || ''
      ),
      product_category: groupedItems.map(
        (item) => item?.product_category || ''
      ),
      product_id: groupedItems.map(
        (item) => item?.product_id?.toLowerCase() || ''
      ),
      product_name: groupedItems.map(
        (item) => item?.product_name?.toLowerCase() || ''
      ),
      product_price: groupedItems.map((item) =>
        priceFormatter(item?.product_price)
      ),
      product_promo_code: groupedItems.map(
        (item) => item?.product_promo_code?.toLowerCase() || ''
      ),
      product_quantity: groupedItems.map((item) =>
        item?.product_quantity?.toString()?.toLowerCase()
      ),
      product_variant: groupedItems.map(
        (item) => item?.product_variant?.toLowerCase() || ''
      ),
      product_type: groupedItems.map(
        (item) => item?.product_type?.toLowerCase() || ''
      ),
      tealium_event: 'purchase',
      deepOrderID:
        orderData?.orderDetail?.order?.readableOrderId || EventValues.Empty,
      event_category: GTMEventCategory.OrderConfirmation.toLocaleLowerCase(),
      event_action: orderData?.orderDetail?.order?.orderNumber || '',
      event_label:
        groupedItems
          .map((item) => item?.product_id || '')
          ?.join('|')
          .toLowerCase() || '',
      computed_revenue: useComputedRevenue ? 'yes' : 'no',
    }

    window.utag.link(utagParam)
  }
}

/**
 * @name productDetailPageViewTrack
 * @description Send user viewing product details to GA tealium site
 * @param brandKey
 * @param productNumber
 * @param product_name
 */
export const productDetailPageViewTrack = (
  brandKey?: string,
  productNumber?: string,
  product_name?: string,
  erpType?: string[],
  userType?: Object
) => {
  if (window.utag && window.utag.link) {
    const utagParam: Record<string, any> = {
      tealium_event: 'view_item',
      event_category: GTMEventCategory.ProductDetailView,
      event_action: brandKey || '',
      event_label: productNumber || '',
      currency_code: 'CNY',
      product_brand: [brandKey || ''],
      product_category: [''],
      product_id: [productNumber],
      product_name: [product_name || ''],
      product_type: [mapProductErpType(erpType as string[], userType) || ''],
      non_interaction: 1,
    }

    window.utag.link(utagParam)
  }
}

export const sendTealiumCartDataEvent = ({
  viewType,
  cartData,
  isB2BUser = false,
  userIsLoggedIn = false,
  userErpType = '',
  isBlueErpIntegrationEnabled = false,
}: {
  viewType: string
  cartData?: CartFragment
  isB2BUser
  userIsLoggedIn
  userErpType?: string
  isBlueErpIntegrationEnabled?: boolean
}) => {
  try {
    const virtualPagePath = [
      window.location.pathname,
      window.location.search,
    ].join('')

    const pageTealiumSetting = getPageTealiumSetting(virtualPagePath)

    if (cartData) {
      const itemGroups = groupBy(
        cartData.items || [],
        (item) => item.material.id
      )

      const userType = { isBlueErpIntegrationEnabled, isB2BUser, userErpType }

      const groupedItems = Object.values(itemGroups).map((group) => ({
        product_brand: group[0]?.brand || group[0]?.material?.brand?.key,
        product_category: undefined,
        product_id: group[0]?.material?.product || group[0]?.material?.id,
        product_name:
          group[0]?.material?.description || group[0]?.material?.name,
        product_price:
          group.reduce((acc, item) => (item?.pricing?.price || 0) + acc, 0) /
          group.length,
        product_promo_code: group[0]?.promoCode || '',
        product_quantity: group.reduce((acc, item) => acc + item.quantity, 0),
        product_variant: group[0]?.material?.number,
        product_type: mapProductErpType(
          group[0]?.material?.erpType as string[],
          userType,
          TealiumCheckout
        ),
      }))

      const getCheckoutOption = () => {
        if (isB2BUser) {
          return 'B2B'
        }

        if (isBlueErpIntegrationEnabled) {
          if (
            cartData.cartType === CartType.Emerald ||
            cartData.cartType === CartType.EmeraldSavedCart ||
            cartData.cartType === CartType.EmeraldFTBCart
          ) {
            return 'b2c-emerald'
          }
          //todo rename it to b2c-blue?
          // if (
          //   cartData.cartType === CartType.BlueCart ||
          //   cartData.cartType === CartType.BlueSavedCart ||
          //   cartData.cartType === CartType.BlueFTBCart
          // ) {
          //   return 'b2c-emerald'
          // }
          if (
            cartData.cartType === null ||
            cartData.cartType === CartType.SavedCart ||
            cartData.cartType === CartType.FTBCart
          ) {
            return 'b2c-standard'
          }
        }

        return 'B2C'
      }

      const utagParam: Record<string, any> = {
        cart_total_items: cartData.count.toString(),
        cart_total_value: cartData.totals?.subtotal.toString(),
        checkout_option: getCheckoutOption(),
        checkout_step: viewType,
        currency_code: cartData.currency,
        userIsLoggedIn,
        userErpType: userErpType?.toLowerCase(),
        product_brand: groupedItems.map((item) => item.product_brand || ''),
        product_category: groupedItems.map(
          (item) => item.product_category || ''
        ),
        product_id: groupedItems.map((item) => item.product_id || ''),
        product_name: groupedItems.map((item) => item.product_name || ''),
        product_price: groupedItems.map((item) =>
          priceFormatter(item.product_price)
        ),
        product_promo_code: groupedItems.map(
          (item) => item.product_promo_code || ''
        ),
        product_quantity: groupedItems.map((item) =>
          item.product_quantity.toString()
        ),
        product_variant: groupedItems.map((item) => item.product_variant || ''),
        product_type: groupedItems.map((item) => item.product_type || ''),
      }

      if (!pageTealiumSetting?.companionLinkEvent) {
        console.error(
          `Could not find corresponding companion uTag.link() event for checkout page view at path: ${virtualPagePath}`
        )
        return
      }
      const linkEventParams = {
        tealium_event: pageTealiumSetting?.companionLinkEvent,
        ...utagParam,
      }
      window.utag.link(linkEventParams)
    }
  } catch (error) {
    console.error('ERROR - Checkout Event ', error)
  }
}

export const sendRecommendedProductsClickEventForAnalytic = (
  gaProductCode: string,
  ga4Payload: GA4EcommercePayload
) => {
  const productCodeList = gaProductCode?.split('|') || []

  let utagParam: Record<string, any>
  if (window.utag && window.utag.link) {
    utagParam = {
      tealium_event: 'select_promotion',
      product_brand: ga4Payload.items.map((item) => item.item_brand),
      product_id: ga4Payload.items.map((item) => item.item_id.toLowerCase()),
      product_list: ga4Payload.items.map((item) => item.item_list_name),
      promotion_id: [productCodeList[0].toLocaleLowerCase() || ''],
      promotion_name: [productCodeList[1].toLocaleLowerCase() || ''],
      promotion_creative: [productCodeList[2].toLocaleLowerCase() || ''],
      index: ga4Payload.items.map((item) => item.index),
      creative_slot: ga4Payload.creative_slot,
      non_interaction: 1,
    }

    window.utag.link(utagParam)
  }
}
// use this function for AEM banner as well. Always be lowercase
export const sendInternalPromotionsEventForAnalytic = (
  promotions: PromoObject[],
  ecommercePayload: GA4EcommercePayload | undefined
) => {
  let utagParam: Record<string, any>
  if (promotions && window.utag && window.utag.link) {
    utagParam = {
      tealium_event: 'view_promotion',
      product_brand: ecommercePayload?.items.map((item) => item.item_brand),
      product_id: ecommercePayload?.items.map((item) =>
        item.item_id.toLowerCase()
      ),
      product_list: ecommercePayload?.items.map((item) => item.item_list_name),
      promotion_id:
        promotions?.map((promo) => (promo?.id || '').toLowerCase()) || [],
      promotion_name:
        promotions?.map((promo) => (promo?.name || '').toLowerCase()) || [],
      promotion_creative:
        promotions?.map((promo) => (promo?.creative || '').toLowerCase()) || [],
      index: ecommercePayload?.items.map((item) => item.index),
      creative_slot: ecommercePayload?.creative_slot,
      non_interaction: 1,
    }

    window.utag.link(utagParam)
  }
}

export const sendOrderConfirmationB2BTrackForAnalytic = ({
  data,
  callback,
}: {
  data: GTMEcommerceValues
  callback: () => any
}) => {
  const paymentMethod = mapPaymentMethod(
    data.ecommerce.purchase.actionField.paymentMethod || '',
    false,
    '',
    true
  )

  let utagParam: Record<string, any>
  if (window.utag && window.utag.link) {
    const products = data.ecommerce.purchase.products
    utagParam = {
      currency_code: data.ecommerce.currencyCode || CN_CURRENTCY_CODE,
      language_code: 'en',
      order_id: data.ecommerce.purchase.actionField.id || '',
      order_shipping_amount:
        data.ecommerce.purchase.actionField.shipping || '0',
      order_grand_total: data.ecommerce.purchase.actionField.revenue,
      order_tax_amount: data.ecommerce.purchase.actionField.tax || '0',
      order_promo_code: data.ecommerce.purchase.actionField.coupon || '',
      payment_method: paymentMethod,
      purchaseSoldToNumber: data.purchaseSoldToNumber,
      purchaseBillToNumber: data.purchaseBillToNumber,
      purchaseShipToNumber: data.purchaseShipToNumber,
      purchaseParticipantId: data.purchaseParticipantId,
      non_interaction: 1,
      product_brand: products.map((e) => e.brand),
      product_category: products.map(() => ''),
      product_id: products.map((e) => e.id),
      product_name: products.map((e) => e.name),
      product_price: products.map((e) => e.price),
      product_promo_code: products.map((e) => e.coupon || ''),
      product_quantity: products.map((e) => e.quantity.toString() || ''),
      product_variant: products.map((e) => e.variant),
      // customer_id: data.purchaseParticipantId,
      tealium_event: 'purchase',
      event_category: GTMEventCategory.OrderConfirmation.toLocaleLowerCase(),
      event_action: data.ecommerce.purchase.actionField.id || '',
      event_label:
        products
          ?.map((product) => product?.id)
          ?.join('|')
          .toLowerCase() || '',
    }

    window.utag.link(utagParam, () => {
      setTimeout(() => {
        callback()
      }, 3000)
    })
  }
}

export const sendAddItemsToCartErrorEvent = (products) => {
  let utagParam: Record<string, any>
  if (products && window.utag && window.utag.link) {
    utagParam = {
      event_action: products[0]?.eventAction || '',
      event_category: products[0]?.eventCategory || '',
      event_label: products[0]?.eventLabel || '',
      tealium_event: TealiumEventTypes.AnalyticsEvent,
      non_interaction: 0,
    }

    window.utag.link(utagParam)
  }
}

export const sendRemoveItemsFromCartEvent = (
  items: (GARemoveCartItem | QuickCartItemFragment | CartItemFragment)[],
  userType?: Object
) => {
  if (items && items.length > 0 && window.utag && window.utag.link) {
    const utagParam: Record<string, any> = {
      product_brand: items.map((item) => item.material?.brand?.key || ''),
      product_category: items.map(() => ''),
      product_id: items.map((item) => item.material.product),
      product_name: items.map(
        (item) => item.material?.description || item.material?.name || ''
      ),
      product_price: items.map(
        (item) => ('pricing' in item && item.pricing?.price) || null
      ),
      product_quantity: items.map((item) => item.quantity.toString()),
      product_variant: items.map((item) => item.material.number),
      product_type: items.map(
        (item) =>
          item.material?.erpType &&
          mapProductErpType(
            (item.material?.erpType as string[]) || [],
            userType,
            TealiumEventTypes.RemoveFromCart
          )
      ),
      currency_code:
        ('currency' in items[0] && items[0]?.currency) || CN_CURRENTCY_CODE,
      tealium_event: TealiumEventTypes.RemoveFromCart,
      non_interaction: 0,
    }

    window.utag.link(utagParam)
  }
}

export const setStoreAddToCartDataForAnalytics = (
  addToCartEventData: AddToCartEventData
) => {
  return sessionStorage.setItem<AddToCartEventData>(
    ITEMS_ADD_TO_CART_DATA_STORAGE_KEY,
    addToCartEventData
  )
}

export const sendAddItemsToCartEvent = ({ sessionStorageKey }) => {
  const { products, pageName, currency } = sessionStorage.getItem(
    sessionStorageKey
  ) as AddToCartGAData
  let utagParam: Record<string, any>
  if (products && window.utag && window.utag.link) {
    utagParam = {
      product_brand: products.map((item) => item.brand || ''),
      product_coupon: products.map((item) => item.coupon || ''),
      product_category: products.map(() => ''),
      product_id: products.map((item) => item.id || ''),
      product_name: products.map((item) => item.name || ''),
      product_price: products.map((item) => priceFormatter(item?.price)),
      product_quantity: products.map((item) => item.quantity.toString()),
      product_variant: products.map((item) => item.variant || ''),
      currency_code: currency || CN_CURRENTCY_CODE,
      tealium_event: TealiumEventTypes.AddToCart,
      non_interaction: 0,
      product_list: [pageName],
      product_type: products.map((item) => item.erpType || ''),
    }

    window.utag.link(utagParam)
  }
}

export const sendAddQuoteItemsToCartEvent = (items: QuoteItemFragment[]) => {
  if (items && window.utag && window.utag.link) {
    const utagParam: Record<string, any> = {
      product_brand: items.map(
        (item) =>
          item.productDetails?.brand.name || item.materialInfo?.brand.name || ''
      ),
      product_category: items.map(() => ''),
      product_id: items.map(
        (item) =>
          item.product ||
          item.productDetails?.productNumber ||
          item.materialInfo?.product ||
          ''
      ),
      product_name: items.map(
        (item) =>
          item.productDetails?.description ||
          item.materialInfo?.description ||
          ''
      ),
      product_price: items.map((item) => priceFormatter(item?.itemPrice)),
      product_quantity: items.map((item) => item.qty?.toString() || ''),
      product_variant: items.map((item) => item.materialNumber),
      currency_code: items[0]?.currency || CN_CURRENTCY_CODE,
      product_list: [ProductListPagesEnum.YourQuotes],
      tealium_event: TealiumEventTypes.AddToCart,
      non_interaction: 0,
    }

    window.utag.link(utagParam)
  }
}

export const sendReorderEvent = (items: OrderItemFragment[]) => {
  if (items && window.utag && window.utag.link) {
    const utagParam: Record<string, any> = {
      product_brand: items.map((item) => item.productDetails?.brand.name || ''),
      product_category: items.map(() => ''),
      product_id: items.map(
        (item) => item.product || item.productDetails?.productNumber || ''
      ),
      product_name: items.map((item) => item.productDetails?.description || ''),
      product_price: items.map((item) => priceFormatter(item?.pricePerUnit)),
      product_quantity: items.map((item) => item.quantity?.toString() || ''),
      product_variant: items.map((item) => item.materialNumber),
      currency_code: items[0]?.currency || CN_CURRENTCY_CODE,
      product_list: [ProductListPagesEnum.YourOrders],
      tealium_event: TealiumEventTypes.AddToCart,
      non_interaction: 0,
    }

    window.utag.link(utagParam)
  }
}

export const sendSavedCartAddToCartEvent = (
  items?: SavedCartItemFragment[]
) => {
  if (items && window.utag && window.utag.link) {
    const utagParam: Record<string, any> = {
      product_brand: items.map((item) => item?.brand || ''),
      product_category: items.map(() => ''),
      product_id: items.map((item) => item?.product),
      product_name: items.map((item) => item?.materialDescription || ''),
      product_price: items.map((item) => priceFormatter(item?.price)),
      product_quantity: items.map((item) => item?.quantity.toString()),
      product_variant: items.map((item) => item?.materialId),
      currency_code: items[0]?.currency || CN_CURRENTCY_CODE,
      product_list: [ProductListPagesEnum.SavedCart],
      tealium_event: TealiumEventTypes.AddToCart,
      non_interaction: 0,
    }

    window.utag.link(utagParam)
  }
}

export const sendSuccessfulLoginEventForTealium = ({
  gaId,
  me,
  isOffersPromotionsEmail,
  roleNames,
  currentUser,
  emproveUserType,
  organizationType,
  organizationPosition,
  organizationWebsite,
  primaryBusinessActivity,
  isB2BUser,
}) => {
  if (window && window.utag && window.utag.link) {
    const soldTos =
      currentUser?.__typename === 'LoggedInUser' &&
      currentUser?.soldToPartners?.map((partner) => partner?.soldTo).join(' | ')

    const shipTos =
      currentUser?.__typename === 'LoggedInUser' &&
      currentUser?.soldToPartners.map((partner) => partner?.shipTo).join(' | ')

    const billTos =
      currentUser?.__typename === 'LoggedInUser' &&
      currentUser?.soldToPartners.map((partner) => partner?.billTo).join(' | ')

    const primaryBusinessActivityString =
      primaryBusinessActivity &&
      Object.keys(
        pickBy(primaryBusinessActivity, (value) => value === true)
      ).join(' | ')

    const loginType =
      me?.profileType === 'B2B'
        ? 'b2b login'
        : loginStorage.get()?.rememberMe
          ? 'persistent login'
          : 'non-persistent login'

    sendEvent({
      eventType: GTMEventTypes.AnalyticsEvent,
      payload: {
        event: 'ga4Event',
        event_name: GTMEventCategory.Login.toLowerCase(),
        login_participant_id: me?.id,
        login_reauthentication: '',
        login_type: loginType,
        method: 'password',
        customer_id: me?.id,
        Roles: roleNames?.replace(/-/g, '|') || undefined,
        UserType: (isB2BUser ? 'B2B' : 'B2C') || undefined,
        newsLetterSignup: isOffersPromotionsEmail
          ? EventValues.Yes
          : EventValues.No,
        soldToNumber: soldTos || undefined,
        billToNumber: billTos || undefined,
        shipToNumber: shipTos || undefined,
        emproveUserType: emproveUserType,
        organizationType: organizationType || undefined,
        organizationPosition: organizationPosition || undefined,
        organizationWebsite: organizationWebsite || undefined,
        primaryBusinessActivity: primaryBusinessActivityString || undefined,
        eventCategory: GTMEventCategory.SuccessfulLogin,
        eventAction: me?.id,
        eventLabel: gaId,
        member_id: me?.memberId || '',
      },
    })
  }
}

export const sendWechatLoginEvent = (gaId) => {
  if (window && window.utag && window.utag.link) {
    const tealiumEventData = {
      event_action: 'wechat login',
      event_category: 'login page',
      event_label: gaId,
      tealium_event: TealiumEventTypes.AnalyticsEvent,
    }

    window.utag.link(tealiumEventData)
  }
}

export const sendMobileLoginEvent = (gaId) => {
  if (window && window.utag && window.utag.link) {
    const tealiumEventData = {
      event_action: 'mobile login',
      event_category: 'login page',
      event_label: gaId,
      tealium_event: TealiumEventTypes.AnalyticsEvent,
    }

    window.utag.link(tealiumEventData)
  }
}
// actions: 'open fapiao', 'view paper fapiao', 'download e-fapiao'
export const sendFapiaoSearchEvent = (action) => {
  if (window && window.utag && window.utag.link) {
    const tealiumEventData = {
      tealium_event: TealiumEventTypes.AnalyticsEvent,
      event_category: 'fapiao search page',
      event_action: action,
      event_label: sessionStorage.getItem('PARTICIPANT_ID'),
      non_interaction: 0,
    }
    window.utag.link(tealiumEventData)
  }
}

export const sendOrderCenterFapiaoEvent = (action) => {
  if (window && window.utag && window.utag.link) {
    const tealiumEventData = {
      tealium_event: TealiumEventTypes.AnalyticsEvent,
      event_category: 'order-center',
      event_action: action,
      event_label: sessionStorage.getItem('PARTICIPANT_ID'),
      non_interaction: 0,
    }
    window.utag.link(tealiumEventData)
  }
}
