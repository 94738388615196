import React, { FC, ReactNode } from 'react'
import { Modal, ModalProps, ButtonBase, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import XIcon from '@src/icons/XIcon'
import clsx from 'clsx'

export enum ModalSizes {
  Small = 'SM',
  Medium = 'MD',
  Large = 'LG',
  XLarge = 'XL',
}

export enum ModalCloseReason {
  EscapeKeyDown = 'escapeKeyDown',
  BackdropClick = 'backdropClick',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    outline: 'none',
    background: theme.palette.background.default,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      maxHeight: 'calc(100% - 64px)', // Matches MUI's Dialog's max-height
      borderRadius: '6px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  root_sm: {
    [theme.breakpoints.up('md')]: {
      width: 510,
    },
  },
  root_md: {
    [theme.breakpoints.up('md')]: {
      width: 620,
    },
  },
  root_lg: {
    [theme.breakpoints.up('md')]: {
      width: 840,
    },
  },
  root_xl: {
    [theme.breakpoints.up('md')]: {
      width: 920,
    },
  },
  head: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 4, 0, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 8, 0, 8),
    },
  },
  body: {
    padding: theme.spacing(6, 4, 4, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 8, 8, 8),
    },
  },
  titleWrapper: {
    width: 'calc(100% - 16px)', // NOTE: 16px close button
  },
  closeButton: {
    // custom focus state
    '&:focus-visible': {
      outline: `2px solid ${theme.palette.primary.main}`,
      outlineOffset: '12px',
    },
    // Since we have a custom focus state, we can disable the default one
    '&.Mui-focusVisible': {
      boxShadow: 'none',
    },
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(16),
  },
  raisedCloseIcon: {
    fontSize: theme.typography.pxToRem(10),
  },
  closeIconDisabled: {
    color: theme.palette.text.disabled,
  },
  backdrop: {
    display: 'flex',
  },
  raisedCloseButton: {
    position: 'absolute',
    top: '15px',
    right: '15px',
  },
  closeButtonNoTitle: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(8),
      right: theme.spacing(8),
    },
  },
  actions: {
    marginTop: theme.spacing(6),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
      display: 'flex',
    },
    '& > *': {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'auto',
      },
    },
    '& > * + *': {
      marginTop: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: theme.spacing(4),
      },
    },
  },
}))

interface ResponsiveModalProps extends ModalProps {
  renderTitle?(): ReactNode
  onClose(event?: object, reason?: string): void
  size?: ModalSizes
  closeButtonId?: string
  closeDisabled?: boolean
  raisedCloseButton?: boolean
  rootClassName?: string
  hideCloseButton?: boolean
  customHeaderStyle?: string
  customModalStyle?: string
  customCloseBtnStyle?: string
}

interface ModalHeadProps {
  renderTitle?(): ReactNode
  onClose(event?: object, reason?: string): void
  closeButtonId?: string
  closeDisabled?: boolean
  raisedCloseButton?: boolean
  ModalHeadProps?: boolean
  hideCloseButton?: boolean
  customHeaderStyle?: string
}

interface CloseButtonProps {
  onClose(event?: object, reason?: string): void
  closeButtonId?: string
  closeDisabled?: boolean
  raisedCloseButton?: boolean
}

const CloseButton: FC<CloseButtonProps> = ({
  onClose,
  closeButtonId,
  closeDisabled,
  raisedCloseButton,
}) => {
  const classes = useStyles()
  const buttonClassName = raisedCloseButton ? classes.raisedCloseButton : ''
  const buttonIconClassName = raisedCloseButton
    ? classes.raisedCloseIcon
    : classes.closeIcon

  return (
    <ButtonBase
      onClick={() => onClose()}
      id={closeButtonId}
      disabled={closeDisabled}
      className={clsx(classes.closeButton, buttonClassName)}
      aria-label="Close"
    >
      <XIcon
        className={clsx(
          buttonIconClassName,
          closeDisabled && classes.closeIconDisabled
        )}
      />
    </ButtonBase>
  )
}

const ModalHead: FC<ModalHeadProps> = ({
  raisedCloseButton,
  onClose,
  closeButtonId,
  closeDisabled,
  renderTitle,
  hideCloseButton,
  customHeaderStyle,
}) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.head, customHeaderStyle)}>
      {raisedCloseButton && !hideCloseButton ? (
        <CloseButton
          onClose={onClose}
          closeButtonId={closeButtonId}
          closeDisabled={closeDisabled}
          raisedCloseButton={raisedCloseButton}
        />
      ) : null}
      <div className={classes.titleWrapper}>{renderTitle && renderTitle()}</div>
      {!raisedCloseButton && !hideCloseButton ? (
        <CloseButton
          onClose={onClose}
          closeButtonId={closeButtonId}
          closeDisabled={closeDisabled}
        />
      ) : null}
    </div>
  )
}

const ResponsiveModal: FC<ResponsiveModalProps> = ({
  children,
  renderTitle,
  onClose,
  closeButtonId = 'modal-close',
  rootClassName,
  size = ModalSizes.Medium,
  closeDisabled = false,
  raisedCloseButton = false,
  hideCloseButton = false,
  customHeaderStyle,
  customModalStyle,
  customCloseBtnStyle,
  ...modalProps
}) => {
  const classes = useStyles()

  if (!modalProps.open) return null

  const rootClasses = clsx(classes.root, rootClassName, {
    [classes.root_sm]: size === ModalSizes.Small,
    [classes.root_md]: size === ModalSizes.Medium,
    [classes.root_lg]: size === ModalSizes.Large,
    [classes.root_xl]: size === ModalSizes.XLarge,
  })

  return (
    <Modal onClose={onClose} className={classes.backdrop} {...modalProps}>
      <div className={clsx(rootClasses, customModalStyle)}>
        {renderTitle ? (
          <ModalHead
            onClose={onClose}
            raisedCloseButton={raisedCloseButton}
            closeButtonId={closeButtonId}
            closeDisabled={closeDisabled}
            renderTitle={renderTitle}
            hideCloseButton={hideCloseButton}
            customHeaderStyle={customHeaderStyle}
          />
        ) : (
          <div
            className={clsx(classes.closeButtonNoTitle, customCloseBtnStyle)}
          >
            <CloseButton
              onClose={onClose}
              closeButtonId={closeButtonId}
              closeDisabled={closeDisabled}
            />
          </div>
        )}
        {children}
      </div>
    </Modal>
  )
}

export const ResponsiveModalBody: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const classes = useStyles()
  return <div className={clsx(classes.body, className)}>{children}</div>
}

export const ResponsiveModalActions: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const classes = useStyles()
  return <div className={clsx(classes.actions, className)}>{children}</div>
}

export default ResponsiveModal
