import { createWalkMeContext } from '@src/utils/createWalkMeContext'

const [MyNetworkWalkMeContext, useMyNetworkWalkMeContext] = createWalkMeContext(
  {}
)

const MyNetworkWalkMeProvider = ({ children }) => {
  const myNetworkWalkMe = useMyNetworkWalkMeContext()

  return (
    <MyNetworkWalkMeContext.Provider value={myNetworkWalkMe}>
      {children}
    </MyNetworkWalkMeContext.Provider>
  )
}

export { MyNetworkWalkMeProvider, MyNetworkWalkMeContext }
