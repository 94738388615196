import { ErrorResponse } from 'apollo-link-error'
import { SourceLocation, GraphQLError } from 'graphql'
import { ApolloError } from 'apollo-boost'
import { StatusCodes } from 'http-status-codes'
import { ErrorCodes } from '@src/types/graphql-types'

const DEFAULT_DISPLAYABLE_ERROR = 'An unknown error has occured.'

export type OriginalError = ErrorResponse | ApolloError

export interface ErrorDetail {
  originalError: OriginalError
  code: string
  errorMessage: string
  path?: readonly (string | number)[]
  displayPath?: string
  displayableError?: string
  exceptionErrorMessage?: string
  invalidArgs?: { [index: string]: string }
  invalidArgsDisplay?: string
  validationErrorCodes?: [string]
  misc?: { [index: string]: any }
  stacktrace?: string
  locations?: readonly SourceLocation[]
  httpResponse?: any
  httpResponseBody?: any
  httpResponseCode?: number
  httpResponseName?: string
  httpRequest?: any
  paramList?: [string]
}

export interface ExtractedError {
  displayableError: string
  errors: ErrorDetail[]
  hasError: (code: string) => boolean
}

enum ApolloErrorTypes {
  NotFound = 'NOT_FOUND',
  BadUserInput = 'BAD_USER_INPUT',
  BadAccessToken = 'ERROR_USER_INVALID_OR_EXPIRED_ACCESS_TOKEN',
  RememberMeAccessDenied = 'ERROR_ACCESS_DENIED_TO_REMEMBER_ME_USER',
}

/**
 * Receives an error from Apollo, extracts the important pieces from it, and
 * returns it in a more ergonomic structure.
 */
export function extractData(error: OriginalError): ExtractedError {
  const { graphQLErrors, networkError } = error

  if (graphQLErrors && graphQLErrors.length > 0) {
    const errors = graphQLErrors.map((graphQlError) =>
      mapGraphQlError(graphQlError, error)
    )

    return {
      displayableError: buildDisplayableError(errors),
      errors: errors,
      hasError(code: string): boolean {
        return !!errors.find((error) => error.code.trim() === code)
      },
    }
  }

  if (networkError) {
    return {
      displayableError: DEFAULT_DISPLAYABLE_ERROR,
      errors: [
        {
          code: 'NETWORK_ERROR',
          errorMessage: 'Network error',
          originalError: error,
        },
      ],
      hasError: (code) => code === 'NETWORK_ERROR',
    }
  }

  return {
    displayableError: DEFAULT_DISPLAYABLE_ERROR,
    errors: [
      {
        code: ErrorCodes.HttpError,
        errorMessage: 'HTTP Request Error',
        originalError: error,
      },
    ],
    hasError: (code) => code === ErrorCodes.HttpError,
  }
}

/**
 * When running the app in development, log all GraphQL errors to the console
 * in a compact but detailed manner.
 */
export function logErrorsInDev(error: OriginalError): void {
  if (process.env.NODE_ENV !== 'development') return
  const extractedError = extractData(error)
  extractedError.errors.forEach(
    ({
      code,
      errorMessage,
      displayableError,
      displayPath,
      exceptionErrorMessage,
      invalidArgsDisplay,
      misc,
      stacktrace,
      locations,
      httpResponse,
      httpResponseCode,
      httpResponseName,
      httpResponseBody,
      httpRequest,
    }) => {
      console.groupCollapsed('%cGraphQL Error', 'color:#A62700;')
      logItem('Code', code)
      logItem('Message', errorMessage)
      logItem('Displayable', displayableError)
      if (httpRequest && httpRequest.method) {
        logItem(
          'Request',
          `${httpRequest.method.toUpperCase()} ${httpRequest.path}`
        )
      }
      if (httpResponseCode && httpResponseName) {
        logItem('Response', `${httpResponseCode} ${httpResponseName}`)
      }
      logItem('Response', httpResponseBody)
      logItem('Path', displayPath)

      // exception details (collapsed)
      console.groupCollapsed('Exception Detail')
      logItem('Message', exceptionErrorMessage)
      logItem('Invalid Args', invalidArgsDisplay)
      logItem('Request', httpRequest)
      logItem('Response', httpResponse)
      logItem('Misc', misc)
      logItem('Stack', stacktrace)
      console.groupEnd()

      // location details (collapsed)
      if (locations && locations.length > 0) {
        console.groupCollapsed('Locations')
        locations.forEach((location) => {
          console.info(location)
        })
        console.groupEnd()
      }

      console.groupEnd()
    }
  )
}

/**
 * Helper method to create a single displayable string from the list of errors
 */
function buildDisplayableError(errors: ErrorDetail[]): string {
  const errorsWithDisplayableError = errors.filter(
    (error) => !!error.displayableError
  )
  return errorsWithDisplayableError.length > 0
    ? errorsWithDisplayableError
        .map((error) => addTrailingPunctuation(error.displayableError))
        .join(' ')
    : DEFAULT_DISPLAYABLE_ERROR
}

/**
 * Helper method for building displayable messages
 */
function addTrailingPunctuation(error?: string): string {
  if (!error) return ''

  const lastChar = error.trim().substr(-1)
  return lastChar.match(/[.?!]/) ? error : `${error}.`
}

/**
 * Helper method to pretty-print an item detail
 */
function logItem(label: string, value?: any): void {
  if (!value) return
  console.log(`%c[${label}]`, 'color: gray', value) // eslint-disable-line no-console
}

/**
 * Helper method to extract the important pieces from a GraphQL error
 */
function mapGraphQlError(
  { extensions, message, path, locations }: GraphQLError,
  originalError: OriginalError
): ErrorDetail {
  // path
  const displayPath = path ? path.join(' > ') : ''

  // error message
  const errorMessages = [message]

  const mappedError = {
    code: 'UNKNOWN_ERROR',
    errorMessage: message,
    path,
    displayPath,
    originalError,
    locations,
  }

  // if there are no extensions, don't process any further
  if (!extensions) return mappedError

  if (extensions.exception)
    extensions.stacktrace = extensions.exception.stacktrace

  // extract data from exception
  const {
    code,
    invalidArgs,
    errorMessage: exceptionErrorMessage,
    displayableError,
    stacktrace,
    request,
    response,
    ...rest
  } = extensions

  // add exception message to top-level error message
  if (exceptionErrorMessage) {
    errorMessages.push(exceptionErrorMessage)
  }
  const errorMessage = errorMessages.join('. ') + '.'

  // special handling of axios errors
  let httpRequest
  let httpResponse
  let httpResponseBody
  let httpResponseCode
  let httpResponseName
  if (response && request) {
    // request info
    httpRequest = request

    // response info
    httpResponse = response
    httpResponseCode = response.status
    httpResponseName = (StatusCodes[response.status] || '').toUpperCase()
    httpResponseBody = response.data
  }

  // required field validation error codes
  let validationErrorCodes
  if (httpResponseBody && httpResponseBody.errorCodes) {
    const { errorCodes } = httpResponseBody
    validationErrorCodes = errorCodes
      .filter((error: any) => {
        const { errorCode } = error
        return (
          errorCode === ErrorCodes.PaymentPoNumberEmpty ||
          errorCode === ErrorCodes.PaymentRequisitionNumberEmpty ||
          errorCode === ErrorCodes.ShippingAttentionToEmpty ||
          errorCode === ErrorCodes.ContactTelephoneEmpty
        )
      })
      .map((error: any) => error.errorCode)
  }

  let paramList
  if (extensions.paramList) {
    paramList = extensions.paramList
  }
  if (httpResponseBody && httpResponseBody.errorCodes) {
    const { errorCodes } = httpResponseBody
    const filteredErrorCodes = errorCodes.filter(
      (errorCode) => errorCode.errorCode === code
    )
    if (filteredErrorCodes.length) paramList = filteredErrorCodes[0].paramList
  }

  // invalid args
  const invalidArgsDisplay = invalidArgs
    ? Object.keys(invalidArgs)
        .map((key) => `${key}: ${invalidArgs[key]}`)
        .join('. ')
    : undefined

  // misc other values in the exception
  const misc = rest && Object.keys(rest).length > 0 ? rest : undefined

  return {
    ...mappedError,
    code,
    errorMessage,
    displayableError,
    exceptionErrorMessage,
    invalidArgs,
    invalidArgsDisplay,
    validationErrorCodes,
    paramList,
    misc,
    httpResponse,
    httpResponseBody,
    httpResponseCode,
    httpResponseName,
    httpRequest,
    stacktrace: stacktrace && stacktrace.join('\n'),
  }
}

// Helper to detect session expiry.
export function hasSessionExpired(error: ErrorResponse): boolean {
  let hasExpired = false
  const extractedError = extractData(error)

  extractedError.errors.forEach(({ code }) => {
    if (code === ApolloErrorTypes.BadAccessToken) {
      hasExpired = true
    }
  })

  return hasExpired
}

// Helper to detect session expiry.
export function hasRememberMeSessionExpired(error: ErrorResponse): boolean {
  const extractedError = extractData(error)
  return extractedError.errors.some(({ code }) => {
    return code === ApolloErrorTypes.RememberMeAccessDenied
  })
}

const isAemProductPageError = (
  errorData: ExtractedError,
  asPath: string
): boolean => {
  return (
    errorData.hasError(ApolloErrorTypes.BadUserInput) &&
    !!asPath.match(/^\/[A-Z]{2}\/[a-z]{2}\/products\//)
  )
}

export const getStatusFromError = (
  error: OriginalError,
  asPath?: string
): number => {
  // TODO: get specific with other errors - https://stljirap.sial.com/browse/STRAT-18055
  let statusCode = 200
  if (!asPath) {
    statusCode = 500
    return statusCode
  }
  const errorData = extractData(error)
  if (errorData.hasError(ApolloErrorTypes.NotFound)) {
    statusCode = 404
  } else if (isAemProductPageError(errorData, asPath)) {
    // If we are on an AEM product page, we don't want a 404 or 500 if part of the page fails, so we return a 200.
    statusCode = 200
  }
  return statusCode
}
