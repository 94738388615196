import { useContext, useState } from 'react'
import { sessionStorage } from 'react-storage'
import { useUserActivity } from '@src/utils/useUserActivity'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import {
  MyNetworkWalkMeContext,
  MyNetworkWalkMeConstants,
  getMyNetworkStorageOverride,
} from '@src/routes/Profile/MyNetwork/MyNetworkWalkMe'

export const useMyNetworkWalkMe = () => {
  const { userIsLoggedIn } = useCurrentUser()
  const { state, setEnabled, dismissStep, startTour, advanceActiveStepNumber } =
    useContext(MyNetworkWalkMeContext)
  const {
    userActivityData,
    trackingData,
    initialTrackingData,
    updateUserActivity,
  } = useUserActivity(
    MyNetworkWalkMeConstants.TRACKING_DATA_KEY,
    getMyNetworkStorageOverride(userIsLoggedIn)
  )

  const [dismissedAlert, setDismissedAlert] = useState(
    !!sessionStorage.getItem(MyNetworkWalkMeConstants.DISMISSED_KEY)
  )
  const dismissedCount = trackingData?.numWalkMeDismissals || 0
  const shouldHideAlert =
    dismissedAlert ||
    !userActivityData ||
    initialTrackingData?.hasCompletedWalkMe ||
    dismissedCount >= MyNetworkWalkMeConstants.MAX_DISMISSALS

  const dismissAlert = (optOutOfWalkMe?: boolean) => {
    if (optOutOfWalkMe) setEnabled(false)
    sessionStorage.setItem(MyNetworkWalkMeConstants.DISMISSED_KEY, true)
    setDismissedAlert(true)
    updateUserActivity({
      numWalkMeDismissals: (dismissedCount || 0) + 1,
      ...(optOutOfWalkMe != null && {
        hasOptedOutOfWalkMe: optOutOfWalkMe,
      }),
    })
  }

  return {
    state,
    setEnabled,
    dismissStep,
    startTour,
    dismissAlert,
    shouldHideAlert,
    advanceActiveStepNumber,
  }
}
