// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
export type DealerFragment = { __typename?: 'Dealer', id: string, organizationName?: string | null, department?: string | null, lineItemCount?: number | null, stateCode?: string | null };

export const DealerFragmentDoc = gql`
    fragment Dealer on Dealer {
  id
  organizationName
  department
  lineItemCount
  stateCode
}
    `;