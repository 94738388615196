import { useContext, useState } from 'react'
import { sessionStorage } from 'react-storage'
import { useUserActivity } from '@src/utils/useUserActivity'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import {
  InvoicesWalkMeContext,
  InvoicesWalkMeConstants,
  getInvoicesStorageOverride,
} from '@src/routes/OrderCenter/Invoices/InvoicesWalkMe/'

export const useInvoicesWalkMe = () => {
  const { userIsLoggedIn } = useCurrentUser()
  const {
    state,
    setEnabled,
    dismissStep,
    startTour,
    advanceActiveStepNumber,
    forceActiveStepNumber,
  } = useContext(InvoicesWalkMeContext)

  const {
    userActivityData,
    trackingData,
    initialTrackingData,
    updateUserActivity,
  } = useUserActivity(
    InvoicesWalkMeConstants.TRACKING_DATA_KEY,
    getInvoicesStorageOverride(userIsLoggedIn)
  )

  const [dismissedAlert, setDismissedAlert] = useState(
    !!sessionStorage.getItem(InvoicesWalkMeConstants.DISMISSED_KEY)
  )
  const [alertState, setAlertState] = useState({ showAlert: true })
  const dismissedCount = trackingData?.numWalkMeDismissals || 0

  const shouldHideAlert =
    dismissedAlert ||
    !alertState.showAlert ||
    !userActivityData ||
    initialTrackingData?.hasOptedOutOfWalkMe ||
    initialTrackingData?.hasCompletedWalkMe ||
    dismissedCount >= InvoicesWalkMeConstants.MAX_DISMISSALS

  const dismissAlert = () => {
    sessionStorage.setItem(InvoicesWalkMeConstants.DISMISSED_KEY, true)
    setDismissedAlert(true)
    updateUserActivity({
      numWalkMeDismissals: (dismissedCount || 0) + 1,
    })
  }

  const closeAlert = () => {
    setAlertState({ showAlert: false })
  }

  return {
    state,
    setEnabled,
    dismissStep,
    startTour,
    dismissAlert,
    shouldHideAlert,
    advanceActiveStepNumber,
    forceActiveStepNumber,
    closeAlert,
  }
}
