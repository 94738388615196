import { localStorage } from 'react-storage'
import { createPersistentStorage } from '@src/utils/createPersistentStorage'
import { TrackingDataTypes } from '@src/utils/useTypedUserActivity'

export enum InvoicesWalkMeConstants {
  BANNER_ID = 'pay-later-banner',
  DISMISSED_KEY = 'invoices.dismissedWalkMe',
  MAX_DISMISSALS = 4,
  TRACKING_DATA_KEY = 'WALK_ME_PAY_LATER',
}

const payLaterStorage = createPersistentStorage<
  TrackingDataTypes[typeof InvoicesWalkMeConstants.TRACKING_DATA_KEY]
>(localStorage, InvoicesWalkMeConstants.TRACKING_DATA_KEY, 'v1')

export const getInvoicesStorageOverride = (userIsLoggedIn: boolean) => {
  return userIsLoggedIn ? undefined : payLaterStorage
}
