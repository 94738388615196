export enum ErpType {
  Red = 'red',
  Blue = 'blue',
}

// Custom hook to determine Product Type based on ERP type
export const useProductErpType = (input) => {
  // Check if input is an array; if not, extract erp_type from the product object
  // This is necessary because in some places we pass the full product object,
  // while in others we may only pass an array of erpType values.
  const erpTypes = Array.isArray(input) ? input : input?.erp_type || []

  const isBlueOnlyProduct =
    erpTypes?.length === 1 && erpTypes[0] === ErpType.Blue

  // Check if the product is a "purple" product (contains both red and blue)
  const isPurpleProduct =
    erpTypes?.includes(ErpType.Red) && erpTypes?.includes(ErpType.Blue)

  return { isBlueOnlyProduct, isPurpleProduct }
}
