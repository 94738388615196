import React, { ReactNode, useLayoutEffect, useState } from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { ClickAwayListener, Popper } from '@material-ui/core'
import clsx from 'clsx'
import StepBody, { Step } from './StepBody'
import { noop } from 'lodash'

const useStyles = makeStyles((theme: Theme) => ({
  popper: { zIndex: theme.zIndex.modal, maxWidth: '95%' },
  arrowDiv: {
    position: 'relative',
    background: 'white',
    border: `2px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(6),
    maxWidth: '507px',
    display: 'inline-block',
    boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: '4px',
  },
  arrow: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 12,
    height: 20,
    transformOrigin: '0 0',
    '&:after, &:before': {
      border: 'solid transparent',
      content: '""',
      position: 'absolute',
    },
    '&:before': {
      borderWidth: '10px 13px',
      borderRightWidth: 0,
      borderLeftColor: theme.palette.primary.main,
      top: 0,
    },
    '&:after': {
      borderWidth: '8px 10px',
      borderRightWidth: 0,
      borderLeftColor: 'white',
      top: 2,
    },
  },
  arrowDivLeft: {
    marginRight: theme.spacing(4),
    '& > $arrow': {
      left: '100%',
    },
  },
  arrowDivRight: {
    marginLeft: theme.spacing(4),
    '& > $arrow': {
      transform: 'scaleX(-1)',
    },
  },
  arrowDivTop: {
    marginBottom: theme.spacing(4),
    '& > $arrow': {
      top: '100%',
      transform: 'rotate(90deg) translateY(-50%)',
    },
  },
  arrowDivBottom: {
    marginTop: theme.spacing(4),
    '& > $arrow': {
      transform: 'rotate(-90deg) translateY(-50%)',
    },
  },
}))

type Placement = 'left' | 'right' | 'top' | 'bottom'

export interface WalkMeStepProps {
  anchorEl: React.RefObject<HTMLElement>
  placement: Placement
  step: Step
  onClose: () => void
  onClickAway?: () => void
  footer?: ReactNode
  id?: string
  className?: string
}

const WalkMeStep: React.FC<WalkMeStepProps> = ({
  anchorEl,
  placement,
  step,
  onClose,
  onClickAway,
  footer,
  id,
  className,
}) => {
  const classes = useStyles()
  const [isAnchorInDOM, setIsAnchorInDOM] = useState(false)
  useLayoutEffect(() => setIsAnchorInDOM(!!anchorEl.current?.offsetParent))
  if (!isAnchorInDOM) return null
  const getArrowClass = () => {
    const arrowClassMap: Record<Placement, string> = {
      left: classes.arrowDivLeft,
      right: classes.arrowDivRight,
      top: classes.arrowDivTop,
      bottom: classes.arrowDivBottom,
    }
    return arrowClassMap[placement]
  }

  return (
    <ClickAwayListener onClickAway={onClickAway || noop}>
      <Popper
        id={id}
        open
        anchorEl={anchorEl.current}
        placement={placement}
        className={clsx(classes.popper, className)}
        aria-live="polite"
        popperOptions={{
          modifiers: {
            arrow: { element: `.${classes.arrow}` },
            flip: { enabled: false },
          },
        }}
      >
        <div className={clsx(classes.arrowDiv, getArrowClass())}>
          <div className={classes.arrow} />
          <StepBody step={step} onClose={onClose} footer={footer} />
        </div>
      </Popper>
    </ClickAwayListener>
  )
}

export default WalkMeStep
