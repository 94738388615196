import Icon, { BaseIconProps } from './Icon'
function XIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
        <path d="m11.57 10 8.1-8.1a1.1 1.1 0 0 0 0-1.57 1.1 1.1 0 0 0-1.57 0L10 8.43 1.9.33a1.1 1.1 0 0 0-1.57 0 1.1 1.1 0 0 0 0 1.57l8.1 8.1-8.1 8.1a1.1 1.1 0 0 0 0 1.57 1.08 1.08 0 0 0 .78.33 1.1 1.1 0 0 0 .79-.33l8.1-8.1 8.1 8.1a1.1 1.1 0 0 0 .79.33 1.08 1.08 0 0 0 .78-.33 1.1 1.1 0 0 0 0-1.57z" />
      </svg>
    </Icon>
  )
}
export default XIcon
