import SubscribableStorage, {
  CustomStorageEvent,
} from 'react-storage/lib/SubscribableStorage'

type Unsubscribe = () => void

export interface PersistentStorage<T> {
  get: () => T | null
  set(newValue: T): void
  listen: (callback: (e: CustomStorageEvent<T>) => void) => Unsubscribe
}

export const createPersistentStorage = <T>(
  subscribableStorage: SubscribableStorage,
  key: string,
  cacheBuster?: string
): PersistentStorage<T> => {
  if (cacheBuster) {
    const cacheBusterKey = `${key}.CACHE_BUSTER`
    const previousCacheBuster = subscribableStorage.getItem(cacheBusterKey)
    if (previousCacheBuster !== cacheBuster) {
      subscribableStorage.removeItem(key)
    }
    subscribableStorage.setItem(cacheBusterKey, cacheBuster)
  }

  return {
    get: () => subscribableStorage.getItem<T>(key),
    set: (newValue) => subscribableStorage.setItem<T>(key, newValue),
    listen: (callback) => subscribableStorage.subscribe(key, callback),
  }
}
