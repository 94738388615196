import Cookies from 'universal-cookie'
import { isArray, isEmpty, isString, groupBy } from 'lodash'
import { sessionStorage } from 'react-storage'

import {
  isTealium,
  sendEvent,
  uaConfiguratorAddToCart,
  getCurrencyCode,
  regexForStripSpecialChars,
  regexForStripHTML,
  regexForStripEncodingUnicode,
  DEFAULT_CURRENCY_CODE,
  ADD_TO_CART_GA_DATA_STORAGE_KEY,
  legacySendEvent,
  priceFormatter,
} from '@utils/analytics/coreAnalytics'

import {
  EventActions,
  EventLabel,
  GTMEcommerceItem,
} from '@utils/analytics/types'

import {
  GTMEventCategory,
  EventValues,
  GTMEventTypes,
} from '@sial/common-utils'

import {
  sendAddItemsToCartEvent,
  sendAddItemsToCartErrorEvent,
} from '@utils/tealiumAnalytics'
import { ConfiguratorType } from '@src/routes/Configurators/components/ConfiguratorConfiguration/enum'

export enum ConfiguratorProductType {
  Milliplex = 'milliplex',
  Configurator = 'Configurator',
  Sirna = 'Predesigned siRNA',
}

export type ConfiguratorEventProduct = {
  id: string
  name: string
  variant?: string
  brand: string
  quantity: number
  price: number | null
  dimension91: string
  discount?: number | null
}

const configToolStr = 'configurator tool'

const clubbingUpProductsforConfig = (
  configdata,
  products
): GTMEcommerceItem[] => {
  const itemGroups = groupBy(products || [], (item) => item?.material)
  const productsArray: GTMEcommerceItem[] = Object.values(itemGroups).map(
    (group) => {
      const item = group[0]
      const quant = group.length
      return {
        id: item?.material?.toLowerCase() || EventValues.Empty,
        name:
          configdata?.productName
            ?.toLowerCase()
            .replace(regexForStripEncodingUnicode, '')
            .replace(regexForStripHTML, '')
            .replace(regexForStripSpecialChars, '') || EventValues.Empty,
        variant: item?.material?.toLowerCase() || EventValues.Empty,
        brand: item?.brand?.toLowerCase() || EventValues.Empty,
        quantity: quant || 1,
        price: priceFormatter(item?.price),
        dimension91: 'standard',
      }
    }
  )
  return productsArray || []
}

// GAtracking for addTo cart for configurator products
export const handleGAAddToCartForConfigurator = (configProductData): void => {
  if (isTealium) {
    // for Tealium
    let products
    let currency
    const pageName = GTMEventCategory.ConfiguratorTool?.toLowerCase()
    if (
      configProductData?.productName !== ConfiguratorProductType.Milliplex &&
      configProductData?.productName !== ConfiguratorProductType.Sirna
    ) {
      currency = getCurrencyCode(
        configProductData?.productDetails[0]?.currency || DEFAULT_CURRENCY_CODE
      )
      products = [
        {
          id: (
            configProductData?.productDetails[0]?.material || EventValues.Empty
          )?.toLowerCase(),
          name: (
            configProductData?.productName
              .replace(regexForStripEncodingUnicode, '')
              .replace(regexForStripHTML, '')
              .replace(regexForStripSpecialChars, '') || EventValues.Empty
          )?.toLowerCase(),
          materialNumber: (
            configProductData?.productDetails[0]?.material?.toLowerCase() ||
            EventValues.Empty
          )?.toLowerCase(),
          brand: (
            configProductData?.productDetails[0]?.brand || EventValues.Empty
          )?.toLowerCase(),
          quantity: configProductData?.totalQuantity || 1,
          variant:
            configProductData?.productDetails[0]?.material?.toLowerCase() ||
            EventValues.Empty,
          price: priceFormatter(configProductData?.price),
          dimension91: 'standard',
        },
      ]
    } else if (
      configProductData?.productName === ConfiguratorProductType.Sirna
    ) {
      currency = getCurrencyCode(
        configProductData?.productDetails[0]?.currency || DEFAULT_CURRENCY_CODE
      )

      const productArray = clubbingUpProductsforConfig(
        configProductData,
        configProductData.productDetails
      )
      products = productArray
    } else {
      currency = getCurrencyCode(
        configProductData.milliplexData[0]?.currency || DEFAULT_CURRENCY_CODE
      )
      products =
        configProductData.milliplexData[0]?.listMilliplexAssayModel[0]?.assays
          ?.filter((assay) =>
            configProductData.milliplexData[0]?.checkedProductIdList.includes(
              assay.panelId
            )
          )
          .map((assay) => {
            let gaPrice
            let gaFormattedPrice = '1'
            if (assay?.price) {
              const avgPrice = assay?.price
              if (avgPrice) {
                try {
                  gaPrice = avgPrice
                    .slice(0, avgPrice.length - 3)
                    .replace(/[.,]/g, '')
                  gaFormattedPrice =
                    gaPrice +
                    avgPrice.slice(avgPrice.length - 3, avgPrice.length)
                } catch {
                  gaFormattedPrice = avgPrice
                }
              } else {
                gaFormattedPrice = avgPrice
              }
            }
            return {
              id: assay?.material?.toLowerCase() || EventValues.Empty,
              name:
                assay?.panelName
                  ?.toLowerCase()
                  .replace(regexForStripEncodingUnicode, '')
                  .replace(regexForStripHTML, '')
                  .replace(regexForStripSpecialChars, '') || EventValues.Empty,
              materialNumber:
                assay?.material?.toLowerCase() || EventValues.Empty,
              brand:
                configProductData.milliplexData[0]?.brand.toLowerCase() ||
                EventValues.Empty,
              quantity: assay?.quantity || 1,
              variant: assay?.material?.toLowerCase() || EventValues.Empty,
              price: priceFormatter(gaFormattedPrice),
              dimension91: 'standard',
            }
          }) || []
    }

    sessionStorage.setItem(ADD_TO_CART_GA_DATA_STORAGE_KEY, {
      products,
      pageName,
      currency,
    })

    sendAddItemsToCartEvent({
      sessionStorageKey: ADD_TO_CART_GA_DATA_STORAGE_KEY,
    })
    sessionStorage.removeItem(ADD_TO_CART_GA_DATA_STORAGE_KEY)
  } else {
    const subTotal =
      configProductData?.productDetails.reduce(
        (acc, product) =>
          (Number(product.price) || 1) * (product.quantity ?? 1) + acc,
        0
      ) || 0
    // for GA
    if (
      configProductData?.productName !== ConfiguratorProductType.Milliplex &&
      configProductData?.productName !== ConfiguratorProductType.Sirna
    ) {
      const material = Array.isArray(configProductData?.productDetails)
        ? configProductData?.productDetails[0]?.material?.toLowerCase() ||
          configProductData?.productDetails[0]?.materialNumber?.toLowerCase()
        : configProductData?.productDetails?.pricing?.material
      const addtocartPayload = {
        productIds: material,
        actionTitle: GTMEventCategory.ConfiguratorTool,
        ecommerce: {
          currencyCode: getCurrencyCode(
            configProductData?.productDetails[0]?.currency ||
              DEFAULT_CURRENCY_CODE
          ),
          add: {
            actionField: {
              list: GTMEventCategory.ConfiguratorTool?.toLowerCase(),
            },
            products: [
              {
                id: (material || EventValues.Empty)?.toLowerCase(),
                name: (
                  configProductData?.productName
                    .replace(regexForStripEncodingUnicode, '')
                    .replace(regexForStripHTML, '')
                    .replace(regexForStripSpecialChars, '') || EventValues.Empty
                )?.toLowerCase(),
                variant: (
                  configProductData?.productDetails[0]?.material ||
                  EventValues.Empty
                )?.toLowerCase(),
                brand: (
                  configProductData?.productDetails[0]?.brand ||
                  EventValues.Empty
                )?.toLowerCase(),
                quantity: configProductData?.totalQuantity || 1,
                price: priceFormatter(configProductData?.price),
                dimension91: 'standard',
              },
            ],
          },
        },
      }

      uaConfiguratorAddToCart(addtocartPayload)

      // GA4 - Add To Cart
      legacySendEvent({
        payload: {
          event: 'add_to_cart',
          addtocart_type: 'default',
          ecommerce: {
            value: subTotal,
            currency: getCurrencyCode(
              configProductData?.productDetails[0]?.currency ||
                DEFAULT_CURRENCY_CODE
            ),
            items: [
              {
                item_id: (
                  configProductData?.productDetails[0]?.material ||
                  configProductData?.productDetails[0]?.materialNumber ||
                  EventValues.Empty
                )?.toLowerCase(),
                item_name: (
                  configProductData?.productName
                    .replace(regexForStripEncodingUnicode, '')
                    .replace(regexForStripHTML, '')
                    .replace(regexForStripSpecialChars, '') || EventValues.Empty
                )?.toLowerCase(),
                discount: configProductData?.discount || 0,
                item_brand: (
                  configProductData?.productDetails[0]?.brand ||
                  EventValues.Empty
                )?.toLowerCase(),
                item_list_id: configToolStr,
                item_list_name: configToolStr,
                item_variant: (
                  configProductData?.productDetails[0]?.material ||
                  EventValues.Empty
                )?.toLowerCase(),
                price: priceFormatter(configProductData?.price),
                item_type: 'standard',
                quantity: configProductData?.totalQuantity || 1,
              },
            ],
          },
        },
      })
    } else if (
      configProductData?.productName === ConfiguratorProductType.Sirna
    ) {
      const trackingData = configProductData.productDetails
        .map((item) => item.subSetInfo)
        .flat()
      const productArray = clubbingUpProductsforConfig(
        configProductData,
        trackingData
      )

      const addtocartPayload = {
        productIds:
          productArray.map((product) => product.id.toLowerCase()).join('|') ||
          EventValues.Empty,
        actionTitle: GTMEventCategory.ConfiguratorTool,
        ecommerce: {
          currencyCode: getCurrencyCode(
            configProductData.productDetails[0]?.currency ||
              DEFAULT_CURRENCY_CODE
          ),
          add: {
            actionField: {
              list: GTMEventCategory.ConfiguratorTool?.toLowerCase(),
            },

            products: productArray,
          },
        },
      }

      uaConfiguratorAddToCart(addtocartPayload)

      // GA4 - Add To Cart
      legacySendEvent({
        payload: {
          event: 'add_to_cart',
          addtocart_type: 'default',
          ecommerce: {
            value: subTotal,
            currency: getCurrencyCode(
              configProductData.productDetails[0]?.currency ||
                DEFAULT_CURRENCY_CODE
            ),
            items: productArray.map((product) => ({
              item_id: (product.id || EventValues.Empty)?.toLowerCase(),
              item_name: (product.name || EventValues.Empty)?.toLowerCase(),
              discount: product?.discount || 0,
              item_brand: (product.brand || EventValues.Empty)?.toLowerCase(),
              item_list_id: configToolStr,
              item_list_name: configToolStr,
              item_variant: (
                product.variant || EventValues.Empty
              )?.toLowerCase(),
              price: priceFormatter(product?.price),
              item_type: 'standard',
              quantity: product.quantity || 1,
            })),
          },
        },
      })
    } else {
      // GAtracking for add To cart for configurator product Milliplex
      const addtocartPayload = {
        productIds:
          configProductData.milliplexData[0]?.listMilliplexAssayModel[0]?.assays
            ?.filter((assay) =>
              configProductData.milliplexData[0]?.checkedProductIdList.includes(
                assay.panelId
              )
            )
            .map((assay) => assay.material?.toLowerCase())
            .join('|') || EventValues.Empty,
        actionTitle: GTMEventCategory.ConfiguratorTool,
        ecommerce: {
          currencyCode: getCurrencyCode(
            configProductData.milliplexData[0]?.currency ||
              DEFAULT_CURRENCY_CODE
          ),
          add: {
            actionField: {
              list: GTMEventCategory.ConfiguratorTool
                ? GTMEventCategory.ConfiguratorTool.toLowerCase()
                : EventValues.Empty,
            },
            products:
              configProductData.milliplexData[0]?.listMilliplexAssayModel[0]?.assays
                ?.filter((assay) =>
                  configProductData.milliplexData[0]?.checkedProductIdList.includes(
                    assay.panelId
                  )
                )
                .map((assay) => {
                  let gaPrice
                  let gaFormattedPrice = '1'
                  if (assay?.price) {
                    const avgPrice = assay?.price
                    if (avgPrice) {
                      try {
                        gaPrice = avgPrice
                          .slice(0, avgPrice.length - 3)
                          .replace(/[.,]/g, '')
                        gaFormattedPrice =
                          gaPrice +
                          avgPrice.slice(avgPrice.length - 3, avgPrice.length)
                      } catch {
                        gaFormattedPrice = avgPrice
                      }
                    } else {
                      gaFormattedPrice = avgPrice
                    }
                  }
                  return {
                    id: assay.material
                      ? assay.material.toLowerCase()
                      : EventValues.Empty,
                    name: assay.panelName
                      ? assay.panelName
                          .toLowerCase()
                          .replace(regexForStripEncodingUnicode, '')
                          .replace(regexForStripHTML, '')
                          .replace(regexForStripSpecialChars, '')
                      : EventValues.Empty,
                    variant: assay.material
                      ? assay.material.toLowerCase()
                      : EventValues.Empty,
                    brand:
                      configProductData.milliplexData[0] &&
                      configProductData.milliplexData[0].brand
                        ? configProductData.milliplexData[0].brand.toLowerCase()
                        : EventValues.Empty,
                    quantity: assay.quantity ? assay.quantity : 1,
                    price: priceFormatter(gaFormattedPrice),
                    dimension91: 'standard',
                  }
                }) || [],
          },
        },
      }

      uaConfiguratorAddToCart(addtocartPayload)

      // GA4 - Add To Cart
      legacySendEvent({
        payload: {
          event: 'add_to_cart',
          addtocart_type: 'default',
          ecommerce: {
            value: subTotal,
            currency: getCurrencyCode(
              configProductData.milliplexData[0]?.currency ||
                DEFAULT_CURRENCY_CODE
            ),
            items: addtocartPayload.ecommerce.add.products.map((product) => ({
              item_id: (product.id || EventValues.Empty)?.toLowerCase(),
              item_name: (product.name || EventValues.Empty)?.toLowerCase(),
              discount: product?.discount || 0,
              item_brand: (product.brand || EventValues.Empty)?.toLowerCase(),
              item_list_id: configToolStr,
              item_list_name: configToolStr,
              item_variant: (
                product.variant || EventValues.Empty
              )?.toLowerCase(),
              price: priceFormatter(product?.price),
              item_type: 'standard',
              quantity: product.quantity || 1,
            })),
          },
        },
      })
    }
  }
}

export const handleGAAddToCartErrorsForConfigurator = (
  configProductData,
  errorMessage
): void => {
  const productName = configProductData?.productName
  const eventLabel =
    `${productName?.toLowerCase()} - ${errorMessage}` || EventValues.Empty
  if (isTealium) {
    const products = [
      {
        eventAction: EventActions.AddToCartErrors,
        eventCategory: GTMEventCategory.Errors?.toLowerCase(),
        eventLabel: eventLabel?.toLowerCase(),
      },
    ]

    sendAddItemsToCartErrorEvent(products)
  }

  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.AddToCartErrors,
      eventAction: EventActions.AddToCartErrors?.toLowerCase(),
      eventLabel: eventLabel?.toLowerCase(),
      eventInteractionType: 1,
    },
  })
}

const getConfiguratorEventAction = (
  configuratorType: ConfiguratorType
): string => {
  switch (configuratorType) {
    case ConfiguratorType.LabWater:
      return 'lab water configurator'
    case ConfiguratorType.MilliQSq:
      return 'modulis configurator'
    case ConfiguratorType.Milliplex:
      return 'milliplex configurator'
    default:
      return ''
  }
}

export const sendConfiguratorUserInteractionEvent = (
  eventAction: string,
  configuratorType: ConfiguratorType,
  eventLabel?: string | string[] | undefined | null
): void => {
  const cookies = new Cookies()
  const gaCookie = cookies.get('_ga') || EventValues.Empty
  const configuratorEventAction = getConfiguratorEventAction(configuratorType)
  let eventActionToSend
  if (isEmpty(eventLabel)) {
    eventActionToSend = gaCookie
  } else if (isString(eventLabel)) {
    eventActionToSend = eventLabel
  } else if (isArray(eventLabel)) {
    eventActionToSend = eventLabel.join(' | ')
  }

  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: configuratorEventAction,
      eventAction: eventAction.toLowerCase(),
      eventLabel: eventActionToSend.toLowerCase(),
      eventInteractionType: 0,
    },
  })
}

export const sendAddToCartConfiguratorEvent = (
  products: ConfiguratorEventProduct[],
  currency: string | null | undefined,
  configuratorType: ConfiguratorType
): void => {
  const labwaterProduct = [...products]
  labwaterProduct.forEach((item) => {
    delete item.discount
  })
  const configuratorEventAction = getConfiguratorEventAction(configuratorType)
  // UA for Add To Cart
  legacySendEvent({
    payload: {
      event: GTMEventTypes.AddToCart,
      eventCategory: GTMEventCategory.AddToCart,
      eventAction: configuratorEventAction,
      eventLabel: products.map((p) => p.id).join(' | '),
      eventInteractionType: 0,
      ecommerce: {
        currencyCode: currency ? getCurrencyCode(currency) : null,
        add: {
          actionField: {
            list: configuratorEventAction,
          },
          labwaterProduct,
        },
      },
    },
  })

  // GA4 for Add To Cart
  legacySendEvent({
    payload: {
      event: 'add_to_cart',
      addtocart_type: 'default',
      ecommerce: {
        currency: currency ? getCurrencyCode(currency) : null,
        value: products.reduce(
          (acc, product) =>
            (Number(product.price) || 1) * (product.quantity ?? 1) + acc,
          0
        ),
        items: products.map((product) => ({
          item_id: product.id,
          item_name: product.name,
          discount: product?.discount || 0,
          item_brand: product.brand,
          item_list_id: configToolStr,
          item_list_name: configToolStr,
          item_variant: product?.variant || EventValues.Empty,
          price: priceFormatter(product?.price),
          item_type: product.dimension91,
          quantity: product.quantity,
        })),
      },
    },
  })
}

export const sendConfiguratorGADownloadEvent = (
  action: string,
  label: string
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: EventValues.Downloads.toLowerCase(),
      eventAction:
        EventActions.Templates === action
          ? `${EventActions.Configurator} ${EventActions.Templates}`
          : `${EventActions.Configurator} ${EventActions.Summary}`,
      eventLabel:
        EventActions.Templates === action
          ? `${label} ${EventLabel.Template}`
          : `${label} ${EventActions.Summary}`,
      eventInteractionType: 0,
    },
  })
}
