export enum ConfiguratorType {
  LabWater = 'labwater',
  MilliQSq = 'milliqsq',
  Milliplex = 'milliplex',
}

export enum ConfiguratorProperties {
  ConfigurationNameMaxLength = 50,
  MessageToSpecialistMaxLength = 500,
}
