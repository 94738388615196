import { GraphQLRequest } from 'apollo-boost'

export default function createGraphQLOperationName(
  graphQLRequest: GraphQLRequest
): object {
  const operationName: string = graphQLRequest.operationName
    ? graphQLRequest.operationName
    : ''
  if (operationName !== '') {
    const variables = graphQLRequest.variables ? graphQLRequest.variables : {}
    if (variables.id) {
      return {
        'x-gql-operation-name': `${operationName}-${variables.id}`,
      }
    } else if (variables.action) {
      return {
        'x-gql-operation-name': `${operationName}-${variables.action}`,
      }
    } else {
      return {
        'x-gql-operation-name': operationName,
      }
    }
  } else {
    return {}
  }
}
