import { createWalkMeContext } from '@src/utils/createWalkMeContext'

const [InvoicesWalkMeContext, useInvoicesWalkMeContext] = createWalkMeContext(
  {}
)

const InvoicesWalkMeProvider: React.FC = ({ children }) => {
  const invoicesWalkMe = useInvoicesWalkMeContext()

  return (
    <InvoicesWalkMeContext.Provider value={invoicesWalkMe}>
      {children}
    </InvoicesWalkMeContext.Provider>
  )
}

export { InvoicesWalkMeProvider, InvoicesWalkMeContext }
