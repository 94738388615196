import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import getConfig from 'next/config'
import { parseUrl } from '@utils/parseUrl'

type SeoTagsProps = {
  canonicalUrl?: string | null
  keywords?: string[] | null
  description?: string | null
  canonicalParamAllowed?: Function
  schema?: object | null
  indexOverride?: 'noindex' | 'index'
  followOverride?: 'nofollow' | 'follow'
  isCMSRoute?: boolean
  isNewHomepage?: boolean
}

type HrefLangInput = {
  hrefLang: string
  href: string
}

const {
  publicRuntimeConfig: {
    canonicalUrlPrefix,
    availableLanguages,
    seoNoIndex,
    seoNoIndexExceptions,
  },
} = getConfig()

const setHrefOrigin = (countyCode: string, origin?: string) => {
  return countyCode === 'CN'
    ? origin?.replace('.com', '.cn')
    : origin?.replace('.cn', '.com')
}

const removeAnchor = (url?: string) => {
  return url?.split('#')[0]
}

const formattedLanguages = (origin?: string, path?: string) => {
  path = removeAnchor(path)
  return Object.keys(availableLanguages)
    .map((key) => [key, availableLanguages[key]])
    .reduce((acc, [countryCode, { defaultFor, languages }]) => {
      languages.forEach((language: any) => {
        const hrefOrigin = setHrefOrigin(countryCode, origin)
        acc.push({
          hrefLang: `${language}-${countryCode.toLowerCase()}`,
          href: `${hrefOrigin}/${countryCode}/${language}${path}`,
        })
      })

      if (origin?.endsWith('.cn')) {
        acc.push({
          hrefLang: 'x-default',
          href: `${origin?.replace('.cn', '.com')}/US/en${path}`,
        })
      } else {
        acc.push({
          hrefLang: 'x-default',
          href: `${origin}/US/en${path}`,
        })
      }

      if (defaultFor) {
        const hrefOrigin = setHrefOrigin(countryCode, origin)
        acc.push({
          hrefLang: defaultFor,
          href: `${hrefOrigin}/${countryCode}/${defaultFor}${path}`,
        })
      }

      return acc
    }, [] as HrefLangInput[])
}

const parseQueryString = (url: string): Record<string, string> => {
  const queryString = url.split('?')[1]
  const queryStringDictionary = {}

  if (queryString?.length > 0) {
    const parameters = queryString.split('&')

    parameters.forEach((parameter) => {
      const [key, value] = parameter.split('=')

      if (key?.length > 0) {
        queryStringDictionary[key] = value
      }
    })
  }

  return queryStringDictionary
}

const scrubQueryParams = (url: string, paramAllowedPredicate?: Function) => {
  if (!paramAllowedPredicate) return url
  const parsedUrl = new URL(url)
  const params = parseQueryString(parsedUrl.search)
  Object.keys(params).forEach((key) => {
    if (!paramAllowedPredicate(key, params[key])) {
      parsedUrl.searchParams.delete(key)
    }
  })
  return parsedUrl.toString()
}

const allowIndexing = (asPath: string) => {
  // if we're allowing indexing everywhere, allow bots to index this page.
  if (!seoNoIndex) return true
  // if there are no exceptions, disallow indexing
  if (!seoNoIndexExceptions?.length) return false
  // allow indexing only if this page is in the list of exceptions
  let index = false
  seoNoIndexExceptions.forEach((path) => {
    const regex = new RegExp(path, 'i')
    const isWhitelisted = regex.test(asPath)
    if (isWhitelisted) index = true
  })
  return index
}

export const SeoTags = ({
  keywords,
  description,
  canonicalParamAllowed,
  schema,
  indexOverride,
  followOverride,
  isCMSRoute,
  isNewHomepage,
  canonicalUrl,
}: SeoTagsProps) => {
  const { asPath } = useRouter()

  /*AEM author can set canonical URL to an AEM page or a non-AEM page.
   *For an AEM page, we get the country and language as a part of URL.
   *For a non AEM page, we need to set these using asPath.*/
  const getPathname = () => {
    if (canonicalUrl && canonicalUrl?.length) {
      const canonicalUrlArr = canonicalUrl.split('/')
      const canonicalCountry = canonicalUrlArr[1]
      const pathnameArr = asPath.split('/')
      const pathnameCountry = pathnameArr[1]
      const pathnameLang = pathnameArr[2]
      return canonicalCountry === pathnameCountry
        ? canonicalUrl
        : `/${pathnameCountry}/${pathnameLang}${canonicalUrl}`
    }
    return ''
  }
  let pathname = ''
  if (typeof window !== 'undefined') {
    pathname =
      (window.location.pathname !== '/' &&
        canonicalParamAllowed === undefined) ||
      isCMSRoute
        ? isCMSRoute && canonicalUrl?.length
          ? getPathname()
          : window.location.pathname
        : asPath
  } else pathname = isCMSRoute && canonicalUrl?.length ? getPathname() : asPath

  const url = scrubQueryParams(
    `${canonicalUrlPrefix}${pathname}`,
    canonicalParamAllowed
  )
  const { country, language, path = '', query = '' } = parseUrl(url)
  const { host, protocol } = new URL(url)
  const pathWithQuery = `${path.toLocaleLowerCase()}${query.toLocaleLowerCase()}`

  const shouldIndex = allowIndexing(asPath)

  const getGooglebotContent = () => {
    const indexContent = shouldIndex ? indexOverride || 'index' : 'noindex'
    const followContent = shouldIndex ? followOverride || 'follow' : 'nofollow'

    return `${indexContent},${followContent}`
  }

  return (
    <>
      <NextSeo
        additionalMetaTags={[
          {
            name: 'googlebot',
            content: getGooglebotContent(),
          },
          {
            property: 'keywords',
            content: (keywords || []).join(','),
          },
        ]}
        canonical={
          isNewHomepage
            ? `${canonicalUrlPrefix}/${country}/${language}`
            : `${canonicalUrlPrefix}/${country}/${language}${removeAnchor(
                pathWithQuery
              )}`
        }
        description={description || ''}
        languageAlternates={formattedLanguages(
          `${protocol}//${host}`,
          `${pathWithQuery}`
        )}
        nofollow={!shouldIndex}
        noindex={!shouldIndex}
      />
      <Head>
        {schema && (
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
          />
        )}
      </Head>
    </>
  )
}

export const convertToSeoFriendly = (inputString: string): string => {
  if (inputString === null || inputString === undefined) {
    return ''
  }

  return inputString
    .trim()
    .replace(/[^a-z0-9]+/gi, '-')
    .replace(/-+/g, '-')
    .toLowerCase()
}
