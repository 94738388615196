import { createWalkMeContext } from '@src/utils/createWalkMeContext'

const [InviteCoWorkerWalkMeContext, useInviteCoWorkerWalkMeContext] =
  createWalkMeContext({})

const InviteCoWorkerWalkMeProvider = ({ children }) => {
  const inviteCoWorkerWalkMe = useInviteCoWorkerWalkMeContext()

  return (
    <InviteCoWorkerWalkMeContext.Provider value={inviteCoWorkerWalkMe}>
      {children}
    </InviteCoWorkerWalkMeContext.Provider>
  )
}

export { InviteCoWorkerWalkMeProvider, InviteCoWorkerWalkMeContext }
