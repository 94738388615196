import getConfig from 'next/config'
import { useEffect } from 'react'
import { useRouter } from '@src/routes'

export const OptimizelyPageTracking = () => {
  const router = useRouter()

  const activateOptlyPage = (optlyPage) => {
    window.optimizely?.push({
      type: 'page',
      pageName: optlyPage.apiName,
      isActive: false,
    })

    window.optimizely?.push({
      type: 'page',
      pageName: optlyPage.apiName,
    })
  }

  const {
    publicRuntimeConfig: { optimizelyId },
  } = getConfig()

  useEffect(() => {
    // Optimizely gets weird with their function binding to the window object.
    // So we want to validate it exists and that all their functions are fully initialized.
    const isOptimizelyFullyInitialized =
      window.optimizely &&
      'get' in window.optimizely &&
      typeof window.optimizely.get === 'function'

    if (!optimizelyId || !isOptimizelyFullyInitialized) return

    try {
      const optlyPages = window?.optimizely?.get('data')?.pages || {}
      for (const page in optlyPages) {
        activateOptlyPage(optlyPages[page])
      }
    } catch (e) {
      console.error('Problem initializing Optimizely pages.')
      console.error(e)
    }
  }, [router])

  return <></>
}

export default OptimizelyPageTracking
