import React from 'react'
import App from 'next/app'
import { ApolloProvider } from '@apollo/react-hooks'
import { createApolloClient } from './apolloClient'
import { FullCartRefetchProvider } from '@utils/useFullCartRefetch'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import getCookiesFromContext from '@src/utils/getCookiesFromContext'
import { Cookies } from 'react-cookie'
import { NextPageContext, NextPage } from 'next'
import { log } from '@src/utils/logging'

// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
let globalApolloClient: ApolloClient<NormalizedCacheObject>

// Always creates a new apollo client on the server
// Creates or reuses apollo client in the browser.
export const initApolloClient = (
  cookies: Cookies,
  initialState?: NormalizedCacheObject,
  ctx?: NextPageContext
) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(cookies, ctx)
  }

  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(cookies, ctx, initialState)
  }

  if (initialState && globalApolloClient) {
    globalApolloClient.cache.restore(initialState)
  }

  return globalApolloClient
}

// Installs the Apollo Client on NextPageContext or AppContext.
// Could be extracted from here if we wanted page-specific Apollo
// setup inside getStaticProps, getStaticPaths or getServerSideProps
const initOnContext = (ctx: any, cookies: Cookies) => {
  const inAppContext = Boolean(ctx.ctx)

  // Initialize ApolloClient if not already done
  const apolloClient =
    ctx.apolloClient ||
    initApolloClient(cookies, ctx.apolloState, inAppContext ? ctx.ctx : ctx)

  // We send the Apollo Client as a prop to the component to avoid calling initApollo() twice in the server.
  // Otherwise, the component would have to call initApollo() again but this
  // time without the context. Once that happens, the following code will make sure we send
  // the prop as `null` to the browser.
  apolloClient.toJSON = () => null

  // Add apolloClient to NextPageContext & AppContext.
  // This allows us to consume the apolloClient inside our
  // custom `getInitialProps({ apolloClient })`.
  ctx.apolloClient = apolloClient
  if (inAppContext) {
    ctx.ctx.apolloClient = apolloClient
  }

  return ctx
}

// Creates a withApollo HOC that provides the apolloContext to a next.js Page or AppTree.
export const withApollo =
  ({ ssr = false } = {}) =>
  (PageComponent: NextPage) => {
    const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
      let client
      if (apolloClient) {
        // Happens on: getDataFromTree & next.js ssr
        client = apolloClient
      } else {
        // Happens on: next.js csr
        client = initApolloClient(new Cookies(), apolloState, undefined)
      }

      return (
        <ApolloProvider client={client}>
          <FullCartRefetchProvider>
            <PageComponent {...pageProps} />
          </FullCartRefetchProvider>
        </ApolloProvider>
      )
    }

    // Set the correct displayName in development
    if (process.env.NODE_ENV !== 'production') {
      const displayName =
        PageComponent.displayName || PageComponent.name || 'Component'
      WithApollo.displayName = `withApollo(${displayName})`
    }

    if (ssr || PageComponent.getInitialProps) {
      WithApollo.getInitialProps = async (ctx) => {
        const inAppContext = Boolean(ctx.ctx)
        const cookies = getCookiesFromContext(inAppContext ? ctx.ctx : ctx)
        const { apolloClient } = initOnContext(ctx, cookies)

        // Run wrapped getInitialProps methods
        let pageProps = {}
        if (PageComponent.getInitialProps) {
          pageProps = await PageComponent.getInitialProps(ctx)
        } else if (inAppContext) {
          pageProps = await App.getInitialProps(ctx)
        }

        // Only on the server:
        if (typeof window === 'undefined') {
          const { AppTree } = ctx
          // When redirecting, the response is finished.
          // No point in continuing to render
          if (ctx.res?.finished) {
            return pageProps
          }

          // Only if dataFromTree is enabled
          if (ssr && AppTree) {
            try {
              // Import `@apollo/react-ssr` dynamically.
              // We don't want to have this in our client bundle.
              const { getDataFromTree } = await import('@apollo/react-ssr')

              // Since AppComponents and PageComponents have different context types
              // we need to modify their props a little.
              let props
              if (inAppContext) {
                props = { ...pageProps, apolloClient }
              } else {
                props = { pageProps: { ...pageProps, apolloClient } }
              }

              // Take the Next.js AppTree, determine which queries are needed to render,
              // and fetch them. This method can be pretty slow since it renders
              // the entire AppTree once for every query.
              await getDataFromTree(<AppTree {...props} />)
            } catch (error) {
              log.error(error, 'Error while running `getDataFromTree`')
            }
          }
        }

        // Set the status code whether that's from getInitialProps or getDataFromTree
        if (ctx.ctx?.res) {
          ctx.ctx.res.statusCode = ctx.ctx?.res?.statusCode ?? 200
        }

        return {
          ...pageProps,
          // Extract query data from the Apollo store
          apolloState: apolloClient.cache.extract(),
          // Provide the client for ssr. As soon as this payload
          // gets JSON.stringified it will remove itself.
          apolloClient: ctx.apolloClient,
        }
      }
    }

    return WithApollo
  }
