import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { sessionStorage } from 'react-storage'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core/'
import LinkStyleButton from '@src/components/LinkStyleButton'
import WalkMeStep from '@src/components/WalkMeStep'
import { Step } from '@src/components/WalkMeStep/StepBody'
import { WalkMeStepProps } from '@src/components/WalkMeStep/WalkMeStep'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import { useUserActivity } from '@src/utils/useUserActivity'
import {
  MyNetworkWalkMeConstants,
  getMyNetworkStorageOverride,
  useMyNetworkWalkMe,
} from '@src/routes/Profile/MyNetwork/MyNetworkWalkMe'

const useStyles = makeStyles((theme) => ({
  step: {
    marginTop: theme.spacing(-1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const steps: Step[] = [
  {
    title: {
      id: 'WALK_ME_VIEW_DETAILS_MN',
      defaultMessage: 'Autonomy for Your team',
    },
    description: {
      id: 'WALK_ME_VIEW_DETAILS_TIP_MN',
      defaultMessage:
        'Easy Registration for your coworkers and control over your organization.',
    },
  },
  {
    title: {
      id: 'WALK_ME_MY_NETWORK_OPTIONS',
      defaultMessage: 'Invite and Manage Coworkers',
    },
    description: {
      id: 'WALK_ME_MY_NETWORK_ACCESS_TIP',
      defaultMessage:
        'Invite your coworkers, edit permissions for ordering and quotes, or manage coworker access to your organization.',
    },
  },
  {
    title: {
      id: 'WALK_ME_MY_NETWORK',
      defaultMessage: 'Streamline Address Management',
    },
    description: {
      id: 'WALK_ME_MY_NETWORK_TIP',
      defaultMessage:
        'Create groups to organize your coworkers. Edit Shipping, billing and payer addresses for all coworkers within a group.',
    },
  },
]

type MyNetworkWalkMeStepProps = Pick<
  WalkMeStepProps,
  'anchorEl' | 'placement'
> & {
  stepNumber: number

  clickNext: (arg) => void
}

export const MyNetworkWalkMeStep = ({
  anchorEl,
  placement,
  stepNumber,
  clickNext,
}: MyNetworkWalkMeStepProps) => {
  const classes = useStyles()
  const { state, dismissStep, setEnabled, advanceActiveStepNumber } =
    useMyNetworkWalkMe()
  const [num, setNum] = useState(0)
  useEffect(() => {
    setNum(stepNumber)
  }, [stepNumber])
  const { userIsLoggedIn } = useCurrentUser()
  const { updateUserActivity, trackingData } = useUserActivity(
    MyNetworkWalkMeConstants.TRACKING_DATA_KEY,
    getMyNetworkStorageOverride(userIsLoggedIn)
  )

  const step = steps[stepNumber - 1]

  const showWalkMeStep =
    state.enabled &&
    !state.stepDismissed &&
    state.activeStepNumber === stepNumber

  const dismissedCount = trackingData?.numWalkMeDismissals || 0

  const optOutOfWalkMe = () => {
    sessionStorage.setItem(MyNetworkWalkMeConstants.DISMISSED_KEY, true)
    updateUserActivity({
      numWalkMeDismissals: dismissedCount + 1,
      hasOptedOutOfWalkMe: true,
    })
    setEnabled(false)
  }

  const onClickAway = () => {
    if (stepNumber !== 1) dismissStep()
    if (state.activeStepNumber === steps.length) {
      sessionStorage.setItem(MyNetworkWalkMeConstants.DISMISSED_KEY, true)
      updateUserActivity({ hasCompletedWalkMe: true })
    }
  }

  return showWalkMeStep && num ? (
    <WalkMeStep
      id={`walkme-step-mn-${stepNumber}`}
      className={classes.step}
      anchorEl={anchorEl}
      placement={placement}
      step={step}
      onClose={optOutOfWalkMe}
      onClickAway={onClickAway}
      footer={
        <div className={classes.footer}>
          <LinkStyleButton data-testid="dismiss-tips" onClick={optOutOfWalkMe}>
            <FormattedMessage
              id="DONT_SHOW_ME_TIPS"
              defaultMessage="Don't Show Me Tips"
            />
          </LinkStyleButton>

          {(stepNumber === 1 || stepNumber === 2) && (
            <Button
              data-testid="start-tour"
              variant="contained"
              color="primary"
              onClick={() => {
                advanceActiveStepNumber(stepNumber + 1)
                clickNext(stepNumber + 1)
              }}
            >
              {stepNumber === 1 ? (
                <FormattedMessage
                  id="SHOW_ME_WHATS_NEW"
                  defaultMessage="Show me what's new"
                />
              ) : (
                <FormattedMessage
                  id="SHOW_ME_WHATS_NEXT"
                  defaultMessage="Next"
                />
              )}
            </Button>
          )}
        </div>
      }
    />
  ) : null
}
