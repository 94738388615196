import pino, { PrettyOptions, Logger } from 'pino'
import getConfig from 'next/config'

const isDevelopment = process.env.NODE_ENV === 'development'

const {
  publicRuntimeConfig: {
    logConfig: { name, level },
  },
} = getConfig()

export const log: Logger = pino({
  name: name,
  level: level,
  prettyPrint: isDevelopment
    ? ({
        ignore: 'pid,hostname',
        translateTime: true,
        levelFirst: true,
      } as PrettyOptions)
    : false,
})
