import { isServer } from '@src/utils/isServer'
import { FsApiFunction } from './analytics/types'

declare global {
  interface Window {
    _fs_app_host?: string
    _fs_asset_map_id?: string
    _fs_capture_on_startup?: boolean
    _fs_cookie_domain?: string
    _fs_debug?: boolean
    _fs_dev_mode?: boolean
    _fs_host?: string
    _fs_initialized?: boolean
    _fs_is_outer_script?: boolean
    _fs_namespace?: string
    _fs_org?: string
    _fs_run_in_iframe?: boolean
    _fs_script?: string
  }
}

// Reference for the below functions: https://github.com/fullstorydev/integration-examples/blob/master/src/utils/fs.js
// These functions were tweaked from the 3rd party code provided to utilize our isServer util and make TS happier
// FullStory is currently implemented through GTM – these allow us to check for and access that instance

/**
 * Gets the FullStory recording API from the window.
 * @param api An optional API that returns the callable API as a function
 * @returns The FullStory "FS" function or a specific API as a function
 */
export function fs(api?: string): FsApiFunction | (() => void) {
  if (!hasFs()) {
    return function () {
      console.error('FullStory unavailable, check your snippet or tag')
    }
  } else {
    // guard against older snippets that may not define an API
    if (!isServer() && api && !window[window._fs_namespace!][api]) {
      return function () {
        console.error(
          window._fs_namespace +
            '.' +
            api +
            ' unavailable, update your snippet or verify the API call'
        )
      }
    }
    return !isServer() && api
      ? window[window._fs_namespace!][api]
      : window[window._fs_namespace!]
  }
}

/**
 * Tests if the FullStory recording API exists in the window.
 * @returns True if the recording API exists and has the function type
 */
export function hasFs(): boolean | string | undefined {
  return (
    !isServer() &&
    window._fs_namespace &&
    typeof window[window._fs_namespace] === 'function'
  )
}
