import { localStorage } from 'react-storage'
import { createPersistentStorage } from '@src/utils/createPersistentStorage'

const loginStorage = createPersistentStorage<{ rememberMe: boolean }>(
  localStorage,
  'LOGIN',
  'v1'
)

export default loginStorage
