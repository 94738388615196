import React from 'react'
import Head from 'next/head'

const AppVersion = () => (
  <>
    {process.env.RELEASE && (
      <Head>
        <meta name="app-version" content={process.env.RELEASE} />
      </Head>
    )}
  </>
)

export default AppVersion
