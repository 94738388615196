import React from 'react'
import Head from 'next/dist/shared/lib/head'
import getConfig from 'next/config'

export const AppHead = () => {
  return (
    <Head>
      <title>Sigma-Aldrich</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
        key="viewport"
      />
      <link rel="preconnect" href="https://t.jabmo.app" />
      <link rel="preconnect" href="https://analytics.twitter.com" />
      <link rel="preconnect" href="https://t.co" />
      <link rel="preconnect" href="https://secure.adnxs.com" />
      <link rel="preconnect" href="https://segments.company-target.com" />
      <link rel="preconnect" href="https://api.company-target.com" />
      <link rel="preconnect" href="https://d22d1xpx4ztuef.cloudfront.net" />
      <link rel="preconnect" href="https://www.facebook.com" />
      <link rel="preconnect" href="https://gateway.foresee.com" />
      <link rel="preconnect" href="https://match.prod.bidr.io" />
      <link rel="preconnect" href="https://device.4seeresults.com" />
      <link rel="preconnect" href="https://stats.g.doubleclick.net" />
      <link rel="preconnect" href="https://www.google-analytics.com" />
      <link rel="preconnect" href="https://googleads.g.doubleclick.net" />
      <link rel="preconnect" href="https://www.google.com" />
      <link rel="preconnect" href="https://adservice.google.com" />
      <link rel="preconnect" href="https://www.googletagmanager.com" />
      <link rel="preconnect" href="https://static.ads-twitter.com" />
      <link rel="preconnect" href="https://5846014.fls.doubleclick.net" />
      <link rel="preconnect" href="https://px.ads.linkedin.com" />
      <link rel="preconnect" href="https://www.linkedin.com" />
      <link rel="preconnect" href="https://snap.licdn.com" />
      <link rel="preconnect" href="https://connect.facebook.net" />
      <link rel="preconnect" href="https://bat.bing.com" />
      <link rel="preconnect" href="https://www.googleadservices.com" />
      <link rel="preconnect" href="https://id.rlcdn.com" />
      <link rel="preconnect" href="https://p.adsymptotic.com" />
      <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
      <link rel="preconnect" href="https://tag.demandbase.com" />
    </Head>
  )
}

// Different locales in our app require different font files to get character coverage.
// For example Japanese has a great many different characters different from English or other Latin alphabet fonts.
// To optimize loading we only want to load extended character sets when the app requires it.
export const LocaleFontLoader = ({ country }: { country: string }) => {
  const simplifiedChineseFontFiles = {
    style: '/static/fonts/noto-sans-sc.css',
    files: ['/static/fonts/Noto_Sans_SC/NotoSansSC-VariableFont_wght.ttf'],
  }

  const traditionalChineseFontFiles = {
    style: '/static/fonts/noto-sans-tc.css',
    files: ['/static/fonts/Noto_Sans_TC/NotoSansTC-VariableFont_wght.ttf'],
  }

  const notoSansExtendedCharSet = {
    style: '/static/fonts/noto-sans.css',
    files: [
      '/static/fonts/Noto_Sans/NotoSans-VariableFont_wdth-wght.ttf',
      '/static/fonts/Noto_Sans/NotoSans-Italic-VariableFont_wdth-wght.ttf',
    ],
  }

  // Languages using additional font faces
  const additionalFontFaces = {
    // Polish needs an expanded character set not present in Lato. So Noto-Sans will full Latin, Cyrillic and Greek scripts is needed.
    PL: notoSansExtendedCharSet,
    //Czech Republic needs an expanded character set not present in Lato. So Noto-Sans will full Latin, Cyrillic and Greek scripts is needed.
    CZ: notoSansExtendedCharSet,
    // Hungary needs an expanded character set not present in Lato. So Noto-Sans will full Latin, Cyrillic and Greek scripts is needed.
    HU: notoSansExtendedCharSet,
    // Font support with all Japanese characters
    JP: {
      style: '/static/fonts/noto-sans-jp.css',
      files: ['/static/fonts/Noto_Sans_JP/NotoSansJP-VariableFont_wght.ttf'],
    },
    // Font support with all Korean characters
    KR: {
      style: '/static/fonts/noto-sans-kr.css',
      files: ['/static/fonts/Noto_Sans_KR/NotoSansKR-VariableFont_wght.ttf'],
    },
    // Simplified Chinese Noto Sans file for Mainland China and Singapore
    CN: simplifiedChineseFontFiles,
    SG: simplifiedChineseFontFiles,
    HK: simplifiedChineseFontFiles,
    MO: simplifiedChineseFontFiles,
    // Traditional Chinese Noto Sans file for Hong Kong, Taiwan, and Macao
    TW: traditionalChineseFontFiles,
  }

  const additionalStyleSheet = additionalFontFaces?.[country]?.style || null
  const additionalFontFiles = additionalFontFaces?.[country]?.files || null

  return (
    <>
      <link rel="stylesheet" href={`/static/fonts/lato.css`} />
      <link
        rel="preload"
        href="/static/fonts/lato/lato-v20-latin-ext_latin-regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin=""
      />

      <link
        rel="preload"
        href="/static/fonts/lato/lato-v20-latin-ext_latin-700.woff2"
        as="font"
        type="font/woff2"
        crossOrigin=""
      />

      <link
        rel="preload"
        href="/static/fonts/lato/lato-v20-latin-ext_latin-900.woff2"
        as="font"
        type="font/woff2"
        crossOrigin=""
      />

      {additionalStyleSheet && (
        <link rel="stylesheet" href={additionalStyleSheet} />
      )}

      {additionalFontFiles?.map((path, key) => (
        <link
          rel="preload"
          href={path}
          as="font"
          type="font/ttf"
          crossOrigin=""
          key={key}
        />
      ))}
    </>
  )
}

export const AppManifestLinks = () => {
  const {
    publicRuntimeConfig: { brandIdentity },
  } = getConfig()

  return (
    <>
      {brandIdentity === 'MerckMillipore' ? (
        <link rel="manifest" href="/mm-manifest.webmanifest" />
      ) : (
        <link rel="manifest" href="/manifest.webmanifest" />
      )}
      <link
        rel="apple-touch-icon"
        href={`/static/pwa/${brandIdentity}/apple-icon-180.png`}
      />
      <meta name="mobile-web-app-capable" content="yes" />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-2048-2732.png`}
        media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-2732-2048.png`}
        media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1668-2388.png`}
        media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-2388-1668.png`}
        media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1536-2048.png`}
        media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-2048-1536.png`}
        media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1668-2224.png`}
        media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-2224-1668.png`}
        media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1620-2160.png`}
        media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-2160-1620.png`}
        media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1284-2778.png`}
        media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-2778-1284.png`}
        media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1170-2532.png`}
        media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-2532-1170.png`}
        media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1125-2436.png`}
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-2436-1125.png`}
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1242-2688.png`}
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-2688-1242.png`}
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-828-1792.png`}
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1792-828.png`}
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1242-2208.png`}
        media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-2208-1242.png`}
        media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-750-1334.png`}
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1334-750.png`}
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-640-1136.png`}
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />
      <link
        rel="apple-touch-startup-image"
        href={`/static/pwa/${brandIdentity}/apple-splash-1136-640.png`}
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />
    </>
  )
}
