import React, { useEffect } from 'react'
import { AppHead } from '@src/components/AppHead'
import { Cookies, CookiesProvider } from 'react-cookie'
import { sessionStorage } from 'react-storage'
import { AppInitialProps, AppContext, NextWebVitalsMetric } from 'next/app'
import { AppCookies, defaultCookieOptions } from '@src/utils/cookies'
import { SeoTags } from '@utils/seo'
import { AnalyticsPageView, DOCUMENT_REFERRER } from '@utils/analytics'
import {
  createIntl,
  createIntlCache,
  RawIntlProvider,
  IntlConfig,
} from 'react-intl'
import getCookiesFromContext from '@src/utils/getCookiesFromContext'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import {
  getLocalizedUrl,
  needsLocalizedUrl,
  removeCountryLanguage,
} from '@utils/regional'
import { UserSession } from '@utils/userSession'
import AppVersion from '@src/components/AppVersion'
import { locationSelectRoute, wimsAuthRoutes } from '@src/routes'
import GlobalSnackbar from '@src/components/GlobalSnackbar'
import { GlobalSnackbarProvider } from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { GlobalModalProvider } from '@src/utils/useGlobalModal'
import { LoginPopoverProvider } from '@src/components/LoginPopover/loginPopoverContext'
import { theme } from '@src/styles/theme'
import { withApollo } from '@src/lib/WithApollo'
import { disableFragmentWarnings } from 'graphql-tag'
import { GUEST_TOKEN } from '@src/queries/GuestTokenQuery'
import { CURRENT_USER } from '@src/queries/CurrentUserQuery'
import dayjs from 'dayjs'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import getConfig from 'next/config'
import { isServer } from '@src/utils/isServer'
import { useChinaUser } from '@src/utils/useChinaUser'
import { fs, hasFs } from '@src/utils/fullStoryUtils'
import OptimizelyPageTracking from '@src/utils/OptimizelyPageTracking'
import SIALOptimizelyProvider from '@src/components/SIALOptimizelyProvider'
import { getOptimizelyDataFile } from '@src/utils/getOptimizelyDataFile'

interface AppProps extends AppInitialProps {
  serverOnlyCookies: { cookies: Cookies }
  referrer: string
  messages: IntlConfig['messages']
  datafile: any
}

interface ExtendedNextAppContext extends AppContext {
  ctx: ExtendedNextPageContext
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  // if it doesn't exist, create metrics cache
  if (!window['metrics']) window['metrics'] = {}
  // cache this metric
  window['metrics'][metric.name] = metric
}

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache()
disableFragmentWarnings()

// Get config vars
const {
  publicRuntimeConfig: { swFilepath },
} = getConfig()

/**
 * <App /> is a custom override for the Next.js `<App />` component so we can:
 * 1) Handle the CSS-in-JS setup for Material-UI.
 * 2) Wrap all pages in context providers and other top-level components.
 *
 * See https://nextjs.org/docs/#custom-app
 */

export const App = ({
  Component,
  pageProps,
  serverOnlyCookies,
  referrer,
  messages,
  datafile,
}) => {
  const cookies = serverOnlyCookies
    ? new Cookies(serverOnlyCookies.cookies)
    : new Cookies()
  const language = cookies.get('language') || 'en'
  const country = cookies.get('country')
  const locale = language + (country ? `-${country}` : '')
  const intl = createIntl({ locale, messages }, cache)

  // If this is a subsequent request, refresh the currentUser query and get currentUser props, else it's first page request so get empty values.
  const {
    userIsLoggedIn,
    currentUser,
    isBlueErpIntegrationEnabled,
    userErpType,
    hasOnlyBlueERP,
    isDarmstadtUser,
    userId,
  } = useCurrentUser({
    skip: !cookies.get('country'),
  })
  const isChinaUser = useChinaUser()
  const shouldSetFullStoryIdentity = !isChinaUser && userId

  // FullStory user identity should be set whenever user logs in
  useEffect(() => {
    if (document && hasFs() && userIsLoggedIn && shouldSetFullStoryIdentity) {
      // Do not include any user PII information when setting identity
      fs()('setIdentity', { uid: userId }) // userId is the same as participantId
    }
  }, [userIsLoggedIn])

  //setting referrer to use it on client side
  sessionStorage.setItem(DOCUMENT_REFERRER, referrer)
  useEffect(() => {
    if (document) {
      const jssStyles = document.querySelector('#jss-server-side')
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles)
      }
    }
  }, [])

  // Sets loggedIn cookie so Akamai knows to render cached header or not
  useEffect(() => {
    if (userIsLoggedIn) {
      cookies.set(AppCookies.LoggedIn, 'true', {
        path: '/',
        expires: dayjs(new Date()).add(2, 'week').toDate(),
        httpOnly: false,
      })
    } else {
      cookies.remove(AppCookies.LoggedIn, {
        path: '/',
        expires: dayjs(new Date()).add(2, 'week').toDate(),
        httpOnly: false,
      })
    }
  }, [userIsLoggedIn])

  useEffect(() => {
    const isMarketplaceCatalogEnabled =
      currentUser?.metadata?.isMarketplaceCatalogEnabled === true
    if (isMarketplaceCatalogEnabled) {
      cookies.set(AppCookies.IsMarketplaceCatalogEnabled, 'true', {
        path: '/',
        expires: dayjs(new Date()).add(2, 'week').toDate(),
        httpOnly: false,
      })
    } else {
      cookies.remove(AppCookies.IsMarketplaceCatalogEnabled, {
        path: '/',
        expires: dayjs(new Date()).add(2, 'week').toDate(),
        httpOnly: false,
      })
    }

    if (isBlueErpIntegrationEnabled) {
      cookies.set(AppCookies.IsBlueErpIntegrationEnabled, 'true', {
        path: '/',
        expires: dayjs(new Date()).add(1, 'year').toDate(),
        httpOnly: false,
      })
    } else {
      cookies.remove(AppCookies.IsBlueErpIntegrationEnabled, {
        path: '/',
        expires: dayjs(new Date()).add(1, 'year').toDate(),
        httpOnly: false,
      })
    }
    // userErpType
    cookies.set(AppCookies.UserErpType, userErpType, {
      path: '/',
      httpOnly: false,
    })
    // hasOnlyBlueERP
    if (hasOnlyBlueERP) {
      cookies.set(AppCookies.HasOnlyBlueERP, 'true', {
        path: '/',
        httpOnly: false,
      })
    } else {
      cookies.remove(AppCookies.HasOnlyBlueERP, {
        path: '/',
        httpOnly: false,
      })
    }
    // isDarmstadtUser
    if (isDarmstadtUser) {
      cookies.set(AppCookies.IsDarmstadtUser, 'true', {
        path: '/',
        httpOnly: false,
      })
    } else {
      cookies.remove(AppCookies.IsDarmstadtUser, {
        path: '/',
        httpOnly: false,
      })
    }
  }, [currentUser])

  useEffect(() => {
    // If service worker supported...
    if ('serviceWorker' in navigator) {
      const swUrl = `${location.origin}${swFilepath}`
      // For each sw registered...
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          // If this is not the current sw, unregister it
          if (registration.active && registration.active.scriptURL !== swUrl) {
            registration.unregister()
          }
        }
      })
      // Set current service worker
      navigator.serviceWorker.register(swFilepath)
    }
  }, [])

  return (
    <>
      <AppHead />
      <AppVersion />
      <SeoTags
        canonicalParamAllowed={(key: string) =>
          key !== 'icid' && key !== 'tfa_1' && key !== 'tfa_2'
        }
      />
      <CookiesProvider cookies={cookies}>
        <RawIntlProvider value={intl}>
          {/* Wrap every page in Styles and Theme providers. */}
          {/* ThemeProvider makes the theme available down the React
          tree thanks to React context. */}
          <SIALOptimizelyProvider datafile={datafile}>
            <ThemeProvider theme={theme}>
              <GlobalSnackbarProvider>
                <GlobalModalProvider>
                  <LoginPopoverProvider>
                    {/* CssBaseline kickstarts an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    {/* Pass pageContext to the _document though the renderPage enhancer
    to render collected styles on server side. */}
                    <Component {...pageProps} />
                    <GlobalSnackbar />
                    {/* If country set, load analytics component */}
                    {cookies.get('country') && <AnalyticsPageView />}
                    {/* Handles Optimizely data transmition push events client-side */}
                    {!isServer() && <OptimizelyPageTracking />}
                  </LoginPopoverProvider>
                </GlobalModalProvider>
              </GlobalSnackbarProvider>
            </ThemeProvider>
          </SIALOptimizelyProvider>
        </RawIntlProvider>
      </CookiesProvider>
    </>
  )
}

App.getInitialProps = async ({
  Component,
  ctx,
}: ExtendedNextAppContext): Promise<AppProps> => {
  let pageProps = {}

  // CORE-1094 Some bots/crawlers behave badly in that they try to access old versions of the app or stale cached files in some build directories.
  // Trying to access these ends up causing a series of unintended redirects that use a lot of overhead as the app tries to get a locale or location select URL on the request.
  // Which that occurs because of some not awesome behavior in our catch-all [...cmsRoute] trying to resolve all non-explictly matched page routes to AEM cms content.
  // So this matches these build/cache file directories and just quickly 404s them without running the full Next app. Reducing server overhead and request handling.
  const forceNotFoundDirs = ['/_next', '/tmp']
  const reqUrl = ctx.req?.url

  if (ctx.res && forceNotFoundDirs.some((dir) => reqUrl.includes(dir))) {
    ctx.res.statusCode = 404
    ctx.res?.end('Not Found')
    return {} as AppProps
  }

  // Props passed to the client from server during gIP are serialized, so
  // when this object gets called with `toJson` it returns undefined

  const serverOnlyCookies = {
    cookies: new Cookies(),
    toJson() {
      return undefined
    },
  }

  // Build list of paths that can skip locale redirection
  const skippableURLPaths = [wimsAuthRoutes.index()]

  const { req, res } = ctx
  const { messages } = req || window.__NEXT_DATA__.props

  //getting the referrer from request header since document.referrer is giving empty in IE11
  const referrer = req?.headers['referer'] || ''

  // Set boolean if this request matches skippable path
  const skipLocationSelect = skippableURLPaths.some((path) =>
    req?.url?.includes(path)
  )

  // If SSR, and this request should not skip locale redirection...
  if (isServer() && req && req.url && res && !skipLocationSelect) {
    // Calculate and set SSR cookies
    const cookiesForOMS = getCookiesFromContext(ctx)
    const omsGuestCountry =
      (ctx.query.omsGuestToken && ctx.query.country) || null
    const omsGuestLanguage =
      (ctx.query.omsGuestToken && ctx.query.country && ctx.query.language) ||
      null
    const store =
      (ctx.query.omsGuestToken && ctx.query.country && ctx.query.store) || null
    if (omsGuestCountry && omsGuestLanguage && store) {
      cookiesForOMS.set(AppCookies.Language, omsGuestLanguage, {
        path: '/',
        expires: dayjs(new Date()).add(1, 'year').toDate(),
        ...defaultCookieOptions,
        httpOnly: false,
      })
      cookiesForOMS.set(AppCookies.Country, omsGuestCountry, {
        path: '/',
        expires: dayjs(new Date()).add(1, 'year').toDate(),
        ...defaultCookieOptions,
        httpOnly: false,
      })
    }
    // Get client-side cookies
    const cookies = getCookiesFromContext(ctx)
    serverOnlyCookies.cookies = cookies.getAll()
    const userCountry = cookies.get(AppCookies.Country)
    const userLanguage = cookies.get(AppCookies.Language)
    const accessToken = cookies.get(AppCookies.AccessToken)

    let validUser = false

    // If the user already has a token, try to load their account info and set validUser flag
    if (accessToken) {
      try {
        await ctx.apolloClient.query({
          query: CURRENT_USER,
        })
        validUser = true
      } catch (err) {
        validUser = false
      }
    }

    if (userCountry && !validUser)
      try {
        const result = await ctx.apolloClient.query({
          query: GUEST_TOKEN,
          variables: { country: userCountry },
          fetchPolicy: 'network-only',
        })
        if (result?.data?.getGuestToken) {
          cookies.set(AppCookies.AccessToken, result.data.getGuestToken, {
            ...defaultCookieOptions,
            path: '/',
            expires: dayjs(new Date()).add(2, 'week').toDate(),
            httpOnly: false,
          })
        }
      } catch (e) {}

    // Set boolean if path is `/location-select`
    const isLocationSelectRoute = req.url.startsWith(
      locationSelectRoute.index()
    )

    // If no country and path is not `/location-select`, redirect the visitor to location-select
    if (!userCountry && !isLocationSelectRoute) {
      const Location = locationSelectRoute.index(removeCountryLanguage(req.url))
      res.writeHead(302, { Location })
      res.end()
      return {} as AppProps
    }

    // If the path is not `/location-select`, check if url matches locale cookie and redirect if needed
    if (!isLocationSelectRoute) {
      const userSession = {
        country: userCountry,
        language: userLanguage,
      } as UserSession
      if (needsLocalizedUrl(userSession, req.url)) {
        const { as: Location } = getLocalizedUrl(userSession, req.url)
        res.writeHead(302, { Location })
        res.end()
        return {} as AppProps
      }
    }
  }

  const datafile = await getOptimizelyDataFile(ctx)

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  return {
    pageProps,
    serverOnlyCookies,
    referrer,
    messages,
    datafile,
  }
}

// @ts-ignore
export default withApollo({ ssr: true })(App)
