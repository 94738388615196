import { sessionStorage } from 'react-storage'
import {
  PromoObject,
  GA4EcommercePayloadItem,
  EventValues,
} from '@sial/common-utils'

import { searchResultsRoute } from '@src/routes'
import {
  ProductSearchType,
  determineQueryFocus,
  getValidSearch,
  formatCasNumberSearchTerm,
} from '@utils/searchUtils'

import { ProductAliasPayload } from './types'

export const buildProductAliasPayload = ({
  action,
  detail,
  focus,
  productId,
  productBrand,
}: {
  action: string
  detail?: string | undefined | null
  focus: string
  productId?: string
  productBrand?: string
}): ProductAliasPayload => {
  let type
  let term
  switch (action) {
    case 'ec number':
      type = ProductSearchType.EgecNumber
      break
    case 'mdl number':
      type = ProductSearchType.MdlNumber
      break
    case 'cas number':
      type = ProductSearchType.CasNumber
      term = formatCasNumberSearchTerm(detail)
      break
    default:
  }
  return {
    action: action + ' click',
    detail: detail,
    component: 'body',
    elementType: 'link',
    elementText: detail,
    linkUrl: `${searchResultsRoute.searchResults(
      term || detail || undefined
    )}${getValidSearch({
      term: term?.toLowerCase() || detail?.toLowerCase(),
      focus: determineQueryFocus(focus),
      type,
    })}`,
    productId,
    productBrand,
  }
}

/*
  BUILDS PROMO OBJECTS FOR CAROUSELS

  Could be used elsewhere for product data structures that use the gaProductCode alongside product data.
  Carousels is the original primary purpose.

  Can build both GA4 promo objects and legacy UA promo objects.
*/
export const buildGaPromoObjects = (
  rawGaProductCode,
  pickedProduct,
  position = 1,
  listNameOverride: string | null = null
): {
  promo: PromoObject
  ga4PromoObj: GA4EcommercePayloadItem
} => {
  const gaProductCode = rawGaProductCode?.toLowerCase()?.split('|') || []

  const pageParent: any = sessionStorage.getItem(
    'DYNAMIC_CAROUSEL_PRODUCTS_PAGE_PARENT'
  )

  const legacyPromo: PromoObject = {
    id: gaProductCode?.[0] || EventValues.NotAvailable,
    name: gaProductCode?.[1] || EventValues.NotAvailable,
    creative: gaProductCode?.[2] || EventValues.NotAvailable,
  }

  const ga4PromoObj: GA4EcommercePayloadItem = {
    creative_name: gaProductCode[2] || EventValues.NotAvailable,
    promotion_id: gaProductCode[0] || EventValues.NotAvailable,
    promotion_name: gaProductCode[1] || EventValues.NotAvailable,
    index: position,
    item_id:
      pickedProduct?.productKey?.toLowerCase() ||
      pickedProduct?.productNumber?.toLowerCase() ||
      EventValues.NotAvailable,
    item_brand:
      pickedProduct?.brand?.key?.toLowerCase() || EventValues.NotAvailable,
    item_list_id: listNameOverride || pageParent?.detail,
    item_list_name: listNameOverride || pageParent?.detail,
  }

  return { promo: legacyPromo, ga4PromoObj }
}

export const calculateDiscountPercentage = (
  listPrice,
  discountedPrice
): number | undefined => {
  return (listPrice ?? 0) > (discountedPrice ?? 0)
    ? Math.round(
        (((listPrice ?? 0) - (discountedPrice ?? 0)) / (listPrice ?? 1)) * 100
      )
    : undefined
}
