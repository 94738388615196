import React from 'react'
import { ReactCookieProps } from 'react-cookie'
import storeValues from '@utils/storeValues'

export interface UserSession {
  country: string
  language: string
  store: string
  accessToken: string
  guid?: string
}

export const userSession = (
  cookies: ReactCookieProps['allCookies']
): UserSession => {
  return {
    country: (cookies && cookies.country) || '',
    accessToken: cookies && cookies.accessToken,
    guid: cookies && cookies.guid,
    language: (cookies && cookies.language) || '',
    // Logged-out users default to 'sial', users who use the B2bLogin will be 'b2b'
    store: (cookies && cookies.store) || storeValues.sial,
  }
}

export const MobileContext = React.createContext<boolean>(false)
