import React from 'react'
import clsx from 'clsx'
import { SnackbarType, useGlobalSnackbar } from './globalSnackBarContext'
import { IconButton, Snackbar, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Alert from '@material-ui/lab/Alert'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    backgroundColor: theme.palette.common.white,
    opacity: 0.9,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000, // https://material-ui.com/customization/z-index/
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      right: 0,
      left: 0,
      bottom: 0,
    },
  },
  alertRoot: {
    top: 76.8 + 24,
    '& $successAlert, & $infoAlert, & $errorAlert': {
      '& .MuiAlert-message': {
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
      },
      '& .MuiAlert-icon': {
        color: theme.palette.common.white,
      },
      '& .MuiAlert-action': {
        color: theme.palette.common.white,
      },
    },
    '& $warningAlert': {
      display: 'flex',
      alignItems: 'center',
      '& .MuiAlert-message': {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold,
      },
      '& .MuiAlert-icon': {
        color: '#f7a703',
      },
      '& .MuiAlert-action': {
        color: theme.palette.primary.main,
      },
      '& a': {
        color: theme.palette.primary.main,
      },
    },
  },
  newToast: {
    top: 145,
    right: 88,
    '& $successAlert': {
      '& .MuiAlert-message': {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(16),
        backgroundColor: theme.palette.success.main,
      },
      backgroundColor: theme.palette.success.main,
      width: 304,
    },
  },
  newToastMob: {
    bottom: 16,
    top: 'unset',
    '& $successAlert': {
      '& .MuiAlert-message': {
        fontWeight: theme.typography.fontWeightRegular,
        backgroundColor: theme.palette.success.main,
      },
      backgroundColor: theme.palette.success.main,
      width: '95%',
    },
  },
  successIcon: {
    fontSize: theme.typography.pxToRem(24),
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      padding: theme.spacing(4, 6),
    },
  },
  snackbarSuccess: { backgroundColor: theme.palette.primary.main },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
    '& a': {
      color: theme.palette.common.white,
      textDecoration: 'underline',
    },
  },
  successAlert: {
    backgroundColor: theme.palette.secondary.main,
  },
  infoAlert: {},
  warningAlert: {
    backgroundColor: '#fff',
    border: 'solid 1px #f7a703',
    color: '#202020',
    borderWidth: '1px 1px 1px 5px',
  },
  errorAlert: {
    backgroundColor: theme.palette.error.main,
    '& a': {
      color: theme.palette.common.white,
      textDecoration: 'underline',
    },
  },
}))

const GlobalSnackbar = () => {
  const classes = useStyles()

  const { globalSnackbarState, setGlobalSnackbarState } = useGlobalSnackbar()
  const theme = useTheme()
  // If alertSeverity exists after this block, then snackbar contains an alert
  let alertSeverity
  switch (globalSnackbarState.variant) {
    case SnackbarType.SuccessAlert:
      alertSeverity = 'success'
      break
    case SnackbarType.InfoAlert:
      alertSeverity = 'info'
      break
    case SnackbarType.WarningAlert:
      alertSeverity = 'warning'
      break
    case SnackbarType.ErrorAlert:
      alertSeverity = 'error'
      break
    default:
      alertSeverity = undefined
  }

  const { newToastFlag } = globalSnackbarState
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const handleClose = () => {
    setGlobalSnackbarState({ ...globalSnackbarState, open: false })
  }

  return (
    <>
      {globalSnackbarState.disableWindowBlurListener &&
        globalSnackbarState.open && <div className={classes.backdrop} />}
      <Snackbar
        anchorOrigin={
          newToastFlag && !isMobile
            ? {
                vertical: 'top',
                horizontal: 'right',
              }
            : newToastFlag && isMobile
              ? {
                  vertical: 'bottom',
                  horizontal: 'center',
                }
              : alertSeverity
                ? {
                    vertical: 'top',
                    horizontal: 'center',
                  }
                : {
                    vertical: 'bottom',
                    horizontal: 'left',
                  }
        }
        open={globalSnackbarState.open}
        autoHideDuration={newToastFlag ? 5000 : alertSeverity ? null : 3000}
        onClose={handleClose}
        classes={{
          root: alertSeverity ? classes.alertRoot : classes.root,
          anchorOriginTopRight: newToastFlag ? classes.newToast : '',
          anchorOriginBottomCenter:
            isMobile && newToastFlag ? classes.newToastMob : '',
        }}
        data-testid="global-snackbar"
        id="global-snackbar"
      >
        {alertSeverity ? (
          <Alert
            classes={{
              standardSuccess: classes.successAlert,
              standardInfo: classes.infoAlert,
              standardWarning: classes.warningAlert,
              standardError: classes.errorAlert,
            }}
            icon={
              newToastFlag && (
                <CheckCircleIcon className={classes.successIcon} />
              )
            }
            severity={alertSeverity}
            action={
              !newToastFlag && (
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              )
            }
          >
            {globalSnackbarState.message}
          </Alert>
        ) : (
          <SnackbarContent
            className={clsx(
              classes.content,
              classes[globalSnackbarState.variant || '']
            )}
            message={<span>{globalSnackbarState.message}</span>}
          />
        )}
      </Snackbar>
    </>
  )
}

export default GlobalSnackbar
