import { useCallback, useMemo } from 'react'
import * as countriesMap from '@localization/countries.json'
import { CountryDataResponse } from '@src/routes/Cart/utils'

export interface CountryDropdownValue {
  label: string
  value: string
}

export const useCountriesData = (): {
  getCountries
  getCountryDetails: (country: string) => CountryDataResponse
  getCountryProp: (country: string, prop: string) => any
} => {
  const getCountries = useMemo(() => {
    const countriesDropDown: CountryDropdownValue[] = []
    for (const countryCode in countriesMap) {
      if (countriesMap[countryCode]?.displayName) {
        countriesDropDown.push({
          label: countriesMap[countryCode]?.displayName || '',
          value: countryCode,
        })
      }
    }
    return countriesDropDown
  }, [countriesMap])

  const getCountryDetails = useCallback(
    (country) => {
      return countriesMap[country]
    },
    [countriesMap]
  )

  const getCountryProp = useCallback(
    (country, prop) => {
      return countriesMap?.[country]?.[prop]
    },
    [countriesMap]
  )

  return useMemo(
    () => ({
      getCountries,
      getCountryDetails,
      getCountryProp,
    }),
    [getCountries, getCountryDetails, getCountryProp]
  )
}
