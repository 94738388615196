/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY DIRECTLY
 */


      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "Certificate",
        "possibleTypes": [
          {
            "name": "CertificateText"
          },
          {
            "name": "CertificateUrl"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "SearchResults",
        "possibleTypes": [
          {
            "name": "ContentDocumentSearchResponse"
          },
          {
            "name": "GeneSearchResults"
          },
          {
            "name": "PaperSearchResponse"
          },
          {
            "name": "ProductSearchResults"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SearchResultsItems",
        "possibleTypes": [
          {
            "name": "Gene"
          },
          {
            "name": "Paper"
          },
          {
            "name": "Product"
          },
          {
            "name": "Substance"
          },
          {
            "name": "TechnicalDocument"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "User",
        "possibleTypes": [
          {
            "name": "GuestUser"
          },
          {
            "name": "LoggedInUser"
          }
        ]
      }
    ]
  }
};
      export default result;
    