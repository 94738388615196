import { useMemo } from 'react'
import { useCookies, AppCookies } from '@src/utils/cookies'
import { useApolloClient } from 'react-apollo'
import dayjs from 'dayjs'
import { userSession, UserSession } from './userSession'
import { useLogoutMutation } from '@src/mutations/LogoutMutation.generated'
import { useClearSessionMutation } from '@src/mutations/ClearSessionMutation.generated'
import { debounce } from 'lodash'
import { NextRouter } from 'next/router'
import { isServer } from './isServer'
export interface UserSessionActions {
  login: (
    accessToken: string,
    store: string,
    reset: boolean
  ) => Promise<UserSession>
  logout: () => Promise<UserSession>
  setLanguage: (lang: string, reload?: boolean) => UserSession
  setCountry: (country: string, reload?: boolean) => UserSession
  b2bLogout: () => Promise<boolean>
}

const authCookieOptions = {
  path: '/',
  expires: dayjs(new Date()).add(2, 'week').toDate(),
  httpOnly: false,
}
const storeCookieOptions = {
  path: '/',
  expires: dayjs(new Date()).add(2, 'week').toDate(),
  httpOnly: false,
}

export const useUserSession = () => {
  const [universalCookies, setCookie, removeCookie] = useCookies([
    AppCookies.Country,
    AppCookies.Language,
    AppCookies.AccessToken,
    AppCookies.Store,
  ])
  let cookies
  if (isServer() && universalCookies.getAll) {
    cookies = universalCookies.getAll()
  } else {
    cookies = universalCookies
  }

  const [removeAccessToken] = useLogoutMutation()
  const [fetchToken] = useClearSessionMutation()
  const apolloClient = useApolloClient()

  const actions = {
    login: async (
      accessToken: string,
      store: string,
      reset = true
    ): Promise<UserSession> => {
      setCookie(AppCookies.AccessToken, accessToken, authCookieOptions)
      setCookie(AppCookies.Store, store, storeCookieOptions)
      try {
        reset
          ? await apolloClient.resetStore()
          : await apolloClient.clearStore()
      } catch (_ignored) {}
      return userSession({ ...cookies, accessToken: accessToken, store: store })
    },
    logout: async (router?: NextRouter): Promise<UserSession> => {
      try {
        const logoutResult = await removeAccessToken()
        const newAccessToken = logoutResult?.data?.logout?.accessToken
        if (newAccessToken) {
          setCookie(AppCookies.AccessToken, newAccessToken, authCookieOptions)
          removeCookie(AppCookies.Store, storeCookieOptions)

          if (
            router &&
            (router.pathname.includes('/cart') ||
              router.pathname.includes('/order-center'))
          ) {
            window.location.href = '/quick-order'
          } else {
            router ? (window.location.href = '/') : window.location.reload()
          }
          return userSession({ ...cookies, accessToken: newAccessToken })
        }
      } catch (_ignored) {}

      // If no valid access token comes back from logout, redirect to country select

      return userSession(cookies)
    },
    clearSession: debounce(
      async (): Promise<UserSession> => {
        try {
          const clearSessionResult = await fetchToken()
          const newAccessToken =
            clearSessionResult?.data?.clearSession?.accessToken
          if (newAccessToken) {
            setCookie(AppCookies.AccessToken, newAccessToken, authCookieOptions)
            removeCookie(AppCookies.Store, storeCookieOptions)
            apolloClient.resetStore()
            return userSession({ ...cookies, accessToken: newAccessToken })
          }
        } catch (_ignored) {}

        // If no valid access token comes back from logout, redirect to country select
        return userSession(cookies)
      },
      1000,
      { leading: true, trailing: false }
    ),
    setLanguage(newLanguage: string): UserSession {
      setCookie(AppCookies.Language, newLanguage, {
        path: '/',
        expires: dayjs(new Date()).add(1, 'year').toDate(),
        httpOnly: false,
      })
      return userSession({ ...cookies, language: newLanguage })
    },
    setCountry(newCountry: string): UserSession {
      setCookie(AppCookies.Country, newCountry, {
        path: '/',
        expires: dayjs(new Date()).add(1, 'year').toDate(),
        httpOnly: false,
      })
      return userSession({ ...cookies, country: newCountry })
    },
    b2bLogout: async (): Promise<boolean> => {
      try {
        removeCookie(AppCookies.Store, storeCookieOptions)
      } catch (_ignored) {}
      return true
    },
  }

  const session = userSession(cookies)
  return useMemo(
    () => ({
      userSession: session,
      userSessionActions: actions,
    }),
    [session, actions]
  )
}
