// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AddUserActivityMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.UserActivityInput>;
}>;


export type AddUserActivityMutation = { __typename?: 'Mutation', addUserActivity?: { __typename?: 'UserActivityEntry', trackingData?: Record<string, unknown> | null, featureType: string } | null };


export const AddUserActivityDocument = gql`
    mutation addUserActivity($input: UserActivityInput) {
  addUserActivity(input: $input) {
    trackingData
    featureType
  }
}
    `;
export type AddUserActivityMutationFn = ApolloReactCommon.MutationFunction<AddUserActivityMutation, AddUserActivityMutationVariables>;
export type AddUserActivityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddUserActivityMutation, AddUserActivityMutationVariables>, 'mutation'>;

    export const AddUserActivityComponent = (props: AddUserActivityComponentProps) => (
      <ApolloReactComponents.Mutation<AddUserActivityMutation, AddUserActivityMutationVariables> mutation={AddUserActivityDocument} {...props} />
    );
    

/**
 * __useAddUserActivityMutation__
 *
 * To run a mutation, you first call `useAddUserActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserActivityMutation, { data, loading, error }] = useAddUserActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserActivityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserActivityMutation, AddUserActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddUserActivityMutation, AddUserActivityMutationVariables>(AddUserActivityDocument, options);
      }
export type AddUserActivityMutationHookResult = ReturnType<typeof useAddUserActivityMutation>;
export type AddUserActivityMutationResult = ApolloReactCommon.MutationResult<AddUserActivityMutation>;
export type AddUserActivityMutationOptions = ApolloReactCommon.BaseMutationOptions<AddUserActivityMutation, AddUserActivityMutationVariables>;