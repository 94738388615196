import { FormattedMessage } from 'react-intl'
import { sessionStorage } from 'react-storage'
import { Button, ButtonGroup, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LinkStyleButton from '@src/components/LinkStyleButton'
import WalkMeStep from '@src/components/WalkMeStep'
import CaretRightIcon from '@src/icons/CaretRightIcon'
import CaretLeftIcon from '@src/icons/CaretLeftIcon'
import { Step } from '@src/components/WalkMeStep/StepBody'
import { WalkMeStepProps } from '@src/components/WalkMeStep/WalkMeStep'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import { useUserActivity } from '@src/utils/useUserActivity'
import {
  InvoicesWalkMeConstants,
  getInvoicesStorageOverride,
  useInvoicesWalkMe,
} from '@src/routes/OrderCenter/Invoices/InvoicesWalkMe/'

const useStyles = makeStyles((theme) => ({
  step: {
    marginTop: theme.spacing(-1),
  },
  buttonGroup: {
    alignItems: 'center',
  },
  buttonRoot: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    minWidth: theme.spacing(4),
    padding: 0,
  },
  buttonOutlined: {
    border: 0,
  },
  buttonDisabled: {},
  buttonIcon: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  buttonIconDisabled: {
    color: theme.palette.grey[500],
  },
  buttonNumbers: {
    textAlign: 'center',
  },
  footer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
  },
}))

const steps: Step[] = [
  {
    title: {
      id: 'WALK_ME_PAY_INVOICES_ONLINE',
      defaultMessage: 'Pay Your Invoices Online',
    },
    description: {
      id: 'WALK_ME_PAY_INVOICES_DETAILS',
      defaultMessage:
        'Conveniently pay your invoices by selecting Pay Invoices from the menu.',
    },
  },
  {
    title: {
      id: 'WALK_ME_SELECT_INVOICES',
      defaultMessage: 'Select Invoices',
    },
    description: {
      id: 'WALK_ME_REVIEW_INVOICE_DETAILS',
      defaultMessage:
        'Review invoice details and select the invoices you’d like to pay.',
    },
  },
  {
    title: {
      id: 'WALK_ME_VIEW_PAY_INVOICES',
      defaultMessage: 'View/Pay Selected Invoices',
    },
    description: {
      id: 'WALK_ME_VIEW_PAY_INVOICES_DETAILS',
      defaultMessage:
        'Once your invoices are selected, click this button to proceed to the payment screen.',
    },
  },
]

type InvoicesWalkMeStepProps = Pick<
  WalkMeStepProps,
  'anchorEl' | 'placement'
> & {
  stepNumber: number
}

export const InvoicesWalkMeStep = ({
  anchorEl,
  placement,
  stepNumber,
}: InvoicesWalkMeStepProps) => {
  const classes = useStyles()
  const { state, dismissStep, advanceActiveStepNumber, forceActiveStepNumber } =
    useInvoicesWalkMe()
  const { userIsLoggedIn } = useCurrentUser()
  const { updateUserActivity } = useUserActivity(
    InvoicesWalkMeConstants.TRACKING_DATA_KEY,
    getInvoicesStorageOverride(userIsLoggedIn)
  )

  const step = steps[stepNumber - 1]

  const showWalkMeStep =
    state.enabled &&
    !state.stepDismissed &&
    state.activeStepNumber === stepNumber

  const onClickAway = () => {
    dismissStep()
    if (state.activeStepNumber === steps.length) {
      sessionStorage.setItem(InvoicesWalkMeConstants.DISMISSED_KEY, true)
      updateUserActivity({ hasCompletedWalkMe: true })
    }
  }

  return showWalkMeStep ? (
    <WalkMeStep
      id={`invoices-step${stepNumber}`}
      className={classes.step}
      anchorEl={anchorEl}
      placement={placement}
      step={step}
      onClose={onClickAway}
      onClickAway={onClickAway}
      footer={
        <div className={classes.footer}>
          {stepNumber === 1 && (
            <LinkStyleButton data-testid="dismiss-tips" onClick={onClickAway}>
              <FormattedMessage id="DISMISS_WALK_ME" defaultMessage="Dismiss" />
            </LinkStyleButton>
          )}

          {(stepNumber === 2 || stepNumber === 3) && (
            <ButtonGroup className={classes.buttonGroup}>
              <Button
                classes={{
                  root: classes.buttonRoot,
                  outlined: classes.buttonOutlined,
                  disabled: classes.buttonDisabled,
                }}
                onClick={() => {
                  forceActiveStepNumber(2)
                }}
              >
                <CaretLeftIcon className={classes.buttonIcon} />
              </Button>
              <Typography variant="body2" className={classes.buttonNumbers}>
                {stepNumber === 2 ? '1/2' : '2/2'}
              </Typography>
              <Button
                classes={{
                  root: classes.buttonRoot,
                  outlined: classes.buttonOutlined,
                  disabled: classes.buttonDisabled,
                }}
                onClick={() => {
                  advanceActiveStepNumber(3)
                }}
              >
                <CaretRightIcon className={classes.buttonIcon} />
              </Button>
            </ButtonGroup>
          )}

          {stepNumber === 2 && (
            <LinkStyleButton
              data-testid="next-tip"
              onClick={() => {
                advanceActiveStepNumber(3)
              }}
            >
              <FormattedMessage id="WALK_ME_NEXT_STEP" defaultMessage="Next" />
            </LinkStyleButton>
          )}

          {stepNumber === 3 && (
            <LinkStyleButton data-testid="walk-me-done" onClick={onClickAway}>
              <FormattedMessage id="WALK_ME_DONE" defaultMessage="Done" />
            </LinkStyleButton>
          )}
        </div>
      }
    />
  ) : null
}
