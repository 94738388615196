import Icon, { BaseIconProps } from './Icon'
function CaretLeftIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 20" {...props}>
        <path d="M8.8 20a2.16 2.16 0 0 1-1.67-.78l-6.6-7.77a2.24 2.24 0 0 1 0-2.9L7.13.77a2.19 2.19 0 0 1 3.1-.24 2.25 2.25 0 0 1 .24 3.14L5.1 10l5.37 6.33a2.24 2.24 0 0 1-.24 3.13A2.16 2.16 0 0 1 8.8 20" />
      </svg>
    </Icon>
  )
}
export default CaretLeftIcon
