import { Context, createContext, useCallback, useState } from 'react'
import { noop } from 'lodash'

type WalkMeState<TExtraState> = TExtraState & {
  activeStepNumber: number
  enabled: boolean
  stepDismissed: boolean
}

const initialWalkMeState: WalkMeState<{}> = {
  activeStepNumber: 1,
  enabled: false,
  stepDismissed: false,
}

interface WalkMeContextValue<TExtraState> {
  state: WalkMeState<TExtraState>
  startTour: (delay?: number, newStepNumber?: number) => void
  advanceActiveStepNumber: (step: number) => void
  forceActiveStepNumber: (step: number) => void
  dismissStep: () => void
  setEnabled: (enabled: boolean) => void
  setExtraState: (extraState: TExtraState) => void
}

export const createWalkMeContext = <T>(
  extraState: T
): readonly [Context<WalkMeContextValue<T>>, () => WalkMeContextValue<T>] => {
  const initialState = { ...initialWalkMeState, ...extraState }
  const ctx = createContext<WalkMeContextValue<T>>({
    state: initialState,
    startTour: noop,
    advanceActiveStepNumber: noop,
    forceActiveStepNumber: noop,
    dismissStep: noop,
    setEnabled: noop,
    setExtraState: noop,
  })
  const useWalkMe = (): WalkMeContextValue<T> => {
    const [state, setState] = useState(initialState)
    const value: WalkMeContextValue<T> = {
      state,
      startTour: useCallback(
        (delay, newStepNumber) =>
          setTimeout(() => {
            setState((currentState) => ({
              ...currentState,
              activeStepNumber: newStepNumber || 1,
              enabled: true,
              stepDismissed: false,
            }))
          }, delay ?? 0),
        []
      ),
      advanceActiveStepNumber: useCallback((newStepNumber: number) => {
        setState((currentState) => {
          if (newStepNumber > currentState.activeStepNumber)
            return {
              ...currentState,
              activeStepNumber: newStepNumber,
              stepDismissed: false,
            }
          return currentState
        })
      }, []),
      forceActiveStepNumber: useCallback((activeStepNumber: number) => {
        setState((s) => {
          return activeStepNumber === s.activeStepNumber
            ? s
            : { ...s, activeStepNumber, stepDismissed: false }
        })
      }, []),
      dismissStep: useCallback(() => {
        setState((s) => {
          return s.stepDismissed ? s : { ...s, stepDismissed: true }
        })
      }, []),
      setEnabled: useCallback((enabled: boolean) => {
        setState((s) => {
          return enabled === s.enabled ? s : { ...s, enabled }
        })
      }, []),
      setExtraState: useCallback((extraState) => {
        setState((s) => ({ ...s, ...extraState }))
      }, []),
    }
    return value
  }

  return [ctx, useWalkMe] as const
}
