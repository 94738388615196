import { Typography, Button, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import ResponsiveModal, {
  ResponsiveModalBody,
  ModalSizes,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import {
  InvoicesWalkMeConstants,
  useInvoicesWalkMe,
} from '@src/routes/OrderCenter/Invoices/InvoicesWalkMe'

interface InvoicesWalkMeAlertProps {
  dismissAlert: () => void
  closeAlert: () => void
  clickNext: (step: number) => void
}

export const useStyles = makeStyles((theme: Theme) => ({
  modalBody: {
    paddingTop: '0',
  },
  banner: {
    width: '100%',

    '& img': {
      width: '100%',
      height: 'auto',
      maxWidth: '100%',
    },
  },
  modalDescription: {
    marginTop: theme.spacing(4),
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(6),
  },
  dismissButton: {
    padding: '10px 24px !important',
    marginRight: theme.spacing(4),
  },
}))

export const InvoicesWalkMeAlert = ({
  dismissAlert,
  clickNext,
  closeAlert,
}: InvoicesWalkMeAlertProps) => {
  const classes = useStyles()
  const { startTour } = useInvoicesWalkMe()

  return (
    <ResponsiveModal
      id={InvoicesWalkMeConstants.BANNER_ID as string}
      open
      size={ModalSizes.Medium}
      onClose={() => dismissAlert()}
    >
      <>
        <div className={classes.banner}>
          <img
            alt="Invoices Banner"
            src="/deepweb/assets/sigmaaldrich/marketing/global/images/banners/pay-later/171107-112848-us-burlington-corporateteam-14160-final/171107-112848-us-burlington-corporateteam-14160-final.jpg"
          />
        </div>
        <ResponsiveModalBody>
          <Typography component="h3" variant="h3">
            <FormattedMessage
              id="WALK_ME_INVOICES_HEADING"
              defaultMessage="New Feature - Pay Invoices"
            />
          </Typography>
          <Typography
            component="p"
            className={classes.modalDescription}
            variant="body1"
          >
            <FormattedMessage
              id="WALK_ME_INVOICES_DESCRIPTION"
              defaultMessage="We’ve made it easier to pay your invoices."
            />
          </Typography>
          <div className={classes.modalActions}>
            <div>
              <Button
                color="primary"
                data-testid="dismiss-walkme"
                id="invoices-dismiss"
                size="large"
                variant="outlined"
                className={classes.dismissButton}
                onClick={() => dismissAlert()}
              >
                <FormattedMessage
                  defaultMessage="Dismiss"
                  id="WALK_ME_INVOICES_DISMISS"
                />
              </Button>
            </div>
            <div>
              <Button
                color="primary"
                data-testid="explore-invoices"
                id="invoices-explore"
                size="large"
                variant="contained"
                onClick={() => {
                  closeAlert()
                  clickNext(1)
                  setTimeout(startTour, 0)
                }}
              >
                <FormattedMessage
                  defaultMessage="Explore"
                  id="WALK_ME_INVOICES_EXPLORE"
                />
              </Button>
            </div>
          </div>
        </ResponsiveModalBody>
      </>
    </ResponsiveModal>
  )
}
