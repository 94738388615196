import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, ButtonBaseProps, Theme } from '@material-ui/core'

export enum LinkStyleButtonSizes {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  Inherit = 'Inherit',
}
interface LinkStyleButtonProps extends ButtonBaseProps {
  size?: LinkStyleButtonSizes
  startIcon?: React.ReactNode
  typeButtonText?: React.ReactNode
  // passing in a href will trigger mui to render the button as an anchor tag: https://mui.com/material-ui/api/button/
  href?: string
}

export const useStyles = makeStyles((theme: Theme) => {
  return {
    linkStyleButton: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      textAlign: 'left',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '&:disabled': {
        color: theme.palette.grey[500],
      },
      // custom focus style for readability
      '&:focus-visible': {
        outline: 'revert',
        outlineOffset: '2px',
        boxShadow: 'none',
      },
    },
    linkStyleButtonSmall: {
      fontSize: theme.typography.pxToRem(12),
    },
    linkStyleButtonLarge: {
      fontSize: theme.typography.pxToRem(16),
    },
    linkStyleButtonInherit: {
      fontSize: 'inherit',
    },
    startIconContainer: {
      fontSize: 0,
      marginRight: theme.spacing(2),
    },
    btnWrap: {
      color: theme.palette.primary.main,
      marginTop: 'auto',
      width: '100%',
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(16),
      padding: '5px 15px',
      transitions: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1)',
      border: `1px solid rgba(15, 105, 175, 0.5)`,
      borderRadius: '5px',
      lineHeight: '1.75',
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
  }
})

const LinkStyleButton: React.FC<LinkStyleButtonProps> = React.forwardRef(
  function LinkStyleButton(
    {
      className,
      size = LinkStyleButtonSizes.Medium,
      startIcon,
      children,
      typeButtonText,
      ...rest
    },
    ref
  ) {
    const classes = useStyles()
    return (
      <ButtonBase
        ref={ref}
        className={clsx(
          classes.linkStyleButton,
          classes[`linkStyleButton${size}`],
          typeButtonText && classes.btnWrap,
          className
        )}
        {...rest}
      >
        {startIcon && (
          <span className={classes.startIconContainer} aria-hidden>
            {startIcon}
          </span>
        )}
        {children}
      </ButtonBase>
    )
  }
)

export default LinkStyleButton
