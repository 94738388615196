import { useMemo } from 'react'
import { values } from 'lodash'
import { format } from 'date-fns-next'
import qs from 'qs'
import { NextRouter } from 'next/router'
import { searchResultsRoute } from '@src/routes'
import {
  Sort,
  FacetInput,
  StructureSearchType,
  StructureSearchFormat,
  StructureSearchFilters,
  CatalogType,
  DidYouMeanTerm,
  ProductSearchGroup,
  ProductAttribute,
} from '@src/types/graphql-types'
import { PartnerAccountDetailFragment } from '@src/fragments/PartnerAccountDetail.generated'
import messages, { Message } from '@utils/messages'
import { useIntl } from 'react-intl'
import { useBuildingBlocksCountry } from './regional'
import { getStateNameFromSial } from '@utils/getStateName'
import getConfig from 'next/config'

export const DEFAULT_PER_PAGE = 30

export const EMPROVE_SCROLL_LINK_ID = 'emprove-dossiers'

export const MAX_VISIBLE_ATTRIBUTES = 4

export enum SearchType {
  Products = 'products',
  Genes = 'genes',
  Papers = 'papers',
  TechnicalDocuments = 'documents',
  SiteContent = 'sitecontent',
  BuildingBlocks = 'buildingblocks',
  StructureSearch = 'structuresearch',
  B2B = 'b2b',
  Chromatograms = 'chromatograms',
}

export enum SearchFocusType {
  Products = 'products',
  Genes = 'genes',
  Papers = 'papers',
  TechnicalDocuments = 'documents',
  SiteContent = 'sitecontent',
  BuildingBlocks = 'buildingblocks',
  StructureSearch = 'structuresearch',
  B2B = 'b2b',
  Chromatograms = 'chromatograms',
}

export enum ReadableSearchTypes {
  products = 'products',
  genes = 'genes',
  papers = 'papers',
  documents = 'documents',
  sitecontent = 'site content',
  buildingblocks = 'building blocks',
  structuresearch = 'structure search',
  b2b = 'b2b',
  chromatograms = 'chromatograms',
}

export enum ShippingTypes {
  SameDay = 'Ships Today',
  FreeFreight = 'free_ship',
}

export enum ProductSearchType {
  Product = 'product',
  ProductExact = 'product_exact',
  ProductNumber = 'product_number',
  ProductName = 'product_name',
  CasNumber = 'cas_number',
  MolecularFormula = 'mol_form',
  SubstanceId = 'substance_id',
  FemaNumber = 'fema_number',
  ColorIndex = 'color_index',
  EgecNumber = 'egec_number',
  EcNumber = 'ec_number',
  GeneProduct = 'gene_product',
  MdlNumber = 'mdl_number',
  PubMed = 'pubmed',
  Protocol = 'protocol',
  RecentPurchases = 'recent purchases',
}

export enum GeneSearchType {
  Gene = 'gene',
  GeneSymbol = 'gene_symbol',
  GeneId = 'gene_id',
  TrcNumber = 'trc_number',
  RefSeq = 'refseq',
  GeneDesc = 'gene_desc',
}

export enum ContentSearchType {
  SiteContent = 'site_content',
  CitationProduct = 'citation_product',
  CitationGene = 'citation_gene',
  CitationSearch = 'citation_search',
  QmDoc = 'qm_doc',
  LotNumber = 'lot_number',
  SafetyDataSheet = 'sds',
  FrequentlyAskedQuestions = 'faq',
}

export enum AliasType {
  EcNumber = 'einecs',
  BeilsteinRegistryNumber = 'beilstein',
}

export enum ProductCardType {
  Recently = 'recently',
  Recommended = 'recommended',
  Featured = 'featured',
  Related = 'related',
  CustomersAlsoViewed = 'customers_also_viewed',
  FrequentlyPurchased = 'frequently_purchased_products',
  CartRecommendedProducts = 'cart_recommended_products',
  CompareSimilarItems = 'compare_similar_items',
  BestSellers = 'best_sellers',
  BuyItAgain = 'buy_it_again',
  ProductHeroCard = 'product_hero_card',
  PopularProducts = 'popular_products',
  NewArrivals = 'new_products',
  BuyAgainHomepage = 'biaSC',
  S138 = 'S138',
  S863 = 'S863',
  S151 = 'S151',
  S372 = 'S372',
  S366 = 'S366',
  S315 = 'S315',
  S324 = 'S324',
  S307 = 'S307',
  S194 = 'S194',
  S348 = 'S348',
  S402 = 'S402',
  S241 = 'S241',
  S395 = 'S395',
  S280 = 'S280',
  S140 = 'S140',
  CP007 = 'CP007',
  S319 = 'S319',
  S198 = 'S198',
  S199 = 'S199',
  S017 = 'S017',
  S029 = 'S029',
  S003 = 'S003',
  S020 = 'S020',
  S007 = 'S007',
  S107 = 'S107',
  S012 = 'S012',
  S053 = 'S053',
  S002 = 'S002',
  S022 = 'S022',
  S071 = 'S071',
  S103 = 'S103',
  S064 = 'S064',
  S015 = 'S015',
  S114 = 'S114',
  S125 = 'S125',
  S111 = 'S111',
}

export const PRODUCT_CARD_TYPE_TRANSLATED_TITLE = {
  [ProductCardType.Recommended]: messages.RECOMMENDED_PRODUCTS,
  [ProductCardType.Related]: messages.RELATED_PRODUCTS,
  [ProductCardType.Recently]: messages.RECENTLY_VIEWED_PRODUCTS,
  [ProductCardType.CustomersAlsoViewed]: messages.CUSTOMERS_ALSO_VIEWED,
  [ProductCardType.FrequentlyPurchased]: messages.FREQUENTLY_PURCHASED,
  [ProductCardType.CartRecommendedProducts]: messages.RECOMMENDED_PRODUCTS,
  [ProductCardType.BestSellers]: {
    id: 'SHOP_BEST_SELLERS',
    defaultMessage: 'Shop Best Sellers',
  },
  [ProductCardType.BuyItAgain]: {
    id: 'BUY_IT_AGAIN',
    defaultMessage: 'Buy It Again',
  },
  [ProductCardType.NewArrivals]: messages.NEW_PRODUCT,
  [ProductCardType.PopularProducts]: {
    id: 'POPULAR_PRODUCTS',
    defaultMessage: 'Popular Products',
  },
}

export const PRODUCT_CARD_TYPE_TEST_ID = {
  [ProductCardType.Recommended]: 'recommended-products',
  [ProductCardType.CartRecommendedProducts]: 'recommended-products',
  [ProductCardType.Related]: 'related-products',
  [ProductCardType.Recently]: 'recently-viewed-products',
  [ProductCardType.CustomersAlsoViewed]: 'customers-also-viewed-products',
  [ProductCardType.FrequentlyPurchased]: 'frequently-purchased-products',
  [ProductCardType.BestSellers]: 'best-seller-products',
  [ProductCardType.BuyItAgain]: 'buy-it-again',
  [ProductCardType.PopularProducts]: 'popular-products',
  [ProductCardType.NewArrivals]: 'new-arrival-products',
  [ProductCardType.BuyAgainHomepage]: 'buy-again-products',
  [ProductCardType.ProductHeroCard]: 'product_hero_card',
}

export enum CertificateSearchType {
  CofAProduct = 'cofa_product_number',
  CofALot = 'cofa_lot_number',
  CofOProduct = 'cofo_product_number',
  CofOLot = 'cofo_lot_number',
  CoqLot = 'coq_lot_number',
}

export enum CertificateFilterType {
  lotNumber = 'lot_number',
  productNumber = 'product',
}

export enum ComponentTypeKey {
  kitOnly = 'KIT_ONLY',
  kitSoldSeparate = 'KIT_SOLD_SEPARATE',
  bulletin = 'BULLETIN',
  analyte = 'ANALYTE',
  solvent = 'SOLVENT',
}

interface BasicSearchQuery {
  term?: string
  page: number
  sort: Sort
  group?: ProductSearchGroup
  perpage: number
  facet: string[]
  catalogType?: CatalogType
  format?: StructureSearchFormat
  filters?: string[]
  orgId?: string
  image?: string
  dym?: string
  region?: string
  debug?: string
}

interface ProductsSearchQuery extends BasicSearchQuery {
  focus: SearchFocusType.Products
  type: ProductSearchType
}

interface GenesSearchQuery extends BasicSearchQuery {
  focus: SearchFocusType.Genes
  type: GeneSearchType
}

interface PapersSearchQuery extends BasicSearchQuery {
  focus: SearchFocusType.Papers
  type: ContentSearchType.CitationSearch
}

interface TechnicalDocumentsSearchQuery extends BasicSearchQuery {
  focus: SearchFocusType.TechnicalDocuments
  type: ContentSearchType.SiteContent
}

interface SiteContentSearchQuery extends BasicSearchQuery {
  focus: SearchFocusType.SiteContent
  type: ContentSearchType.SiteContent
}

interface BuildingBlocksSearchQuery extends BasicSearchQuery {
  focus: SearchFocusType.BuildingBlocks
  type: ProductSearchType
}

interface B2BProductSearchQuery extends BasicSearchQuery {
  focus: SearchFocusType.B2B
  type: ProductSearchType
  orgId: string
}

interface StructureSearchQuery extends BasicSearchQuery {
  focus: SearchFocusType.StructureSearch
  type: StructureSearchType
  format: StructureSearchFormat
  image: string
  catalogType?: CatalogType.Buildingblocks
}

interface ChromatogramSearchQuery extends BasicSearchQuery {
  focus: SearchFocusType.Chromatograms
  type: ProductSearchType
}

export type SearchQuery =
  | ProductsSearchQuery
  | GenesSearchQuery
  | PapersSearchQuery
  | TechnicalDocumentsSearchQuery
  | SiteContentSearchQuery
  | BuildingBlocksSearchQuery
  | StructureSearchQuery
  | B2BProductSearchQuery
  | ChromatogramSearchQuery

/**
 * Translation strings for search types
 */
export const SEARCH_TYPE_MESSAGES: Record<SearchType, Message> = {
  [SearchType.Products]: messages.PRODUCTS,
  [SearchType.Genes]: { id: 'GENES', defaultMessage: 'Genes' },
  [SearchType.Papers]: { id: 'PAPERS', defaultMessage: 'Papers' },
  [SearchType.TechnicalDocuments]: messages.TECHNICAL_DOCUMENTS,
  [SearchType.SiteContent]: messages.SITE_CONTENT,
  [SearchType.BuildingBlocks]: messages.BUILDING_BLOCKS_EXPLORER,
  [SearchType.StructureSearch]: {
    id: 'STRUCTURE_SEARCH',
    defaultMessage: 'Structure Search',
  },
  [SearchType.B2B]: messages.PRODUCTS,
  [SearchType.Chromatograms]: messages.CHROMATOGRAMS,
}

/**
 * Returns a translation string for a given search type
 */
export const getSearchTypeMessage = (searchType: string) => {
  return SEARCH_TYPE_MESSAGES[searchType]
}

export interface SearchOption {
  value: SearchFocusType
  label: string
}

const {
  publicRuntimeConfig: { featureFlags },
} = getConfig()

export const useSearchOptions = (): SearchOption[] => {
  const { formatMessage } = useIntl()
  const showBuildingBlocks =
    useBuildingBlocksCountry() && !featureFlags.disableBBE

  return useMemo(
    () => [
      {
        value: SearchFocusType.Products,
        label: formatMessage(SEARCH_TYPE_MESSAGES[SearchType.Products]),
      },
      // Show Building Blocks if allowed for user / country
      ...(showBuildingBlocks
        ? [
            {
              value: SearchFocusType.BuildingBlocks,
              label: formatMessage(
                SEARCH_TYPE_MESSAGES[SearchType.BuildingBlocks]
              ),
            },
          ]
        : []),
      {
        value: SearchFocusType.TechnicalDocuments,
        label: formatMessage(
          SEARCH_TYPE_MESSAGES[SearchType.TechnicalDocuments]
        ),
      },
      {
        value: SearchFocusType.SiteContent,
        label: formatMessage(SEARCH_TYPE_MESSAGES[SearchType.SiteContent]),
      },
      {
        value: SearchFocusType.Papers,
        label: formatMessage(SEARCH_TYPE_MESSAGES[SearchType.Papers]),
      },
      {
        value: SearchFocusType.Genes,
        label: formatMessage(SEARCH_TYPE_MESSAGES[SearchType.Genes]),
      },
    ],
    [showBuildingBlocks, formatMessage]
  )
}

export const structureFiltersToStringArray = (
  filtersObject: StructureSearchFilters
): string[] => {
  return Object.keys(filtersObject).map(
    (filterKey) => `${filterKey}:${filtersObject[filterKey]}`
  )
}

export const structureFilterArrayToObject = (
  filterArray?: string[] | string
): StructureSearchFilters | undefined => {
  if (!filterArray) return
  //  router returns a string when only one filter and an array for multiple filters
  const filters = Array.isArray(filterArray) ? filterArray : [filterArray]
  const filterPairs = filters.map((filterPair) => filterPair.split(':'))
  return filterPairs.reduce((filterObj, currFilter) => {
    // the only two types of values in filters are either numbers or booleans
    filterObj[currFilter[0]] =
      parseInt(currFilter[1]) || JSON.parse(currFilter[1])
    return filterObj
  }, {})
}

interface GetValidSearchArgs {
  term?: string
  selectedFacets?: string[]
  focus?: string
  type?: string
  page?: number
  sort?: string
  group?: ProductSearchGroup
  perPage?: number
  format?: StructureSearchFormat
  filters?: StructureSearchFilters | string[]
  image?: string
  orgId?: string
  catalogType?: CatalogType
  dym?: string
  region?: string
  debug?: string
}

export const getValidSearchQuery = ({
  term,
  selectedFacets,
  focus,
  type,
  page,
  sort,
  group,
  perPage,
  format,
  filters,
  image,
  orgId,
  catalogType,
  dym,
  region,
  debug,
}: GetValidSearchArgs): SearchQuery => {
  const isValidFocusType = (
    maybeFocus: string | undefined
  ): maybeFocus is SearchFocusType => {
    return (
      maybeFocus != null &&
      values<string>(SearchFocusType).indexOf(maybeFocus) >= 0
    )
  }
  const getParsedFocus = () => {
    if (orgId) {
      return SearchFocusType.B2B
    }

    if (isValidFocusType(focus)) {
      return focus
    }

    return SearchFocusType.Products
  }
  const parsedFocus = getParsedFocus()
  const parsedSelectedFacets =
    selectedFacets && Array.from(selectedFacets).sort()
  const parsedPage = page && page > 0 ? page : 1
  const parsedPerPage = perPage && perPage > 0 ? perPage : DEFAULT_PER_PAGE
  const parsedSort =
    sort && values(Sort).indexOf(sort as Sort) >= 0
      ? (sort as Sort)
      : Sort.Relevance
  const parsedFormat = format || StructureSearchFormat.Molfile
  const parsedFilters =
    filters && !(filters instanceof Array || typeof filters === 'string')
      ? structureFiltersToStringArray(filters)
      : filters
  const parsedOrgId = orgId ? orgId : undefined
  const parsedImage = image || ''
  //this is only used to maintain a structure search focus while using the building blocks catalog on structure search
  const parsedCatalogType =
    catalogType === CatalogType.Buildingblocks ? catalogType : undefined
  const parsedRegion = region || undefined
  let query: SearchQuery
  switch (parsedFocus) {
    case SearchFocusType.Products: {
      query = {
        term,
        facet: parsedSelectedFacets || [],
        focus: parsedFocus,
        page: parsedPage,
        perpage: parsedPerPage,
        sort: parsedSort,
        group,
        dym: dym,
        type:
          type &&
          values(ProductSearchType).indexOf(type as ProductSearchType) >= 0
            ? (type as ProductSearchType)
            : ProductSearchType.Product,
        region: parsedRegion,
        debug,
      }
      break
    }
    case SearchFocusType.BuildingBlocks: {
      query = {
        term,
        facet: parsedSelectedFacets || [],
        focus: parsedFocus,
        page: parsedPage,
        perpage: parsedPerPage,
        sort: parsedSort,
        dym: dym,
        type:
          type &&
          values(ProductSearchType).indexOf(type as ProductSearchType) >= 0
            ? (type as ProductSearchType)
            : ProductSearchType.Product,
        debug,
      }
      break
    }
    case SearchFocusType.Genes: {
      query = {
        term,
        facet: parsedSelectedFacets || [],
        focus: parsedFocus,
        page: parsedPage,
        perpage: parsedPerPage,
        sort: parsedSort,
        type:
          type && values(GeneSearchType).indexOf(type as GeneSearchType) >= 0
            ? (type as GeneSearchType)
            : GeneSearchType.Gene,
      }
      break
    }
    case SearchFocusType.StructureSearch: {
      query = {
        term,
        facet: parsedSelectedFacets || [],
        focus: parsedFocus,
        format: parsedFormat,
        page: parsedPage,
        perpage: parsedPerPage,
        sort: parsedSort,
        type:
          type &&
          values(StructureSearchType).indexOf(type as StructureSearchType) >= 0
            ? (type as StructureSearchType)
            : StructureSearchType.Substructure,
        filters: parsedFilters,
        image: parsedImage,
        catalogType: parsedCatalogType,
      }
      break
    }
    case SearchFocusType.Papers: {
      query = {
        term,
        facet: parsedSelectedFacets || [],
        focus: parsedFocus,
        page: parsedPage,
        perpage: parsedPerPage,
        sort: parsedSort,
        type: ContentSearchType.CitationSearch,
      }
      break
    }
    case SearchFocusType.TechnicalDocuments:
    case SearchFocusType.SiteContent: {
      query = {
        term,
        facet: parsedSelectedFacets || [],
        focus: parsedFocus,
        page: parsedPage,
        perpage: parsedPerPage,
        sort: parsedSort,
        type: ContentSearchType.SiteContent,
      }
      break
    }
    case SearchFocusType.B2B: {
      query = parsedOrgId
        ? {
            orgId: parsedOrgId,
            term,
            focus: SearchFocusType.B2B as SearchFocusType.B2B,
            facet: parsedSelectedFacets || [],
            page: parsedPage,
            perpage: parsedPerPage,
            sort: parsedSort,
            type:
              type &&
              values(ProductSearchType).indexOf(type as ProductSearchType) >= 0
                ? (type as ProductSearchType)
                : ProductSearchType.Product,
          }
        : {
            term,
            facet: parsedSelectedFacets || [],
            focus: SearchFocusType.Products,
            page: parsedPage,
            perpage: parsedPerPage,
            sort: parsedSort,
            type:
              type &&
              values(ProductSearchType).indexOf(type as ProductSearchType) >= 0
                ? (type as ProductSearchType)
                : ProductSearchType.Product,
          }

      break
    }
    case SearchFocusType.Chromatograms: {
      if (featureFlags.chromatogramSearch) {
        query = {
          term,
          facet: parsedSelectedFacets || [],
          focus: parsedFocus,
          page: parsedPage,
          perpage: parsedPerPage,
          sort: parsedSort,
          type: ProductSearchType.Protocol,
        }
      } else {
        query = getValidSearchQuery({ term, selectedFacets: [] })
      }
      break
    }
    default: {
      query = getValidSearchQuery({ term, selectedFacets: [] })
    }
  }

  return query
}

export function stringifySearchQuery(
  query: Maybe<SearchQuery | B2BProductSearchQuery>
) {
  const regexForEncodingUnicode = /(<|%3C).*?(>|%3E)/g
  if (query && query.term) query?.term.replace(regexForEncodingUnicode, '')
  return qs.stringify(query, {
    indices: false,
    sort: alphabeticalSort,
    addQueryPrefix: true,
  })
}

export function getValidSearch({
  term,
  selectedFacets,
  focus,
  type,
  page,
  sort,
  group,
  perPage,
  format,
  filters,
  image,
  orgId,
  catalogType,
  dym,
  region,
}: GetValidSearchArgs): string {
  return stringifySearchQuery(
    getValidSearchQuery({
      term,
      selectedFacets,
      focus,
      type,
      page,
      sort,
      group,
      perPage,
      format,
      filters,
      image,
      orgId,
      catalogType,
      dym,
      region,
    })
  )
}

function alphabeticalSort(a: string, b: string): number {
  return a.localeCompare(b)
}

export function parseFacetsQuery(queryFacets: string[]): FacetInput[] {
  return queryFacets.reduce((parsedFacets, facetStr) => {
    const [key, value] = facetStr.split(':')

    let keyIndex = parsedFacets.findIndex((facet) => facet.key === key)

    if (keyIndex < 0) {
      parsedFacets.push({
        key,
        options: [],
      })
      keyIndex = parsedFacets.length - 1
    }

    parsedFacets[keyIndex].options.push(value)

    return parsedFacets
  }, [] as FacetInput[])
}

/**
 * Parses a date value that's valid as a graphql input (i.e. a number) into a
 * string value that's valid for a date input (e.g. 2019-04-31).
 */
export const parseDateInput = (date?: Maybe<number>) =>
  date ? format(new Date(date), 'yyyy-MM-dd') : ''

/**
 * Formats the `shipTo` value for display in dropdowns
 */
export const formatShipToLabel = (
  shipTo: PartnerAccountDetailFragment,
  isChinaUser = false
) => {
  const stateName = getStateNameFromSial(isChinaUser, shipTo.state)
  return `${shipTo.organizationName} - ${shipTo.attentionTo} - ${shipTo.department} - ${shipTo.buildingRoom} - ${shipTo.streetAddresses} - ${shipTo.city} - ${stateName} - ${shipTo.postalCode}`
}

/* determine what catalog to use when accessing /product api */
export const determineQueryFocus = (focus: string) => {
  switch (focus) {
    case SearchFocusType.BuildingBlocks:
      return SearchFocusType.BuildingBlocks
    case SearchFocusType.B2B:
      return SearchFocusType.B2B
    default:
      return SearchFocusType.Products
  }
}

export const getSellerName = (
  productAttributes?: ProductAttribute[]
): string => {
  const sellerNameAttribute = !!productAttributes
    ? productAttributes.find(
        (att) => att.key === 'manufacturer designation.default'
      )
    : null

  return sellerNameAttribute?.values[0] || ''
}

export const determineCatalogType = (path: string | null | undefined) => {
  switch (path) {
    case CatalogType.Buildingblocks:
      return CatalogType.Buildingblocks
    case CatalogType.B2b:
      return CatalogType.B2b
    case CatalogType.Marketplace:
      return CatalogType.Marketplace
    default:
      return CatalogType.Sial
  }
}

const getDymUrl = (
  dymTermsArray: Maybe<DidYouMeanTerm>[],
  queryTerm: string | undefined,
  focus: string
) => {
  const parsedDym = dymTermsArray
    .reduce(
      (array, termObject) =>
        termObject ? array.concat(termObject.term) : array,
      [] as string[]
    )
    .join()
  return `${searchResultsRoute.searchResults(queryTerm)}${getValidSearch({
    term: queryTerm,
    dym: parsedDym,
    focus,
  })}`
}

// for when a user hits no-results page on SRP, required to tack on the did you mean terms to url
// without causing a page refresh
export const dymShallowRoute = (
  router: NextRouter,
  queryTerm: string | undefined,
  focus: string,
  dymTermArray: Maybe<DidYouMeanTerm>[]
) => {
  /* need to do all this because the search url has a dynamic
      param, so need to include that as [term] in the 'originalPath'
      then replace it with the newPath which will have the actual term
      */
  // removes country/lang
  const searchPath = router.pathname.slice(router.pathname.indexOf('/search/'))
  const asNewPath = getDymUrl(dymTermArray, queryTerm, focus) // ie search/trizma?...query
  const newPathQuery = asNewPath.slice(asNewPath.indexOf('?'))
  const originalPath = `${searchPath}${newPathQuery}` // ie search/[term]?...query

  router.push(originalPath, asNewPath, { shallow: true })
}

export const formatCasNumberSearchTerm = (
  casNumber: string | null | undefined
): string => {
  const match = casNumber?.match(/^\d{2,7}-\d{2}-\d/)
  return match ? match[0] : ''
}

export const PRODUCT_CAROUSEL_TYPES = [
  ProductCardType.Recommended,
  ProductCardType.CartRecommendedProducts,
  ProductCardType.Recently,
  ProductCardType.BestSellers,
  ProductCardType.BuyItAgain,
  ProductCardType.PopularProducts,
  ProductCardType.NewArrivals,
  ProductCardType.BuyAgainHomepage,
  ProductCardType.ProductHeroCard,
  ProductCardType.Related,
]
