import React from 'react'
import { useApolloClient } from 'react-apollo'
import { CartRequestAction } from '@src/types/graphql-types'
import {
  CartDocument,
  CartQuery,
  CartQueryVariables,
} from '@src/queries/CartQuery.generated'

/**
 * In order to refetch the full cart after e.g. deleting a cart item, we have
 * historically had the mutation return the full cart data if it is a mutation
 * that affects full cart fields. The issue with that is the mutation then
 * becomes as slow as full cart.
 *
 * Simply telling the mutation to refetch the full cart after isn't sufficient,
 * as other components in the app that use full cart are not notified that the
 * full cart is being refetched, and will thus continue to display out-of-date
 * cached data (if any) until the full cart refetch resolves, in the worst case
 * 4 minutes later.
 *
 * We need this context provider for two reasons:
 *
 * 1. To keep track of when we are and are not refetching the full cart, so the
 * cart page has a way to know that some other component in the app modified it.
 * 2. To ensure that full cart refetches are deduplicated.
 *
 * Deduplication is touchy here, since Apollo client has its own deduplication
 * logic, and that logic is: if there's a request in flight, just drop all
 * subsequent requests. That's great for requests that are lightning fast, but
 * for long-running requests that have a chance of becoming stale before they
 * even resolve, we need a way to say "hey, this has changed, please cancel your
 * current refetch and start over."
 */
interface FullCartRefetchContextProps {
  refetchFullCart: (
    id?: CartRequestAction,
    onError?: (error: unknown) => void
  ) => void
  cancelFullCartRefetch: () => void
  isCartRefetching: boolean
}

export const FullCartRefetchContext =
  React.createContext<FullCartRefetchContextProps>({
    refetchFullCart: () => null,
    cancelFullCartRefetch: () => null,
    isCartRefetching: false,
  })

export const FullCartRefetchProvider = ({ children }) => {
  const client = useApolloClient()

  const [currentRefetch, setCurrentRefetch] =
    React.useState<ZenObservable.Subscription | null>(null)

  const isCartRefetching = React.useMemo(
    () => !!currentRefetch,
    [currentRefetch]
  )

  const refetchFullCart = React.useCallback(
    (id = CartRequestAction.Mini, onError?: (error: unknown) => void) => {
      if (currentRefetch) {
        currentRefetch.unsubscribe()
      }
      const query = client.watchQuery<CartQuery, CartQueryVariables>({
        query: CartDocument,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
        context: {
          queryDeduplication: false,
        },
      })
      const nextRefetch = query.subscribe(() => {
        setCurrentRefetch(null)
        // Clean up this refetch if it never got cancelled
        nextRefetch.unsubscribe()
      }, onError)
      setCurrentRefetch(nextRefetch)
    },
    [currentRefetch, client]
  )

  const cancelFullCartRefetch = React.useCallback(() => {
    if (currentRefetch) {
      currentRefetch.unsubscribe()
      setCurrentRefetch(null)
    }
  }, [currentRefetch])

  return (
    <FullCartRefetchContext.Provider
      value={{ refetchFullCart, cancelFullCartRefetch, isCartRefetching }}
    >
      {children}
    </FullCartRefetchContext.Provider>
  )
}

export const useFullCartRefetch = () => React.useContext(FullCartRefetchContext)
