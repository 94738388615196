import { UserSession } from './userSession'
import { parseUrl } from '@utils/parseUrl'
import { useCurrentUser, SitePreference } from '@src/utils/useCurrentUser'
import { useCountriesData } from './useCountriesData'

export const useCountryName = (countryCode: string | null | undefined) => {
  const { getCountryProp } = useCountriesData()
  if (!countryCode) {
    return null
  }
  return getCountryProp(countryCode, 'displayName')
}

export const getLocalizedUrl = (userSession: UserSession, url?: string) => {
  // TODO STRAT-13269 consolidate this with server/utils/https.ts#parsedUrlWithBasename
  if (!url) return { href: '', as: '' }
  const language = userSession.language.toLowerCase()
  const country = userSession.country.toUpperCase()
  const { query = '', path = '' } = parseUrl(url)
  const trimmedPath = (path ?? '').replace(/\/$/, '')
  return {
    href: `/[country]/[language]${trimmedPath}${query}`,
    as: `/${country}/${language}${trimmedPath}${query}`,
  }
}

export interface ParsedPath {
  basename: {
    country?: string
    language?: string
  }
  fragment?: string
  pathname?: string
  afterBasename?: string
  pathAfterBasename?: string
  origin?: string
  query?: string
}

/**
 * @function parseRegionalUrl
 *
 * This splits up a regional URL to give the country, language, & everything after it.
 *
 * e.g. http://www.sigmaaldrich.com/US/en/search/trizma?page=1
 *  will return:
 *    {
 *      basename: {
 *        country: 'US',
 *        language: 'en'
 *      },
 *      afterBasename: '/search/trizma?page=1',
 *      pathname: '/US/en/search/trizma?page=1'
 *      origin: 'http://www.sigmaaldrich.com',
 *    }
 *
 * @param url
 */
export const parseRegionalUrl = (url?: string): ParsedPath => {
  const parseableUrl =
    url || (typeof window !== 'undefined' ? window.location.href : null)
  if (parseableUrl) {
    const {
      protocol,
      host,
      country,
      language,
      path = '',
      query = '',
    } = parseUrl(parseableUrl)
    const isRegional = country && language
    if (!isRegional) return { basename: {} }
    // TODO: remove this logic and refactor parseUrl to detect fragments.
    const [realPath, pathFragment] = path.split('#')
    const [realQuery, queryFragment] = query.split('#')

    return {
      basename: {
        country,
        language,
      },
      fragment: pathFragment || queryFragment,
      pathname: `/${country}/${language}${realPath}`,
      afterBasename: `${realPath}${realQuery}`,
      pathAfterBasename: realPath,
      query: realQuery,
      origin: `${protocol}://${host}`,
    }
  } else {
    return {
      basename: {},
    }
  }
}

// Note: If you are testing this functionality, you must either provide values
// at `__mocks_/next/config.js` or stub `next/config` in your test
export const useBuildingBlocksCountry = () => {
  const { currentUser, getSitePreference } = useCurrentUser()
  const countryLevelFlagEnabled =
    !!currentUser?.metadata?.isBuildingBlockEnabled
  if (currentUser?.__typename === 'LoggedInUser') {
    const userLevelFlagEnabled = !getSitePreference(
      SitePreference.HideBuildingBlocks
    )
    return countryLevelFlagEnabled && userLevelFlagEnabled
  }
  return countryLevelFlagEnabled
}

export function removeCountryLanguage(url: string): string {
  const { country, language } = parseUrl(url)
  if (country && language) {
    url = url.replace(`/${country}/${language}`, '')
  }

  return url
}

/**
 * This function returns true if no country/lang prefix is present in the url,
 *  or if cookie values don't match the prefix.
 * @param {UserSession} userSession contains users country and language specified in the cookies
 * @param {url} url to check if needs localizing
 * @returns boolean
 */
export function needsLocalizedUrl(
  userSession: UserSession,
  url: string
): boolean {
  const { country: urlCountry, language: urlLanguage } = parseUrl(url)
  const hasPrefix = !!urlCountry && !!urlLanguage
  const cookiesDontMatchPrefix =
    urlCountry?.toLowerCase() !== userSession.country.toLowerCase() ||
    urlLanguage?.toLowerCase() !== userSession.language.toLowerCase()
  return !hasPrefix || (hasPrefix && cookiesDontMatchPrefix)
}
