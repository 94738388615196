import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { sessionStorage } from 'react-storage'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core/'
import LinkStyleButton from '@src/components/LinkStyleButton'
import WalkMeStep from '@src/components/WalkMeStep'
import { Step } from '@src/components/WalkMeStep/StepBody'
import { WalkMeStepProps } from '@src/components/WalkMeStep/WalkMeStep'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import { useUserActivity } from '@src/utils/useUserActivity'
import {
  InviteCoWorkerWalkMeConstants,
  getInviteCoWorkerStorageOverride,
  useInviteCoWorkerWalkMe,
} from '@src/routes/InviteCoWorker/InviteCoWorkerWalkMe'

const useStyles = makeStyles((theme) => ({
  step: {
    marginTop: theme.spacing(-1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const steps: Step[] = [
  {
    title: {
      id: 'WALK_ME_VIEW_DETAILS',
      defaultMessage:
        'Invite a Coworker: Simplified Registration for Your Team',
    },
    description: {
      id: 'WALK_ME_VIEW_DETAILS_TIP',
      defaultMessage:
        'Using your existing account, it takes only a couple of clicks to register your coworkers.',
    },
  },
  {
    title: {
      id: 'WALK_ME_INVITE_COWORKER_OPTIONS',
      defaultMessage: 'Select “Invite a Coworker”',
    },
    description: {
      id: 'WALK_ME_INVITE_COWORKER_ACCESS_TIP',
      defaultMessage:
        'Grant your coworker immediate access to your organization’s pricing, quotes, or orders.',
    },
  },
  {
    title: {
      id: 'WALK_ME_INVITE_COWORKER',
      defaultMessage: 'Add Your Coworker’s Information',
    },
    description: {
      id: 'WALK_ME_INVITE_COWORKER_TIP',
      defaultMessage:
        'Fill in the required information and select the features you would like to grant to your coworker. Once complete, they will be sent an invitation via email.',
    },
  },
]

type InviteCoWorkerWalkMeStepProps = Pick<
  WalkMeStepProps,
  'anchorEl' | 'placement'
> & {
  stepNumber: number

  clickNext: (arg) => void
}

export const InviteCoWorkerWalkMeStep = ({
  anchorEl,
  placement,
  stepNumber,
  clickNext,
}: InviteCoWorkerWalkMeStepProps) => {
  const classes = useStyles()
  const { state, dismissStep, setEnabled, advanceActiveStepNumber } =
    useInviteCoWorkerWalkMe()
  const [num, setNum] = useState(0)
  useEffect(() => {
    setNum(stepNumber)
  }, [stepNumber])
  const { userIsLoggedIn } = useCurrentUser()
  const { updateUserActivity, trackingData } = useUserActivity(
    InviteCoWorkerWalkMeConstants.TRACKING_DATA_KEY,
    getInviteCoWorkerStorageOverride(userIsLoggedIn)
  )

  const step = steps[stepNumber - 1]

  const showWalkMeStep =
    state.enabled &&
    !state.stepDismissed &&
    state.activeStepNumber === stepNumber
  const dismissedCount = trackingData?.numWalkMeDismissals || 0

  const optOutOfWalkMe = () => {
    sessionStorage.setItem(InviteCoWorkerWalkMeConstants.DISMISSED_KEY, true)
    updateUserActivity({
      numWalkMeDismissals: dismissedCount + 1,
      hasOptedOutOfWalkMe: true,
    })
    setEnabled(false)
  }

  const onClickAway = () => {
    if (stepNumber !== 1) dismissStep()
    if (state.activeStepNumber === steps.length) {
      sessionStorage.setItem(InviteCoWorkerWalkMeConstants.DISMISSED_KEY, true)
      updateUserActivity({ hasCompletedWalkMe: true })
    }
  }

  return showWalkMeStep && num ? (
    <WalkMeStep
      id={`walkme-step${stepNumber}`}
      className={classes.step}
      anchorEl={anchorEl}
      placement={placement}
      step={step}
      onClose={optOutOfWalkMe}
      onClickAway={onClickAway}
      footer={
        <div className={classes.footer}>
          <LinkStyleButton data-testid="dismiss-tips" onClick={optOutOfWalkMe}>
            <FormattedMessage
              id="DONT_SHOW_ME_TIPS"
              defaultMessage="Don't Show Me Tips"
            />
          </LinkStyleButton>

          {stepNumber === 1 && (
            <Button
              data-testid="start-tour"
              variant="contained"
              color="primary"
              onClick={() => {
                advanceActiveStepNumber(2)
                clickNext(2)
              }}
            >
              <FormattedMessage
                id="SHOW_ME_WHATS_NEW"
                defaultMessage="Show me what's new"
              />
            </Button>
          )}
        </div>
      }
    />
  ) : null
}
