// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UserActivityQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserActivityQuery = { __typename?: 'Query', getUserActivity?: Array<{ __typename?: 'UserActivityEntry', trackingData?: Record<string, unknown> | null, featureType: string }> | null };


export const UserActivityDocument = gql`
    query UserActivity {
  getUserActivity {
    trackingData
    featureType
  }
}
    `;
export type UserActivityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserActivityQuery, UserActivityQueryVariables>, 'query'>;

    export const UserActivityComponent = (props: UserActivityComponentProps) => (
      <ApolloReactComponents.Query<UserActivityQuery, UserActivityQueryVariables> query={UserActivityDocument} {...props} />
    );
    

/**
 * __useUserActivityQuery__
 *
 * To run a query within a React component, call `useUserActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserActivityQuery, UserActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserActivityQuery, UserActivityQueryVariables>(UserActivityDocument, options);
      }
export function useUserActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserActivityQuery, UserActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserActivityQuery, UserActivityQueryVariables>(UserActivityDocument, options);
        }
export type UserActivityQueryHookResult = ReturnType<typeof useUserActivityQuery>;
export type UserActivityLazyQueryHookResult = ReturnType<typeof useUserActivityLazyQuery>;
export type UserActivityQueryResult = ApolloReactCommon.QueryResult<UserActivityQuery, UserActivityQueryVariables>;