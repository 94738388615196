import React from 'react'

interface LoginPopoverContextValue {
  loginPopover: React.ReactNode
  setLoginPopover: (loginPopover: React.ReactNode) => void
}
export const LoginPopoverContext =
  React.createContext<LoginPopoverContextValue>({
    loginPopover: null,
    setLoginPopover: () => null,
  })

export const LoginPopoverProvider = (props) => {
  const [loginPopover, setLoginPopover] = React.useState<React.ReactNode>(null)
  const value = {
    loginPopover,
    setLoginPopover,
  }

  return <LoginPopoverContext.Provider value={value} {...props} />
}

export const useLoginPopover = () => React.useContext(LoginPopoverContext)
