import getConfig from 'next/config'
import { OurBrands } from '@sial/common-design'

export const getBrandIdentity = (): string => {
  const {
    publicRuntimeConfig: { brandIdentity: configBrandIdentity },
  } = getConfig()

  const isValidBrandIdentity = configBrandIdentity in OurBrands

  const brandIdentity = isValidBrandIdentity
    ? configBrandIdentity
    : 'SigmaAldrich'

  if (!isValidBrandIdentity) {
    console.error(
      `INVALID BRAND IDENTITY VARIABLE SUPPLIED! App received '${configBrandIdentity}' as the 'brandIdentity' config variable.`
    )
    console.error(
      `App will default to '${brandIdentity}' because 'brandIdentity' was invalid.`
    )
  }

  return brandIdentity
}
