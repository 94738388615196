import isbot from 'isbot'
import getConfig from 'next/config'
import getCookiesFromContext from '@src/utils/getCookiesFromContext'

const {
  publicRuntimeConfig: { optimizelyFeatureFlagId },
} = getConfig()

export const getOptimizelyDataFile = async (ctx: ExtendedNextPageContext) => {
  const cookies = getCookiesFromContext(ctx)
  const userAgentStr = ctx.req?.headers['user-agent'] || ''
  const isBot = isbot(userAgentStr)

  const oneTrustConsentCookie = cookies.get('OptanonConsent')
  const hasOneTrustConsent = oneTrustConsentCookie?.includes('C0003:1')

  if (!isBot && hasOneTrustConsent && optimizelyFeatureFlagId) {
    try {
      const dataFileRes = await fetch(
        `https://cdn.optimizely.com/datafiles/${optimizelyFeatureFlagId}.json`
      )
      if (dataFileRes.ok) {
        return await dataFileRes.json()
      } else {
        return null
      }
    } catch (err) {
      return null
    }
  }
  return null
}
