import { useRouter as useNextRouter, NextRouter } from 'next/router'
import { useUserSession } from './useUserSession'
import { isObject } from 'lodash'
import { UrlObject } from 'url'
import { UserSession } from './userSession'

type Url = UrlObject | string

type RouterAction = (
  url: Url,
  as?: Url | undefined,
  options?: {} | undefined
) => Promise<boolean>

type AppendRouterArgs = (
  userSession: UserSession,
  url: Url,
  as?: Url | undefined,
  options?: {} | undefined
) => [Url, Url | undefined, {} | undefined]

export interface LocalizedSingletonRouter extends NextRouter {
  push: RouterAction
  replace: RouterAction
}

export const appendRouterArgs: AppendRouterArgs = (
  userSession,
  url,
  as,
  options
) => {
  const language = userSession?.language?.toLowerCase() || ''
  const country = userSession?.country?.toUpperCase() || ''

  const href = isObject(url) ? url.pathname || '' : url
  // Ignore routes that already have basename OR catchAll
  if (
    href.startsWith('/[country]/[language]') ||
    href.startsWith('/[...rest]')
  ) {
    return [url, as, options]
  }

  const urlPath = `/[country]/[language]${isObject(url) ? url.pathname : url}`
  // If the provided URL is a string, pass it through as a string so we do not mangle query params
  const urlArg = isObject(url)
    ? {
        pathname: urlPath,
        query: url.query,
      }
    : urlPath

  const asUrl = as || url
  const asPath = `/${country}/${language}${
    isObject(asUrl) ? asUrl.pathname : asUrl
  }`
  // If the provided URL is a string, pass it through as a string so we do not mangle query params
  const asArg = isObject(asUrl)
    ? {
        pathname: asPath,
        query: asUrl.query,
      }
    : asPath

  return [urlArg, asArg, options]
}

export const useLocalizedRouter = <TKeys extends string = string>() => {
  // WARNING: This utility does not respond to changes of `userSession.country` or `userSession.language` if
  // the underlying cookie changes. The `appendRouterArgs` input is static, being that the `useLocalizedRouter`
  // module cannot be overwritten once invoked
  const { userSession } = useUserSession()
  const router = useNextRouter<TKeys>()
  const push: RouterAction = (...args) =>
    router.push(...appendRouterArgs(userSession, ...args))
  const replace: RouterAction = (...args) =>
    router.replace(...appendRouterArgs(userSession, ...args))
  return { ...router, replace, push }
}

export default useLocalizedRouter
