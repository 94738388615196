import Icon, { BaseIconProps } from './Icon'
function CaretRightIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 20" {...props}>
        <path d="M2.2 20a2.16 2.16 0 0 1-1.43-.54 2.24 2.24 0 0 1-.24-3.13L5.9 10 .53 3.67A2.24 2.24 0 0 1 .77.54a2.17 2.17 0 0 1 3.1.24l6.6 7.77a2.24 2.24 0 0 1 0 2.9l-6.6 7.77A2.16 2.16 0 0 1 2.2 20" />
      </svg>
    </Icon>
  )
}
export default CaretRightIcon
